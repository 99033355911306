define("dash/templates/components/transition-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "HJGpFrAv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[21,\"finalContentClasses\"]],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/components/transition-loader.hbs"
    }
  });
  _exports.default = _default;
});