define("dash/routes/devices/device/contacts/new", ["exports", "dash/lib/authenticatedRoute", "dash/lib/contactsCache"], function (_exports, _authenticatedRoute, _contactsCache) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.cleanContactNameFields = void 0;
  // this cleans the contact name fields because a blank field turns into the string 'null' in the vcard
  const cleanContactNameFields = contact => {
    const firstName = Ember.get(contact, 'firstName');
    const lastName = Ember.get(contact, 'lastName');
    if (!lastName) {
      Ember.set(contact, 'lastName', '');
    }
    if (!firstName) {
      Ember.set(contact, 'firstName', '');
    }
    return contact;
  };
  _exports.cleanContactNameFields = cleanContactNameFields;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    model() {
      return Ember.get(this, 'store').createRecord('contact', {
        device: this.modelFor('devices.device')
      });
    },
    setupController(controller) {
      this._super(...arguments);
      return Ember.set(controller, 'submissionError', null);
    },
    actions: {
      onCancel(redirectPath) {
        if (Ember.get(this, 'controller.model.isNew')) {
          Ember.get(this, 'controller.model').unloadRecord();
        }
        this.transitionTo(redirectPath);
      },
      saveContact() {
        const contact = Ember.get(this, 'controller.model');
        if (contact.isSaving) return;
        Ember.set(this, 'controller.submissionError', null);
        cleanContactNameFields(contact).save().then(savedContact => {
          _contactsCache.default.add(this.modelFor('devices.device'), savedContact);
          Ember.getOwner(this).lookup('route:devices.device.contacts').refresh().then(() => {
            this.transitionTo('devices.device.contacts.index');
          });
        }).catch(errors => {
          Ember.set(this, 'controller.submissionError', "Something went wrong. Please try again");
          return Ember.get(this, 'dash').handleServerErrors(errors);
        });
      }
    }
  });
  _exports.default = _default;
});