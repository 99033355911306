define("dash/models/contact", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  const Validations = (0, _emberCpValidations.buildValidations)({
    firstName: (0, _emberCpValidations.validator)('presence', true),
    number: (0, _emberCpValidations.validator)('presence', true)
  });
  var _default = Model.extend(Validations, {
    firstName: attr('string'),
    lastName: attr('string'),
    number: attr('string'),
    device: belongsTo('device'),
    fullName: Ember.computed('firstName', 'lastName', function () {
      return `${Ember.get(this, 'firstName')} ${Ember.get(this, 'lastName')}`;
    })
  });
  _exports.default = _default;
});