define("dash/templates/components/tool-install-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "f5NFyOOi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"my2\"],[9],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"\\n    To install, your device must be on at least LightOS v.\\n    \"],[1,[21,\"minApkVersion\"],false],[0,\"\\n    .\\n  \"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"href\",\"https://support.thelightphone.com/hc/en-us/articles/360031128871-Phone-Updates\"],[11,\"class\",\"underline\"],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener\"],[9],[0,\"\\n      How do I know which version my device is on?\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/components/tool-install-message.hbs"
    }
  });
  _exports.default = _default;
});