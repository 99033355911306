define("dash/components/iccid-form", ["exports", "dash/constants/ExternalLinks"], function (_exports, _ExternalLinks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['IccidForm', 'container--wide', 'text-center', 'mt2_5', 'md:px0'],
    ExternalLinks: _ExternalLinks.default,
    hasValidationError: false,
    actions: {
      onUpdateValue(iccid, field, val) {
        Ember.set(iccid, field, val);
      },
      submit(evt) {
        if (evt) evt.preventDefault();
        Ember.get(this, 'iccid').validate().then(({
          validations
        }) => {
          const isValid = Ember.get(validations, 'isValid');
          Ember.set(this, 'hasValidationError', !isValid);
          if (isValid) Ember.get(this, 'commit')(Ember.get(this, 'iccid'));
        });
      }
    }
  });
  _exports.default = _default;
});