define("dash/templates/components/main-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fnI6InZx",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"each\",[[23,[\"items\"]]],[[\"key\"],[\"text\"]],{\"statements\":[[0,\"  \"],[7,\"li\"],[11,\"class\",\"MainMenu__link-container\"],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[\"path\"]]],null,{\"statements\":[[4,\"link-to\",[[22,1,[\"path\"]]],[[\"class\"],[\"MainMenu__link p1 md:p1_5 block\"]],{\"statements\":[[0,\"        \"],[1,[22,1,[\"text\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"if\",[[22,1,[\"external\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\"],[12,\"href\",[22,1,[\"external\"]]],[11,\"class\",\"MainMenu__link p1 md:p1_5 block\"],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener\"],[9],[0,\"\\n        \"],[1,[22,1,[\"text\"]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/components/main-menu.hbs"
    }
  });
  _exports.default = _default;
});