define("dash/components/device-select", ["exports", "dash/constants/ExternalLinks"], function (_exports, _ExternalLinks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['container--wide', 'text-center', 'mt2_5', 'md:px0'],
    ExternalLinks: _ExternalLinks.default,
    hasValidationError: false,
    actions: {
      selectDevice(device) {
        Ember.set(Ember.get(this, 'activation'), 'imei', Ember.get(device, 'imei'));
      }
    }
  });
  _exports.default = _default;
});