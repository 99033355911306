define("dash/components/support-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    dash: Ember.inject.service(),
    classNames: ['SupportWarning'],
    isVisible: Ember.computed('dash.{currentSupportToken}', function () {
      return !!Ember.get(this, 'dash.currentSupportToken');
    })
  });
  _exports.default = _default;
});