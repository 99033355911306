define("dash/templates/devices/device/tools/calendar/add/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "z5UFayS4",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\",\"withoutContainer\"],[\"Add Calendar\",\"devices.device.tools.calendar\",\"home\",true]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"container__desktop flex flex-1 flex-col self-center sub-heading text-center justify-center mb4 md:mb8\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex flex-col items-center\"],[9],[0,\"\\n      \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"devices.device.tools.calendar.add.google\"],[[\"class\"],[\"sub-title pointer hover-gray-light mb4 md:mb1\"]],{\"statements\":[[0,\"          \"],[7,\"li\"],[11,\"class\",\"title py1\"],[9],[0,\"\\n            Google\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"link-to\",[\"devices.device.tools.calendar.add.icloud\"],[[\"class\"],[\"sub-title pointer hover-gray-light mb4 md:mb1\"]],{\"statements\":[[0,\"          \"],[7,\"li\"],[11,\"class\",\"title py1\"],[9],[0,\"\\n            iCloud\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"link-to\",[\"devices.device.tools.calendar.add.caldav\"],[[\"class\"],[\"sub-title pointer hover-gray-light mb4 md:mb1\"]],{\"statements\":[[0,\"          \"],[7,\"li\"],[11,\"class\",\"title py1\"],[9],[0,\"\\n            CalDAV\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/device/tools/calendar/add/index.hbs"
    }
  });
  _exports.default = _default;
});