define("dash/routes/account/referrals/signup", ["exports", "dash/lib/authenticatedRoute", "dash/lib/address", "dash/lib/setPassword", "dash/routes/account/referrals/countries"], function (_exports, _authenticatedRoute, _address, _setPassword, _countries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    affiliate: Ember.inject.service(),
    model() {
      const address = _address.default.create(Ember.getOwner(this).ownerInjection());
      Ember.set(address, 'phone', '8334141033');
      const password = _setPassword.default.create(Ember.getOwner(this).ownerInjection());
      const email = Ember.get(this, 'dash.currentUser.email');
      return {
        address,
        password,
        email
      };
    },
    setupController(controller) {
      this._super(...arguments);
      Ember.set(controller, 'supportedCountries', _countries.default);
      Ember.set(controller, 'showPasswordDialog', false);
      Ember.set(controller, 'isSaving', false);
    },
    actions: {
      saveAddress() {
        Ember.set(this, 'controller.showPasswordDialog', true);
      },
      savePassword(evt) {
        if (evt) evt.preventDefault();
        const address = Ember.get(this, 'controller.model.address').serialize();
        const password = Ember.get(this, 'controller.model.password.password');
        const email = Ember.get(this, 'controller.model.email');
        const dashService = Ember.get(this, 'dash');
        const affiliateService = Ember.get(this, 'affiliate');
        Ember.set(this, 'controller.isSaving', true);
        return affiliateService.createAffiliate(address, password, email).catch(errors => {
          return dashService.handleServerErrors(errors);
        }).finally(() => {
          Ember.set(this, 'controller.isSaving', false);
          this.transitionTo('account.referrals.paypal');
        });
      }
    }
  });
  _exports.default = _default;
});