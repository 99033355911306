define("dash/helpers/format-date-string", ["exports", "dash/constants/monthNames"], function (_exports, _monthNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatDateString = formatDateString;
  function formatDateString([dateString /*...rest*/]) {
    const date = new Date(dateString);
    return `${_monthNames.default[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  }
  var _default = Ember.Helper.helper(formatDateString);
  _exports.default = _default;
});