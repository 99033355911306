define("dash/components/imeis-form-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      onInputChange(event) {
        this.get('updateImeiAction')(Ember.get(this, 'index'), event.target.value);
      },
      onFocusOut() {
        this.get('onRowUnfocus')(Ember.get(this, 'index'));
      }
    }
  });
  _exports.default = _default;
});