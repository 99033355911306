define("dash/routes/devices/device/contacts/import/other", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Stages = {
    IDLE: 'IDLE',
    UPLOADING: 'UPLOADING',
    SUCCESS: 'SUCCESS'
  };
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    setupController( /*controller*/
    ) {
      this._super(...arguments);
      Ember.set(this, 'controller.stage', Stages.IDLE);
    },
    actions: {
      onfileadd(file) {
        Ember.set(this, 'controller.stage', Stages.UPLOADING);
        const currentDevice = this.modelFor('devices.device');
        Ember.get(this, 'dash').uploadVCard(file, currentDevice).then(() => {
          Ember.set(this, 'controller.stage', Stages.SUCCESS);
          Ember.get(this, 'dash').showSuccessMessage('Success! Your import has started.');
        }).catch(errors => {
          Ember.set(this, 'controller.stage', Stages.IDLE);
          return Ember.get(this, 'dash').handleServerErrors(errors);
        });
      }
    }
  });
  _exports.default = _default;
});