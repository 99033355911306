define("dash/models/address", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var _default = _emberData.default.Model.extend({
    title: attr('string'),
    address: attr('raw'),
    position: attr('raw'),
    selected: attr('boolean', {
      defaultValue: false
    })
  });
  _exports.default = _default;
});