define("dash/routes/devices/device/tools/notes", ["exports", "dash/lib/authenticatedRoute", "dash/constants/ExternalLinks"], function (_exports, _authenticatedRoute, _ExternalLinks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    model() {
      const noteDeviceTool = Ember.get(this.modelFor("devices.device"), "deviceTools").find(deviceTool => {
        return Ember.get(deviceTool, "tool.namespace") === "notes";
      });
      if (!noteDeviceTool) {
        return {
          installed: false,
          notes: [],
          device: this.modelFor("devices.device"),
          supportLink: _ExternalLinks.default.SUPPORT.notes,
          tool: Ember.get(this, "store").peekAll("tool").find(tool => tool.namespace === "notes")
        };
      }
      return {
        noteDeviceTool,
        installed: true,
        supportLink: _ExternalLinks.default.SUPPORT.notes
      };
    }
  });
  _exports.default = _default;
});