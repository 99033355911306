define("dash/models/audio", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', true),
    artist: (0, _emberCpValidations.validator)('presence', true)
  });
  var _default = Model.extend(Validations, {
    title: attr('string'),
    artist: attr('string'),
    album: attr('string'),
    duration: attr('number'),
    deviceTool: belongsTo('device-tool')
  });
  _exports.default = _default;
});