define("dash/templates/components/subscription-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "f2K5Ifkh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[12,\"href\",[23,[\"ExternalLinks\",\"GIGS\",\"SIGNIN\"]]],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"mb2\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"mb1 heading\"],[9],[1,[21,\"phoneNumber\"],false],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"mb1\"],[9],[1,[23,[\"subscription\",\"plan_name\"]],false],[0,\" - \"],[1,[21,\"cost\"],false],[10],[0,\"\\n    \"],[7,\"p\"],[9],[1,[21,\"nextRenewal\"],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/components/subscription-detail.hbs"
    }
  });
  _exports.default = _default;
});