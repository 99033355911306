define("dash/routes/account/referrals/countries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AUS",
    "name": "Australia",
    "subdivisions": [{
      "code": "AU-ACT",
      "name": "Australian Capital Territory",
      "short_code": "ACT"
    }, {
      "code": "AU-NT",
      "name": "Northern Territory",
      "short_code": "NT"
    }, {
      "code": "AU-NSW",
      "name": "New South Wales",
      "short_code": "NSW"
    }, {
      "code": "AU-QLD",
      "name": "Queensland",
      "short_code": "QLD"
    }, {
      "code": "AU-SA",
      "name": "South Australia",
      "short_code": "SA"
    }, {
      "code": "AU-TAS",
      "name": "Tasmania",
      "short_code": "TAS"
    }, {
      "code": "AU-VIC",
      "name": "Victoria",
      "short_code": "VIC"
    }, {
      "code": "AU-WA",
      "name": "Western Australia",
      "short_code": "WA"
    }]
  }, {
    "code": "AUT",
    "name": "Austria",
    "subdivisions": [{
      "code": "AT-1",
      "name": "Burgenland",
      "short_code": "1"
    }, {
      "code": "AT-2",
      "name": "Kärnten",
      "short_code": "2"
    }, {
      "code": "AT-3",
      "name": "Niederösterreich",
      "short_code": "3"
    }, {
      "code": "AT-4",
      "name": "Oberösterreich",
      "short_code": "4"
    }, {
      "code": "AT-5",
      "name": "Salzburg",
      "short_code": "5"
    }, {
      "code": "AT-6",
      "name": "Steiermark",
      "short_code": "6"
    }, {
      "code": "AT-7",
      "name": "Tirol",
      "short_code": "7"
    }, {
      "code": "AT-8",
      "name": "Vorarlberg",
      "short_code": "8"
    }, {
      "code": "AT-9",
      "name": "Wien",
      "short_code": "9"
    }]
  }, {
    "code": "BEL",
    "name": "Belgium",
    "subdivisions": [{
      "code": "BE-BRU",
      "name": "Bruxelles-Capitale, Région de",
      "short_code": "BRU"
    }, {
      "code": "BE-VLG",
      "name": "Vlaams Gewest",
      "short_code": "VLG"
    }, {
      "code": "BE-VAN",
      "name": "Antwerpen",
      "short_code": "VAN"
    }, {
      "code": "BE-VBR",
      "name": "Vlaams-Brabant",
      "short_code": "VBR"
    }, {
      "code": "BE-VLI",
      "name": "Limburg",
      "short_code": "VLI"
    }, {
      "code": "BE-VOV",
      "name": "Oost-Vlaanderen",
      "short_code": "VOV"
    }, {
      "code": "BE-VWV",
      "name": "West-Vlaanderen",
      "short_code": "VWV"
    }, {
      "code": "BE-WAL",
      "name": "wallonne, Région",
      "short_code": "WAL"
    }, {
      "code": "BE-WBR",
      "name": "Brabant wallon",
      "short_code": "WBR"
    }, {
      "code": "BE-WHT",
      "name": "Hainaut",
      "short_code": "WHT"
    }, {
      "code": "BE-WLG",
      "name": "Liège",
      "short_code": "WLG"
    }, {
      "code": "BE-WLX",
      "name": "Luxembourg",
      "short_code": "WLX"
    }, {
      "code": "BE-WNA",
      "name": "Namur",
      "short_code": "WNA"
    }]
  }, {
    "code": "BRA",
    "name": "Brazil",
    "subdivisions": [{
      "code": "BR-AC",
      "name": "Acre",
      "short_code": "AC"
    }, {
      "code": "BR-AL",
      "name": "Alagoas",
      "short_code": "AL"
    }, {
      "code": "BR-AM",
      "name": "Amazonas",
      "short_code": "AM"
    }, {
      "code": "BR-AP",
      "name": "Amapá",
      "short_code": "AP"
    }, {
      "code": "BR-BA",
      "name": "Bahia",
      "short_code": "BA"
    }, {
      "code": "BR-CE",
      "name": "Ceará",
      "short_code": "CE"
    }, {
      "code": "BR-ES",
      "name": "Espírito Santo",
      "short_code": "ES"
    }, {
      "code": "BR-GO",
      "name": "Goiás",
      "short_code": "GO"
    }, {
      "code": "BR-MA",
      "name": "Maranhão",
      "short_code": "MA"
    }, {
      "code": "BR-MG",
      "name": "Minas Gerais",
      "short_code": "MG"
    }, {
      "code": "BR-MS",
      "name": "Mato Grosso do Sul",
      "short_code": "MS"
    }, {
      "code": "BR-MT",
      "name": "Mato Grosso",
      "short_code": "MT"
    }, {
      "code": "BR-PA",
      "name": "Pará",
      "short_code": "PA"
    }, {
      "code": "BR-PB",
      "name": "Paraíba",
      "short_code": "PB"
    }, {
      "code": "BR-PE",
      "name": "Pernambuco",
      "short_code": "PE"
    }, {
      "code": "BR-PI",
      "name": "Piauí",
      "short_code": "PI"
    }, {
      "code": "BR-PR",
      "name": "Paraná",
      "short_code": "PR"
    }, {
      "code": "BR-RJ",
      "name": "Rio de Janeiro",
      "short_code": "RJ"
    }, {
      "code": "BR-RN",
      "name": "Rio Grande do Norte",
      "short_code": "RN"
    }, {
      "code": "BR-RO",
      "name": "Rondônia",
      "short_code": "RO"
    }, {
      "code": "BR-RR",
      "name": "Roraima",
      "short_code": "RR"
    }, {
      "code": "BR-RS",
      "name": "Rio Grande do Sul",
      "short_code": "RS"
    }, {
      "code": "BR-SC",
      "name": "Santa Catarina",
      "short_code": "SC"
    }, {
      "code": "BR-SE",
      "name": "Sergipe",
      "short_code": "SE"
    }, {
      "code": "BR-SP",
      "name": "São Paulo",
      "short_code": "SP"
    }, {
      "code": "BR-TO",
      "name": "Tocantins",
      "short_code": "TO"
    }, {
      "code": "BR-DF",
      "name": "Distrito Federal",
      "short_code": "DF"
    }]
  }, {
    "code": "KHM",
    "name": "Cambodia",
    "subdivisions": [{
      "code": "KH-12",
      "name": "Phnum Pénh",
      "short_code": "12"
    }, {
      "code": "KH-1",
      "name": "Banteay Meanchey",
      "short_code": "1"
    }, {
      "code": "KH-10",
      "name": "Krâchéh",
      "short_code": "10"
    }, {
      "code": "KH-11",
      "name": "Môndól Kiri",
      "short_code": "11"
    }, {
      "code": "KH-13",
      "name": "Preăh Vihéar",
      "short_code": "13"
    }, {
      "code": "KH-14",
      "name": "Prey Veng",
      "short_code": "14"
    }, {
      "code": "KH-15",
      "name": "Poŭthĭsăt",
      "short_code": "15"
    }, {
      "code": "KH-16",
      "name": "Ratanakiri",
      "short_code": "16"
    }, {
      "code": "KH-17",
      "name": "Siem Reap",
      "short_code": "17"
    }, {
      "code": "KH-18",
      "name": "Preăh Sihanouk",
      "short_code": "18"
    }, {
      "code": "KH-19",
      "name": "Stoĕng Trêng",
      "short_code": "19"
    }, {
      "code": "KH-2",
      "name": "Battambang",
      "short_code": "2"
    }, {
      "code": "KH-20",
      "name": "Svay Riĕng",
      "short_code": "20"
    }, {
      "code": "KH-21",
      "name": "Takeo",
      "short_code": "21"
    }, {
      "code": "KH-22",
      "name": "Ŏtdâr Méanchey",
      "short_code": "22"
    }, {
      "code": "KH-23",
      "name": "Kep",
      "short_code": "23"
    }, {
      "code": "KH-24",
      "name": "Pailĭn",
      "short_code": "24"
    }, {
      "code": "KH-25",
      "name": "Tbong Khmum",
      "short_code": "25"
    }, {
      "code": "KH-3",
      "name": "Kampong Cham",
      "short_code": "3"
    }, {
      "code": "KH-4",
      "name": "Kampong Chhnang",
      "short_code": "4"
    }, {
      "code": "KH-5",
      "name": "Kampong Speu",
      "short_code": "5"
    }, {
      "code": "KH-6",
      "name": "Kâmpóng Thum",
      "short_code": "6"
    }, {
      "code": "KH-7",
      "name": "Kampot",
      "short_code": "7"
    }, {
      "code": "KH-8",
      "name": "Kandal",
      "short_code": "8"
    }, {
      "code": "KH-9",
      "name": "Koh Kong",
      "short_code": "9"
    }]
  }, {
    "code": "CAN",
    "name": "Canada",
    "subdivisions": [{
      "code": "CA-NT",
      "name": "Northwest Territories",
      "short_code": "NT"
    }, {
      "code": "CA-NU",
      "name": "Nunavut",
      "short_code": "NU"
    }, {
      "code": "CA-YT",
      "name": "Yukon",
      "short_code": "YT"
    }, {
      "code": "CA-AB",
      "name": "Alberta",
      "short_code": "AB"
    }, {
      "code": "CA-BC",
      "name": "British Columbia",
      "short_code": "BC"
    }, {
      "code": "CA-MB",
      "name": "Manitoba",
      "short_code": "MB"
    }, {
      "code": "CA-NB",
      "name": "New Brunswick",
      "short_code": "NB"
    }, {
      "code": "CA-NL",
      "name": "Newfoundland and Labrador",
      "short_code": "NL"
    }, {
      "code": "CA-NS",
      "name": "Nova Scotia",
      "short_code": "NS"
    }, {
      "code": "CA-ON",
      "name": "Ontario",
      "short_code": "ON"
    }, {
      "code": "CA-PE",
      "name": "Prince Edward Island",
      "short_code": "PE"
    }, {
      "code": "CA-QC",
      "name": "Quebec",
      "short_code": "QC"
    }, {
      "code": "CA-SK",
      "name": "Saskatchewan",
      "short_code": "SK"
    }]
  }, {
    "code": "HRV",
    "name": "Croatia",
    "subdivisions": [{
      "code": "HR-01",
      "name": "Zagrebačka županija",
      "short_code": "01"
    }, {
      "code": "HR-02",
      "name": "Krapinsko-zagorska županija",
      "short_code": "02"
    }, {
      "code": "HR-03",
      "name": "Sisačko-moslavačka županija",
      "short_code": "03"
    }, {
      "code": "HR-04",
      "name": "Karlovačka županija",
      "short_code": "04"
    }, {
      "code": "HR-05",
      "name": "Varaždinska županija",
      "short_code": "05"
    }, {
      "code": "HR-06",
      "name": "Koprivničko-križevačka županija",
      "short_code": "06"
    }, {
      "code": "HR-07",
      "name": "Bjelovarsko-bilogorska županija",
      "short_code": "07"
    }, {
      "code": "HR-08",
      "name": "Primorsko-goranska županija",
      "short_code": "08"
    }, {
      "code": "HR-09",
      "name": "Ličko-senjska županija",
      "short_code": "09"
    }, {
      "code": "HR-10",
      "name": "Virovitičko-podravska županija",
      "short_code": "10"
    }, {
      "code": "HR-11",
      "name": "Požeško-slavonska županija",
      "short_code": "11"
    }, {
      "code": "HR-12",
      "name": "Brodsko-posavska županija",
      "short_code": "12"
    }, {
      "code": "HR-13",
      "name": "Zadarska županija",
      "short_code": "13"
    }, {
      "code": "HR-14",
      "name": "Osječko-baranjska županija",
      "short_code": "14"
    }, {
      "code": "HR-15",
      "name": "Šibensko-kninska županija",
      "short_code": "15"
    }, {
      "code": "HR-16",
      "name": "Vukovarsko-srijemska županija",
      "short_code": "16"
    }, {
      "code": "HR-17",
      "name": "Splitsko-dalmatinska županija",
      "short_code": "17"
    }, {
      "code": "HR-18",
      "name": "Istarska županija",
      "short_code": "18"
    }, {
      "code": "HR-19",
      "name": "Dubrovačko-neretvanska županija",
      "short_code": "19"
    }, {
      "code": "HR-20",
      "name": "Međimurska županija",
      "short_code": "20"
    }, {
      "code": "HR-21",
      "name": "Grad Zagreb",
      "short_code": "21"
    }]
  }, {
    "code": "CZE",
    "name": "Czech Republic",
    "subdivisions": [{
      "code": "CZ-20",
      "name": "Středočeský kraj",
      "short_code": "20"
    }, {
      "code": "CZ-201",
      "name": "Benešov",
      "short_code": "201"
    }, {
      "code": "CZ-202",
      "name": "Beroun",
      "short_code": "202"
    }, {
      "code": "CZ-203",
      "name": "Kladno",
      "short_code": "203"
    }, {
      "code": "CZ-204",
      "name": "Kolín",
      "short_code": "204"
    }, {
      "code": "CZ-205",
      "name": "Kutná Hora",
      "short_code": "205"
    }, {
      "code": "CZ-206",
      "name": "Mělník",
      "short_code": "206"
    }, {
      "code": "CZ-207",
      "name": "Mladá Boleslav",
      "short_code": "207"
    }, {
      "code": "CZ-208",
      "name": "Nymburk",
      "short_code": "208"
    }, {
      "code": "CZ-209",
      "name": "Praha-východ",
      "short_code": "209"
    }, {
      "code": "CZ-20A",
      "name": "Praha-západ",
      "short_code": "20A"
    }, {
      "code": "CZ-20B",
      "name": "Příbram",
      "short_code": "20B"
    }, {
      "code": "CZ-20C",
      "name": "Rakovník",
      "short_code": "20C"
    }, {
      "code": "CZ-31",
      "name": "Jihočeský kraj",
      "short_code": "31"
    }, {
      "code": "CZ-311",
      "name": "České Budějovice",
      "short_code": "311"
    }, {
      "code": "CZ-312",
      "name": "Český Krumlov",
      "short_code": "312"
    }, {
      "code": "CZ-313",
      "name": "Jindřichův Hradec",
      "short_code": "313"
    }, {
      "code": "CZ-314",
      "name": "Písek",
      "short_code": "314"
    }, {
      "code": "CZ-315",
      "name": "Prachatice",
      "short_code": "315"
    }, {
      "code": "CZ-316",
      "name": "Strakonice",
      "short_code": "316"
    }, {
      "code": "CZ-317",
      "name": "Tábor",
      "short_code": "317"
    }, {
      "code": "CZ-32",
      "name": "Plzeňský kraj",
      "short_code": "32"
    }, {
      "code": "CZ-321",
      "name": "Domažlice",
      "short_code": "321"
    }, {
      "code": "CZ-322",
      "name": "Klatovy",
      "short_code": "322"
    }, {
      "code": "CZ-323",
      "name": "Plzeň-město",
      "short_code": "323"
    }, {
      "code": "CZ-324",
      "name": "Plzeň-jih",
      "short_code": "324"
    }, {
      "code": "CZ-325",
      "name": "Plzeň-sever",
      "short_code": "325"
    }, {
      "code": "CZ-326",
      "name": "Rokycany",
      "short_code": "326"
    }, {
      "code": "CZ-327",
      "name": "Tachov",
      "short_code": "327"
    }, {
      "code": "CZ-41",
      "name": "Karlovarský kraj",
      "short_code": "41"
    }, {
      "code": "CZ-411",
      "name": "Cheb",
      "short_code": "411"
    }, {
      "code": "CZ-412",
      "name": "Karlovy Vary",
      "short_code": "412"
    }, {
      "code": "CZ-413",
      "name": "Sokolov",
      "short_code": "413"
    }, {
      "code": "CZ-42",
      "name": "Ústecký kraj",
      "short_code": "42"
    }, {
      "code": "CZ-421",
      "name": "Děčín",
      "short_code": "421"
    }, {
      "code": "CZ-422",
      "name": "Chomutov",
      "short_code": "422"
    }, {
      "code": "CZ-423",
      "name": "Litoměřice",
      "short_code": "423"
    }, {
      "code": "CZ-424",
      "name": "Louny",
      "short_code": "424"
    }, {
      "code": "CZ-425",
      "name": "Most",
      "short_code": "425"
    }, {
      "code": "CZ-426",
      "name": "Teplice",
      "short_code": "426"
    }, {
      "code": "CZ-427",
      "name": "Ústí nad Labem",
      "short_code": "427"
    }, {
      "code": "CZ-51",
      "name": "Liberecký kraj",
      "short_code": "51"
    }, {
      "code": "CZ-511",
      "name": "Česká Lípa",
      "short_code": "511"
    }, {
      "code": "CZ-512",
      "name": "Jablonec nad Nisou",
      "short_code": "512"
    }, {
      "code": "CZ-513",
      "name": "Liberec",
      "short_code": "513"
    }, {
      "code": "CZ-514",
      "name": "Semily",
      "short_code": "514"
    }, {
      "code": "CZ-52",
      "name": "Královéhradecký kraj",
      "short_code": "52"
    }, {
      "code": "CZ-521",
      "name": "Hradec Králové",
      "short_code": "521"
    }, {
      "code": "CZ-522",
      "name": "Jičín",
      "short_code": "522"
    }, {
      "code": "CZ-523",
      "name": "Náchod",
      "short_code": "523"
    }, {
      "code": "CZ-524",
      "name": "Rychnov nad Kněžnou",
      "short_code": "524"
    }, {
      "code": "CZ-525",
      "name": "Trutnov",
      "short_code": "525"
    }, {
      "code": "CZ-53",
      "name": "Pardubický kraj",
      "short_code": "53"
    }, {
      "code": "CZ-531",
      "name": "Chrudim",
      "short_code": "531"
    }, {
      "code": "CZ-532",
      "name": "Pardubice",
      "short_code": "532"
    }, {
      "code": "CZ-533",
      "name": "Svitavy",
      "short_code": "533"
    }, {
      "code": "CZ-534",
      "name": "Ústí nad Orlicí",
      "short_code": "534"
    }, {
      "code": "CZ-63",
      "name": "Kraj Vysočina",
      "short_code": "63"
    }, {
      "code": "CZ-631",
      "name": "Havlíčkův Brod",
      "short_code": "631"
    }, {
      "code": "CZ-632",
      "name": "Jihlava",
      "short_code": "632"
    }, {
      "code": "CZ-633",
      "name": "Pelhřimov",
      "short_code": "633"
    }, {
      "code": "CZ-634",
      "name": "Třebíč",
      "short_code": "634"
    }, {
      "code": "CZ-635",
      "name": "Žďár nad Sázavou",
      "short_code": "635"
    }, {
      "code": "CZ-64",
      "name": "Jihomoravský kraj",
      "short_code": "64"
    }, {
      "code": "CZ-641",
      "name": "Blansko",
      "short_code": "641"
    }, {
      "code": "CZ-642",
      "name": "Brno-město",
      "short_code": "642"
    }, {
      "code": "CZ-643",
      "name": "Brno-venkov",
      "short_code": "643"
    }, {
      "code": "CZ-644",
      "name": "Břeclav",
      "short_code": "644"
    }, {
      "code": "CZ-645",
      "name": "Hodonín",
      "short_code": "645"
    }, {
      "code": "CZ-646",
      "name": "Vyškov",
      "short_code": "646"
    }, {
      "code": "CZ-647",
      "name": "Znojmo",
      "short_code": "647"
    }, {
      "code": "CZ-71",
      "name": "Olomoucký kraj",
      "short_code": "71"
    }, {
      "code": "CZ-711",
      "name": "Jeseník",
      "short_code": "711"
    }, {
      "code": "CZ-712",
      "name": "Olomouc",
      "short_code": "712"
    }, {
      "code": "CZ-713",
      "name": "Prostějov",
      "short_code": "713"
    }, {
      "code": "CZ-714",
      "name": "Přerov",
      "short_code": "714"
    }, {
      "code": "CZ-715",
      "name": "Šumperk",
      "short_code": "715"
    }, {
      "code": "CZ-72",
      "name": "Zlínský kraj",
      "short_code": "72"
    }, {
      "code": "CZ-721",
      "name": "Kroměříž",
      "short_code": "721"
    }, {
      "code": "CZ-722",
      "name": "Uherské Hradiště",
      "short_code": "722"
    }, {
      "code": "CZ-723",
      "name": "Vsetín",
      "short_code": "723"
    }, {
      "code": "CZ-724",
      "name": "Zlín",
      "short_code": "724"
    }, {
      "code": "CZ-80",
      "name": "Moravskoslezský kraj",
      "short_code": "80"
    }, {
      "code": "CZ-801",
      "name": "Bruntál",
      "short_code": "801"
    }, {
      "code": "CZ-802",
      "name": "Frýdek Místek",
      "short_code": "802"
    }, {
      "code": "CZ-803",
      "name": "Karviná",
      "short_code": "803"
    }, {
      "code": "CZ-804",
      "name": "Nový Jičín",
      "short_code": "804"
    }, {
      "code": "CZ-805",
      "name": "Opava",
      "short_code": "805"
    }, {
      "code": "CZ-806",
      "name": "Ostrava-město",
      "short_code": "806"
    }, {
      "code": "CZ-10",
      "name": "Praha, Hlavní mešto",
      "short_code": "10"
    }]
  }, {
    "code": "DNK",
    "name": "Denmark",
    "subdivisions": [{
      "code": "DK-81",
      "name": "Nordjylland",
      "short_code": "81"
    }, {
      "code": "DK-82",
      "name": "Midtjylland",
      "short_code": "82"
    }, {
      "code": "DK-83",
      "name": "Syddanmark",
      "short_code": "83"
    }, {
      "code": "DK-84",
      "name": "Hovedstaden",
      "short_code": "84"
    }, {
      "code": "DK-85",
      "name": "Sjælland",
      "short_code": "85"
    }]
  }, {
    "code": "EST",
    "name": "Estonia",
    "subdivisions": [{
      "code": "EE-37",
      "name": "Harjumaa",
      "short_code": "37"
    }, {
      "code": "EE-39",
      "name": "Hiiumaa",
      "short_code": "39"
    }, {
      "code": "EE-44",
      "name": "Ida-Virumaa",
      "short_code": "44"
    }, {
      "code": "EE-49",
      "name": "Jõgevamaa",
      "short_code": "49"
    }, {
      "code": "EE-51",
      "name": "Järvamaa",
      "short_code": "51"
    }, {
      "code": "EE-57",
      "name": "Läänemaa",
      "short_code": "57"
    }, {
      "code": "EE-59",
      "name": "Lääne-Virumaa",
      "short_code": "59"
    }, {
      "code": "EE-65",
      "name": "Põlvamaa",
      "short_code": "65"
    }, {
      "code": "EE-67",
      "name": "Pärnumaa",
      "short_code": "67"
    }, {
      "code": "EE-70",
      "name": "Raplamaa",
      "short_code": "70"
    }, {
      "code": "EE-74",
      "name": "Saaremaa",
      "short_code": "74"
    }, {
      "code": "EE-78",
      "name": "Tartumaa",
      "short_code": "78"
    }, {
      "code": "EE-82",
      "name": "Valgamaa",
      "short_code": "82"
    }, {
      "code": "EE-84",
      "name": "Viljandimaa",
      "short_code": "84"
    }, {
      "code": "EE-86",
      "name": "Võrumaa",
      "short_code": "86"
    }]
  }, {
    "code": "FIN",
    "name": "Finland",
    "subdivisions": [{
      "code": "FI-01",
      "name": "Ahvenanmaan maakunta",
      "short_code": "01"
    }, {
      "code": "FI-02",
      "name": "Etelä-Karjala",
      "short_code": "02"
    }, {
      "code": "FI-03",
      "name": "Etelä-Pohjanmaa",
      "short_code": "03"
    }, {
      "code": "FI-04",
      "name": "Etelä-Savo",
      "short_code": "04"
    }, {
      "code": "FI-05",
      "name": "Kainuu",
      "short_code": "05"
    }, {
      "code": "FI-06",
      "name": "Kanta-Häme",
      "short_code": "06"
    }, {
      "code": "FI-07",
      "name": "Keski-Pohjanmaa",
      "short_code": "07"
    }, {
      "code": "FI-08",
      "name": "Keski-Suomi",
      "short_code": "08"
    }, {
      "code": "FI-09",
      "name": "Kymenlaakso",
      "short_code": "09"
    }, {
      "code": "FI-10",
      "name": "Lappi",
      "short_code": "10"
    }, {
      "code": "FI-11",
      "name": "Pirkanmaa",
      "short_code": "11"
    }, {
      "code": "FI-12",
      "name": "Pohjanmaa",
      "short_code": "12"
    }, {
      "code": "FI-13",
      "name": "Pohjois-Karjala",
      "short_code": "13"
    }, {
      "code": "FI-14",
      "name": "Pohjois-Pohjanmaa",
      "short_code": "14"
    }, {
      "code": "FI-15",
      "name": "Pohjois-Savo",
      "short_code": "15"
    }, {
      "code": "FI-16",
      "name": "Päijät-Häme",
      "short_code": "16"
    }, {
      "code": "FI-17",
      "name": "Satakunta",
      "short_code": "17"
    }, {
      "code": "FI-18",
      "name": "Uusimaa",
      "short_code": "18"
    }, {
      "code": "FI-19",
      "name": "Varsinais-Suomi",
      "short_code": "19"
    }]
  }, {
    "code": "FRA",
    "name": "France",
    "subdivisions": [{
      "code": "FR-ARA",
      "name": "Auvergne-Rhône-Alpes",
      "short_code": "ARA"
    }, {
      "code": "FR-01",
      "name": "Ain",
      "short_code": "01"
    }, {
      "code": "FR-03",
      "name": "Allier",
      "short_code": "03"
    }, {
      "code": "FR-07",
      "name": "Ardèche",
      "short_code": "07"
    }, {
      "code": "FR-15",
      "name": "Cantal",
      "short_code": "15"
    }, {
      "code": "FR-26",
      "name": "Drôme",
      "short_code": "26"
    }, {
      "code": "FR-38",
      "name": "Isère",
      "short_code": "38"
    }, {
      "code": "FR-42",
      "name": "Loire",
      "short_code": "42"
    }, {
      "code": "FR-43",
      "name": "Haute-Loire",
      "short_code": "43"
    }, {
      "code": "FR-63",
      "name": "Puy-de-Dôme",
      "short_code": "63"
    }, {
      "code": "FR-69",
      "name": "Rhône",
      "short_code": "69"
    }, {
      "code": "FR-73",
      "name": "Savoie",
      "short_code": "73"
    }, {
      "code": "FR-74",
      "name": "Haute-Savoie",
      "short_code": "74"
    }, {
      "code": "FR-BFC",
      "name": "Bourgogne-Franche-Comté",
      "short_code": "BFC"
    }, {
      "code": "FR-21",
      "name": "Côte-d'Or",
      "short_code": "21"
    }, {
      "code": "FR-25",
      "name": "Doubs",
      "short_code": "25"
    }, {
      "code": "FR-39",
      "name": "Jura",
      "short_code": "39"
    }, {
      "code": "FR-58",
      "name": "Nièvre",
      "short_code": "58"
    }, {
      "code": "FR-70",
      "name": "Haute-Saône",
      "short_code": "70"
    }, {
      "code": "FR-71",
      "name": "Saône-et-Loire",
      "short_code": "71"
    }, {
      "code": "FR-89",
      "name": "Yonne",
      "short_code": "89"
    }, {
      "code": "FR-90",
      "name": "Territoire de Belfort",
      "short_code": "90"
    }, {
      "code": "FR-BRE",
      "name": "Bretagne",
      "short_code": "BRE"
    }, {
      "code": "FR-22",
      "name": "Côtes-d'Armor",
      "short_code": "22"
    }, {
      "code": "FR-29",
      "name": "Finistère",
      "short_code": "29"
    }, {
      "code": "FR-35",
      "name": "Ille-et-Vilaine",
      "short_code": "35"
    }, {
      "code": "FR-56",
      "name": "Morbihan",
      "short_code": "56"
    }, {
      "code": "FR-CVL",
      "name": "Centre-Val de Loire",
      "short_code": "CVL"
    }, {
      "code": "FR-18",
      "name": "Cher",
      "short_code": "18"
    }, {
      "code": "FR-28",
      "name": "Eure-et-Loir",
      "short_code": "28"
    }, {
      "code": "FR-36",
      "name": "Indre",
      "short_code": "36"
    }, {
      "code": "FR-37",
      "name": "Indre-et-Loire",
      "short_code": "37"
    }, {
      "code": "FR-41",
      "name": "Loir-et-Cher",
      "short_code": "41"
    }, {
      "code": "FR-45",
      "name": "Loiret",
      "short_code": "45"
    }, {
      "code": "FR-GES",
      "name": "Grand-Est",
      "short_code": "GES"
    }, {
      "code": "FR-08",
      "name": "Ardennes",
      "short_code": "08"
    }, {
      "code": "FR-10",
      "name": "Aube",
      "short_code": "10"
    }, {
      "code": "FR-51",
      "name": "Marne",
      "short_code": "51"
    }, {
      "code": "FR-52",
      "name": "Haute-Marne",
      "short_code": "52"
    }, {
      "code": "FR-54",
      "name": "Meurthe-et-Moselle",
      "short_code": "54"
    }, {
      "code": "FR-55",
      "name": "Meuse",
      "short_code": "55"
    }, {
      "code": "FR-57",
      "name": "Moselle",
      "short_code": "57"
    }, {
      "code": "FR-67",
      "name": "Bas-Rhin",
      "short_code": "67"
    }, {
      "code": "FR-68",
      "name": "Haut-Rhin",
      "short_code": "68"
    }, {
      "code": "FR-88",
      "name": "Vosges",
      "short_code": "88"
    }, {
      "code": "FR-HDF",
      "name": "Hauts-de-France",
      "short_code": "HDF"
    }, {
      "code": "FR-02",
      "name": "Aisne",
      "short_code": "02"
    }, {
      "code": "FR-59",
      "name": "Nord",
      "short_code": "59"
    }, {
      "code": "FR-60",
      "name": "Oise",
      "short_code": "60"
    }, {
      "code": "FR-62",
      "name": "Pas-de-Calais",
      "short_code": "62"
    }, {
      "code": "FR-80",
      "name": "Somme",
      "short_code": "80"
    }, {
      "code": "FR-IDF",
      "name": "Île-de-France",
      "short_code": "IDF"
    }, {
      "code": "FR-75",
      "name": "Paris",
      "short_code": "75"
    }, {
      "code": "FR-77",
      "name": "Seine-et-Marne",
      "short_code": "77"
    }, {
      "code": "FR-78",
      "name": "Yvelines",
      "short_code": "78"
    }, {
      "code": "FR-91",
      "name": "Essonne",
      "short_code": "91"
    }, {
      "code": "FR-92",
      "name": "Hauts-de-Seine",
      "short_code": "92"
    }, {
      "code": "FR-93",
      "name": "Seine-Saint-Denis",
      "short_code": "93"
    }, {
      "code": "FR-94",
      "name": "Val-de-Marne",
      "short_code": "94"
    }, {
      "code": "FR-95",
      "name": "Val-d'Oise",
      "short_code": "95"
    }, {
      "code": "FR-NAQ",
      "name": "Nouvelle-Aquitaine",
      "short_code": "NAQ"
    }, {
      "code": "FR-16",
      "name": "Charente",
      "short_code": "16"
    }, {
      "code": "FR-17",
      "name": "Charente-Maritime",
      "short_code": "17"
    }, {
      "code": "FR-19",
      "name": "Corrèze",
      "short_code": "19"
    }, {
      "code": "FR-23",
      "name": "Creuse",
      "short_code": "23"
    }, {
      "code": "FR-24",
      "name": "Dordogne",
      "short_code": "24"
    }, {
      "code": "FR-33",
      "name": "Gironde",
      "short_code": "33"
    }, {
      "code": "FR-40",
      "name": "Landes",
      "short_code": "40"
    }, {
      "code": "FR-47",
      "name": "Lot-et-Garonne",
      "short_code": "47"
    }, {
      "code": "FR-64",
      "name": "Pyrénées-Atlantiques",
      "short_code": "64"
    }, {
      "code": "FR-79",
      "name": "Deux-Sèvres",
      "short_code": "79"
    }, {
      "code": "FR-86",
      "name": "Vienne",
      "short_code": "86"
    }, {
      "code": "FR-87",
      "name": "Haute-Vienne",
      "short_code": "87"
    }, {
      "code": "FR-NOR",
      "name": "Normandie",
      "short_code": "NOR"
    }, {
      "code": "FR-14",
      "name": "Calvados",
      "short_code": "14"
    }, {
      "code": "FR-27",
      "name": "Eure",
      "short_code": "27"
    }, {
      "code": "FR-50",
      "name": "Manche",
      "short_code": "50"
    }, {
      "code": "FR-61",
      "name": "Orne",
      "short_code": "61"
    }, {
      "code": "FR-76",
      "name": "Seine-Maritime",
      "short_code": "76"
    }, {
      "code": "FR-OCC",
      "name": "Occitanie",
      "short_code": "OCC"
    }, {
      "code": "FR-09",
      "name": "Ariège",
      "short_code": "09"
    }, {
      "code": "FR-11",
      "name": "Aude",
      "short_code": "11"
    }, {
      "code": "FR-12",
      "name": "Aveyron",
      "short_code": "12"
    }, {
      "code": "FR-30",
      "name": "Gard",
      "short_code": "30"
    }, {
      "code": "FR-31",
      "name": "Haute-Garonne",
      "short_code": "31"
    }, {
      "code": "FR-32",
      "name": "Gers",
      "short_code": "32"
    }, {
      "code": "FR-34",
      "name": "Hérault",
      "short_code": "34"
    }, {
      "code": "FR-46",
      "name": "Lot",
      "short_code": "46"
    }, {
      "code": "FR-48",
      "name": "Lozère",
      "short_code": "48"
    }, {
      "code": "FR-65",
      "name": "Hautes-Pyrénées",
      "short_code": "65"
    }, {
      "code": "FR-66",
      "name": "Pyrénées-Orientales",
      "short_code": "66"
    }, {
      "code": "FR-81",
      "name": "Tarn",
      "short_code": "81"
    }, {
      "code": "FR-82",
      "name": "Tarn-et-Garonne",
      "short_code": "82"
    }, {
      "code": "FR-PAC",
      "name": "Provence-Alpes-Côte-d’Azur",
      "short_code": "PAC"
    }, {
      "code": "FR-04",
      "name": "Alpes-de-Haute-Provence",
      "short_code": "04"
    }, {
      "code": "FR-05",
      "name": "Hautes-Alpes",
      "short_code": "05"
    }, {
      "code": "FR-06",
      "name": "Alpes-Maritimes",
      "short_code": "06"
    }, {
      "code": "FR-13",
      "name": "Bouches-du-Rhône",
      "short_code": "13"
    }, {
      "code": "FR-83",
      "name": "Var",
      "short_code": "83"
    }, {
      "code": "FR-84",
      "name": "Vaucluse",
      "short_code": "84"
    }, {
      "code": "FR-PDL",
      "name": "Pays-de-la-Loire",
      "short_code": "PDL"
    }, {
      "code": "FR-44",
      "name": "Loire-Atlantique",
      "short_code": "44"
    }, {
      "code": "FR-49",
      "name": "Maine-et-Loire",
      "short_code": "49"
    }, {
      "code": "FR-53",
      "name": "Mayenne",
      "short_code": "53"
    }, {
      "code": "FR-72",
      "name": "Sarthe",
      "short_code": "72"
    }, {
      "code": "FR-85",
      "name": "Vendée",
      "short_code": "85"
    }, {
      "code": "FR-CP",
      "name": "Clipperton",
      "short_code": "CP"
    }, {
      "code": "FR-BL",
      "name": "Saint-Barthélemy",
      "short_code": "BL"
    }, {
      "code": "FR-GF",
      "name": "Guyane (française)",
      "short_code": "GF"
    }, {
      "code": "FR-MF",
      "name": "Saint-Martin",
      "short_code": "MF"
    }, {
      "code": "FR-MQ",
      "name": "Martinique",
      "short_code": "MQ"
    }, {
      "code": "FR-NC",
      "name": "Nouvelle-Calédonie",
      "short_code": "NC"
    }, {
      "code": "FR-PF",
      "name": "Polynésie française",
      "short_code": "PF"
    }, {
      "code": "FR-PM",
      "name": "Saint-Pierre-et-Miquelon",
      "short_code": "PM"
    }, {
      "code": "FR-TF",
      "name": "Terres australes françaises",
      "short_code": "TF"
    }, {
      "code": "FR-WF",
      "name": "Wallis-et-Futuna ",
      "short_code": "WF"
    }, {
      "code": "FR-GUA",
      "name": "Guadeloupe",
      "short_code": "GUA"
    }, {
      "code": "FR-GP",
      "name": "Guadeloupe",
      "short_code": "GP"
    }, {
      "code": "FR-LRE",
      "name": "La Réunion",
      "short_code": "LRE"
    }, {
      "code": "FR-RE",
      "name": "La Réunion",
      "short_code": "RE"
    }, {
      "code": "FR-MAY",
      "name": "Mayotte",
      "short_code": "MAY"
    }, {
      "code": "FR-YT",
      "name": "Mayotte",
      "short_code": "YT"
    }, {
      "code": "FR-COR",
      "name": "Corse",
      "short_code": "COR"
    }, {
      "code": "FR-2A",
      "name": "Corse-du-Sud",
      "short_code": "2A"
    }, {
      "code": "FR-2B",
      "name": "Haute-Corse",
      "short_code": "2B"
    }]
  }, {
    "code": "DEU",
    "name": "Germany",
    "subdivisions": [{
      "code": "DE-BB",
      "name": "Brandenburg",
      "short_code": "BB"
    }, {
      "code": "DE-BE",
      "name": "Berlin",
      "short_code": "BE"
    }, {
      "code": "DE-BW",
      "name": "Baden-Württemberg",
      "short_code": "BW"
    }, {
      "code": "DE-BY",
      "name": "Bayern",
      "short_code": "BY"
    }, {
      "code": "DE-HB",
      "name": "Bremen",
      "short_code": "HB"
    }, {
      "code": "DE-HE",
      "name": "Hessen",
      "short_code": "HE"
    }, {
      "code": "DE-HH",
      "name": "Hamburg",
      "short_code": "HH"
    }, {
      "code": "DE-MV",
      "name": "Mecklenburg-Vorpommern",
      "short_code": "MV"
    }, {
      "code": "DE-NI",
      "name": "Niedersachsen",
      "short_code": "NI"
    }, {
      "code": "DE-NW",
      "name": "Nordrhein-Westfalen",
      "short_code": "NW"
    }, {
      "code": "DE-RP",
      "name": "Rheinland-Pfalz",
      "short_code": "RP"
    }, {
      "code": "DE-SH",
      "name": "Schleswig-Holstein",
      "short_code": "SH"
    }, {
      "code": "DE-SL",
      "name": "Saarland",
      "short_code": "SL"
    }, {
      "code": "DE-SN",
      "name": "Sachsen",
      "short_code": "SN"
    }, {
      "code": "DE-ST",
      "name": "Sachsen-Anhalt",
      "short_code": "ST"
    }, {
      "code": "DE-TH",
      "name": "Thüringen",
      "short_code": "TH"
    }]
  }, {
    "code": "GRC",
    "name": "Greece",
    "subdivisions": [{
      "code": "GR-69",
      "name": "Ágion Óros",
      "short_code": "69"
    }, {
      "code": "GR-A",
      "name": "Anatolikí Makedonía kai Thráki",
      "short_code": "A"
    }, {
      "code": "GR-B",
      "name": "Kentrikí Makedonía",
      "short_code": "B"
    }, {
      "code": "GR-C",
      "name": "Dytikí Makedonía",
      "short_code": "C"
    }, {
      "code": "GR-D",
      "name": "Ípeiros",
      "short_code": "D"
    }, {
      "code": "GR-E",
      "name": "Thessalía",
      "short_code": "E"
    }, {
      "code": "GR-F",
      "name": "Ionía Nísia",
      "short_code": "F"
    }, {
      "code": "GR-G",
      "name": "Dytikí Elláda",
      "short_code": "G"
    }, {
      "code": "GR-H",
      "name": "Stereá Elláda",
      "short_code": "H"
    }, {
      "code": "GR-I",
      "name": "Attikí",
      "short_code": "I"
    }, {
      "code": "GR-J",
      "name": "Peloponnísos",
      "short_code": "J"
    }, {
      "code": "GR-K",
      "name": "Voreío Aigaío",
      "short_code": "K"
    }, {
      "code": "GR-L",
      "name": "Notío Aigaío",
      "short_code": "L"
    }, {
      "code": "GR-M",
      "name": "Kríti",
      "short_code": "M"
    }]
  }, {
    "code": "HKG",
    "name": "Hong Kong",
    "subdivisions": [{
      "code": "HK-HCW",
      "name": "Central and Western Hong Kong Island",
      "short_code": "HCW"
    }, {
      "code": "HK-HEA",
      "name": "Eastern Hong Kong Island",
      "short_code": "HEA"
    }, {
      "code": "HK-HSO",
      "name": "Southern Hong Kong Island",
      "short_code": "HSO"
    }, {
      "code": "HK-HWC",
      "name": "Wan Chai Hong Kong Island",
      "short_code": "HWC"
    }, {
      "code": "HK-KKC",
      "name": "Kowloon City Kowloon",
      "short_code": "KKC"
    }, {
      "code": "HK-KKT",
      "name": "Kwun Tong Kowloon",
      "short_code": "KKT"
    }, {
      "code": "HK-KSS",
      "name": "Sham Shui Po Kowloon",
      "short_code": "KSS"
    }, {
      "code": "HK-KWT",
      "name": "Wong Tai Sin Kowloon",
      "short_code": "KWT"
    }, {
      "code": "HK-KYT",
      "name": "Yau Tsim Mong Kowloon",
      "short_code": "KYT"
    }, {
      "code": "HK-NIS",
      "name": "Islands New Territories",
      "short_code": "NIS"
    }, {
      "code": "HK-NKT",
      "name": "Kwai Tsing New Territories",
      "short_code": "NKT"
    }, {
      "code": "HK-NNO",
      "name": "North New Territories",
      "short_code": "NNO"
    }, {
      "code": "HK-NSK",
      "name": "Sai Kung New Territories",
      "short_code": "NSK"
    }, {
      "code": "HK-NST",
      "name": "Sha Tin New Territories",
      "short_code": "NST"
    }, {
      "code": "HK-NTM",
      "name": "Tuen Mun New Territories",
      "short_code": "NTM"
    }, {
      "code": "HK-NTP",
      "name": "Tai Po New Territories",
      "short_code": "NTP"
    }, {
      "code": "HK-NTW",
      "name": "Tsuen Wan New Territories",
      "short_code": "NTW"
    }, {
      "code": "HK-NYL",
      "name": "Yuen Long New Territories",
      "short_code": "NYL"
    }]
  }, {
    "code": "HUN",
    "name": "Hungary",
    "subdivisions": [{
      "code": "HU-BA",
      "name": "Baranya",
      "short_code": "BA"
    }, {
      "code": "HU-BE",
      "name": "Békés",
      "short_code": "BE"
    }, {
      "code": "HU-BK",
      "name": "Bács-Kiskun",
      "short_code": "BK"
    }, {
      "code": "HU-BZ",
      "name": "Borsod-Abaúj-Zemplén",
      "short_code": "BZ"
    }, {
      "code": "HU-CS",
      "name": "Csongrád",
      "short_code": "CS"
    }, {
      "code": "HU-FE",
      "name": "Fejér",
      "short_code": "FE"
    }, {
      "code": "HU-GS",
      "name": "Győr-Moson-Sopron",
      "short_code": "GS"
    }, {
      "code": "HU-HB",
      "name": "Hajdú-Bihar",
      "short_code": "HB"
    }, {
      "code": "HU-HE",
      "name": "Heves",
      "short_code": "HE"
    }, {
      "code": "HU-JN",
      "name": "Jász-Nagykun-Szolnok",
      "short_code": "JN"
    }, {
      "code": "HU-KE",
      "name": "Komárom-Esztergom",
      "short_code": "KE"
    }, {
      "code": "HU-NO",
      "name": "Nógrád",
      "short_code": "NO"
    }, {
      "code": "HU-PE",
      "name": "Pest",
      "short_code": "PE"
    }, {
      "code": "HU-SO",
      "name": "Somogy",
      "short_code": "SO"
    }, {
      "code": "HU-SZ",
      "name": "Szabolcs-Szatmár-Bereg",
      "short_code": "SZ"
    }, {
      "code": "HU-TO",
      "name": "Tolna",
      "short_code": "TO"
    }, {
      "code": "HU-VA",
      "name": "Vas",
      "short_code": "VA"
    }, {
      "code": "HU-VE",
      "name": "Veszprém",
      "short_code": "VE"
    }, {
      "code": "HU-ZA",
      "name": "Zala",
      "short_code": "ZA"
    }, {
      "code": "HU-BC",
      "name": "Békéscsaba",
      "short_code": "BC"
    }, {
      "code": "HU-DE",
      "name": "Debrecen",
      "short_code": "DE"
    }, {
      "code": "HU-DU",
      "name": "Dunaújváros",
      "short_code": "DU"
    }, {
      "code": "HU-EG",
      "name": "Eger",
      "short_code": "EG"
    }, {
      "code": "HU-ER",
      "name": "Érd",
      "short_code": "ER"
    }, {
      "code": "HU-GY",
      "name": "Győr",
      "short_code": "GY"
    }, {
      "code": "HU-HV",
      "name": "Hódmezővásárhely",
      "short_code": "HV"
    }, {
      "code": "HU-KM",
      "name": "Kecskemét",
      "short_code": "KM"
    }, {
      "code": "HU-KV",
      "name": "Kaposvár",
      "short_code": "KV"
    }, {
      "code": "HU-MI",
      "name": "Miskolc",
      "short_code": "MI"
    }, {
      "code": "HU-NK",
      "name": "Nagykanizsa",
      "short_code": "NK"
    }, {
      "code": "HU-NY",
      "name": "Nyíregyháza",
      "short_code": "NY"
    }, {
      "code": "HU-PS",
      "name": "Pécs",
      "short_code": "PS"
    }, {
      "code": "HU-SD",
      "name": "Szeged",
      "short_code": "SD"
    }, {
      "code": "HU-SF",
      "name": "Székesfehérvár",
      "short_code": "SF"
    }, {
      "code": "HU-SH",
      "name": "Szombathely",
      "short_code": "SH"
    }, {
      "code": "HU-SK",
      "name": "Szolnok",
      "short_code": "SK"
    }, {
      "code": "HU-SN",
      "name": "Sopron",
      "short_code": "SN"
    }, {
      "code": "HU-SS",
      "name": "Szekszárd",
      "short_code": "SS"
    }, {
      "code": "HU-ST",
      "name": "Salgótarján",
      "short_code": "ST"
    }, {
      "code": "HU-TB",
      "name": "Tatabánya",
      "short_code": "TB"
    }, {
      "code": "HU-VM",
      "name": "Veszprém",
      "short_code": "VM"
    }, {
      "code": "HU-ZE",
      "name": "Zalaegerszeg",
      "short_code": "ZE"
    }, {
      "code": "HU-BU",
      "name": "Budapest",
      "short_code": "BU"
    }]
  }, {
    "code": "ISL",
    "name": "Iceland",
    "subdivisions": [{
      "code": "IS-1",
      "name": "Höfuðborgarsvæði",
      "short_code": "1"
    }, {
      "code": "IS-2",
      "name": "Suðurnes",
      "short_code": "2"
    }, {
      "code": "IS-3",
      "name": "Vesturland",
      "short_code": "3"
    }, {
      "code": "IS-4",
      "name": "Vestfirðir",
      "short_code": "4"
    }, {
      "code": "IS-5",
      "name": "Norðurland vestra",
      "short_code": "5"
    }, {
      "code": "IS-6",
      "name": "Norðurland eystra",
      "short_code": "6"
    }, {
      "code": "IS-7",
      "name": "Austurland",
      "short_code": "7"
    }, {
      "code": "IS-8",
      "name": "Suðurland",
      "short_code": "8"
    }]
  }, {
    "code": "ISR",
    "name": "Israel",
    "subdivisions": [{
      "code": "IL-D",
      "name": "El Janūbī",
      "short_code": "D"
    }, {
      "code": "IL-HA",
      "name": "Ḥeifa",
      "short_code": "HA"
    }, {
      "code": "IL-JM",
      "name": "El Quds",
      "short_code": "JM"
    }, {
      "code": "IL-M",
      "name": "El Awsaṭ",
      "short_code": "M"
    }, {
      "code": "IL-TA",
      "name": "Tell Abīb",
      "short_code": "TA"
    }, {
      "code": "IL-Z",
      "name": "Esh Shamālī",
      "short_code": "Z"
    }]
  }, {
    "code": "ITA",
    "name": "Italy",
    "subdivisions": [{
      "code": "IT-21",
      "name": "Piemonte",
      "short_code": "21"
    }, {
      "code": "IT-AL",
      "name": "Alessandria",
      "short_code": "AL"
    }, {
      "code": "IT-AT",
      "name": "Asti",
      "short_code": "AT"
    }, {
      "code": "IT-BI",
      "name": "Biella",
      "short_code": "BI"
    }, {
      "code": "IT-CN",
      "name": "Cuneo",
      "short_code": "CN"
    }, {
      "code": "IT-NO",
      "name": "Novara",
      "short_code": "NO"
    }, {
      "code": "IT-VB",
      "name": "Verbano-Cusio-Ossola",
      "short_code": "VB"
    }, {
      "code": "IT-VC",
      "name": "Vercelli",
      "short_code": "VC"
    }, {
      "code": "IT-TO",
      "name": "Torino",
      "short_code": "TO"
    }, {
      "code": "IT-23",
      "name": "Val d'Aoste",
      "short_code": "23"
    }, {
      "code": "IT-AO",
      "name": "Aoste",
      "short_code": "AO"
    }, {
      "code": "IT-25",
      "name": "Lombardia",
      "short_code": "25"
    }, {
      "code": "IT-BG",
      "name": "Bergamo",
      "short_code": "BG"
    }, {
      "code": "IT-BS",
      "name": "Brescia",
      "short_code": "BS"
    }, {
      "code": "IT-CO",
      "name": "Como",
      "short_code": "CO"
    }, {
      "code": "IT-CR",
      "name": "Cremona",
      "short_code": "CR"
    }, {
      "code": "IT-LC",
      "name": "Lecco",
      "short_code": "LC"
    }, {
      "code": "IT-LO",
      "name": "Lodi",
      "short_code": "LO"
    }, {
      "code": "IT-MB",
      "name": "Monza e Brianza",
      "short_code": "MB"
    }, {
      "code": "IT-MN",
      "name": "Mantova",
      "short_code": "MN"
    }, {
      "code": "IT-PV",
      "name": "Pavia",
      "short_code": "PV"
    }, {
      "code": "IT-SO",
      "name": "Sondrio",
      "short_code": "SO"
    }, {
      "code": "IT-VA",
      "name": "Varese",
      "short_code": "VA"
    }, {
      "code": "IT-MI",
      "name": "Milano",
      "short_code": "MI"
    }, {
      "code": "IT-32",
      "name": "Trentino-Südtirol",
      "short_code": "32"
    }, {
      "code": "IT-BZ",
      "name": "Bozen",
      "short_code": "BZ"
    }, {
      "code": "IT-TN",
      "name": "Trento",
      "short_code": "TN"
    }, {
      "code": "IT-34",
      "name": "Veneto",
      "short_code": "34"
    }, {
      "code": "IT-BL",
      "name": "Belluno",
      "short_code": "BL"
    }, {
      "code": "IT-PD",
      "name": "Padova",
      "short_code": "PD"
    }, {
      "code": "IT-RO",
      "name": "Rovigo",
      "short_code": "RO"
    }, {
      "code": "IT-TV",
      "name": "Treviso",
      "short_code": "TV"
    }, {
      "code": "IT-VI",
      "name": "Vicenza",
      "short_code": "VI"
    }, {
      "code": "IT-VR",
      "name": "Verona",
      "short_code": "VR"
    }, {
      "code": "IT-VE",
      "name": "Venezia",
      "short_code": "VE"
    }, {
      "code": "IT-36",
      "name": "Friuli-Venezia Giulia",
      "short_code": "36"
    }, {
      "code": "IT-42",
      "name": "Liguria",
      "short_code": "42"
    }, {
      "code": "IT-IM",
      "name": "Imperia",
      "short_code": "IM"
    }, {
      "code": "IT-SP",
      "name": "La Spezia",
      "short_code": "SP"
    }, {
      "code": "IT-SV",
      "name": "Savona",
      "short_code": "SV"
    }, {
      "code": "IT-GE",
      "name": "Genova",
      "short_code": "GE"
    }, {
      "code": "IT-45",
      "name": "Emilia-Romagna",
      "short_code": "45"
    }, {
      "code": "IT-FC",
      "name": "Forlì-Cesena",
      "short_code": "FC"
    }, {
      "code": "IT-FE",
      "name": "Ferrara",
      "short_code": "FE"
    }, {
      "code": "IT-MO",
      "name": "Modena",
      "short_code": "MO"
    }, {
      "code": "IT-PC",
      "name": "Piacenza",
      "short_code": "PC"
    }, {
      "code": "IT-PR",
      "name": "Parma",
      "short_code": "PR"
    }, {
      "code": "IT-RA",
      "name": "Ravenna",
      "short_code": "RA"
    }, {
      "code": "IT-RE",
      "name": "Reggio Emilia",
      "short_code": "RE"
    }, {
      "code": "IT-RN",
      "name": "Rimini",
      "short_code": "RN"
    }, {
      "code": "IT-BO",
      "name": "Bologna",
      "short_code": "BO"
    }, {
      "code": "IT-52",
      "name": "Toscana",
      "short_code": "52"
    }, {
      "code": "IT-AR",
      "name": "Arezzo",
      "short_code": "AR"
    }, {
      "code": "IT-GR",
      "name": "Grosseto",
      "short_code": "GR"
    }, {
      "code": "IT-LI",
      "name": "Livorno",
      "short_code": "LI"
    }, {
      "code": "IT-LU",
      "name": "Lucca",
      "short_code": "LU"
    }, {
      "code": "IT-MS",
      "name": "Massa-Carrara",
      "short_code": "MS"
    }, {
      "code": "IT-PI",
      "name": "Pisa",
      "short_code": "PI"
    }, {
      "code": "IT-PO",
      "name": "Prato",
      "short_code": "PO"
    }, {
      "code": "IT-PT",
      "name": "Pistoia",
      "short_code": "PT"
    }, {
      "code": "IT-SI",
      "name": "Siena",
      "short_code": "SI"
    }, {
      "code": "IT-FI",
      "name": "Firenze",
      "short_code": "FI"
    }, {
      "code": "IT-55",
      "name": "Umbria",
      "short_code": "55"
    }, {
      "code": "IT-PG",
      "name": "Perugia",
      "short_code": "PG"
    }, {
      "code": "IT-TR",
      "name": "Terni",
      "short_code": "TR"
    }, {
      "code": "IT-57",
      "name": "Marche",
      "short_code": "57"
    }, {
      "code": "IT-AN",
      "name": "Ancona",
      "short_code": "AN"
    }, {
      "code": "IT-AP",
      "name": "Ascoli Piceno",
      "short_code": "AP"
    }, {
      "code": "IT-FM",
      "name": "Fermo",
      "short_code": "FM"
    }, {
      "code": "IT-MC",
      "name": "Macerata",
      "short_code": "MC"
    }, {
      "code": "IT-PU",
      "name": "Pesaro e Urbino",
      "short_code": "PU"
    }, {
      "code": "IT-62",
      "name": "Lazio",
      "short_code": "62"
    }, {
      "code": "IT-FR",
      "name": "Frosinone",
      "short_code": "FR"
    }, {
      "code": "IT-LT",
      "name": "Latina",
      "short_code": "LT"
    }, {
      "code": "IT-RI",
      "name": "Rieti",
      "short_code": "RI"
    }, {
      "code": "IT-VT",
      "name": "Viterbo",
      "short_code": "VT"
    }, {
      "code": "IT-RM",
      "name": "Roma",
      "short_code": "RM"
    }, {
      "code": "IT-65",
      "name": "Abruzzo",
      "short_code": "65"
    }, {
      "code": "IT-AQ",
      "name": "L'Aquila",
      "short_code": "AQ"
    }, {
      "code": "IT-CH",
      "name": "Chieti",
      "short_code": "CH"
    }, {
      "code": "IT-PE",
      "name": "Pescara",
      "short_code": "PE"
    }, {
      "code": "IT-TE",
      "name": "Teramo",
      "short_code": "TE"
    }, {
      "code": "IT-67",
      "name": "Molise",
      "short_code": "67"
    }, {
      "code": "IT-CB",
      "name": "Campobasso",
      "short_code": "CB"
    }, {
      "code": "IT-IS",
      "name": "Isernia",
      "short_code": "IS"
    }, {
      "code": "IT-72",
      "name": "Campania",
      "short_code": "72"
    }, {
      "code": "IT-AV",
      "name": "Avellino",
      "short_code": "AV"
    }, {
      "code": "IT-BN",
      "name": "Benevento",
      "short_code": "BN"
    }, {
      "code": "IT-CE",
      "name": "Caserta",
      "short_code": "CE"
    }, {
      "code": "IT-SA",
      "name": "Salerno",
      "short_code": "SA"
    }, {
      "code": "IT-NA",
      "name": "Napoli",
      "short_code": "NA"
    }, {
      "code": "IT-75",
      "name": "Puglia",
      "short_code": "75"
    }, {
      "code": "IT-BR",
      "name": "Brindisi",
      "short_code": "BR"
    }, {
      "code": "IT-BT",
      "name": "Barletta-Andria-Trani",
      "short_code": "BT"
    }, {
      "code": "IT-FG",
      "name": "Foggia",
      "short_code": "FG"
    }, {
      "code": "IT-LE",
      "name": "Lecce",
      "short_code": "LE"
    }, {
      "code": "IT-TA",
      "name": "Taranto",
      "short_code": "TA"
    }, {
      "code": "IT-BA",
      "name": "Bari",
      "short_code": "BA"
    }, {
      "code": "IT-77",
      "name": "Basilicata",
      "short_code": "77"
    }, {
      "code": "IT-MT",
      "name": "Matera",
      "short_code": "MT"
    }, {
      "code": "IT-PZ",
      "name": "Potenza",
      "short_code": "PZ"
    }, {
      "code": "IT-78",
      "name": "Calabria",
      "short_code": "78"
    }, {
      "code": "IT-CS",
      "name": "Cosenza",
      "short_code": "CS"
    }, {
      "code": "IT-CZ",
      "name": "Catanzaro",
      "short_code": "CZ"
    }, {
      "code": "IT-KR",
      "name": "Crotone",
      "short_code": "KR"
    }, {
      "code": "IT-VV",
      "name": "Vibo Valentia",
      "short_code": "VV"
    }, {
      "code": "IT-RC",
      "name": "Reggio Calabria",
      "short_code": "RC"
    }, {
      "code": "IT-82",
      "name": "Sicilia",
      "short_code": "82"
    }, {
      "code": "IT-AG",
      "name": "Agrigento",
      "short_code": "AG"
    }, {
      "code": "IT-CL",
      "name": "Caltanissetta",
      "short_code": "CL"
    }, {
      "code": "IT-EN",
      "name": "Enna",
      "short_code": "EN"
    }, {
      "code": "IT-RG",
      "name": "Ragusa",
      "short_code": "RG"
    }, {
      "code": "IT-SR",
      "name": "Siracusa",
      "short_code": "SR"
    }, {
      "code": "IT-TP",
      "name": "Trapani",
      "short_code": "TP"
    }, {
      "code": "IT-CT",
      "name": "Catania",
      "short_code": "CT"
    }, {
      "code": "IT-ME",
      "name": "Messina",
      "short_code": "ME"
    }, {
      "code": "IT-PA",
      "name": "Palermo",
      "short_code": "PA"
    }, {
      "code": "IT-88",
      "name": "Sardegna",
      "short_code": "88"
    }, {
      "code": "IT-NU",
      "name": "Nuoro",
      "short_code": "NU"
    }, {
      "code": "IT-OR",
      "name": "Oristano",
      "short_code": "OR"
    }, {
      "code": "IT-SD",
      "name": "Sud Sardegna",
      "short_code": "SD"
    }, {
      "code": "IT-SS",
      "name": "Sassari",
      "short_code": "SS"
    }, {
      "code": "IT-CA",
      "name": "Cagliari",
      "short_code": "CA"
    }]
  }, {
    "code": "JPN",
    "name": "Japan",
    "subdivisions": [{
      "code": "JP-01",
      "name": "Hokkaido",
      "short_code": "01"
    }, {
      "code": "JP-02",
      "name": "Aomori",
      "short_code": "02"
    }, {
      "code": "JP-03",
      "name": "Iwate",
      "short_code": "03"
    }, {
      "code": "JP-04",
      "name": "Miyagi",
      "short_code": "04"
    }, {
      "code": "JP-05",
      "name": "Akita",
      "short_code": "05"
    }, {
      "code": "JP-06",
      "name": "Yamagata",
      "short_code": "06"
    }, {
      "code": "JP-07",
      "name": "Fukushima",
      "short_code": "07"
    }, {
      "code": "JP-08",
      "name": "Ibaraki",
      "short_code": "08"
    }, {
      "code": "JP-09",
      "name": "Tochigi",
      "short_code": "09"
    }, {
      "code": "JP-10",
      "name": "Gunma",
      "short_code": "10"
    }, {
      "code": "JP-11",
      "name": "Saitama",
      "short_code": "11"
    }, {
      "code": "JP-12",
      "name": "Chiba",
      "short_code": "12"
    }, {
      "code": "JP-13",
      "name": "Tokyo",
      "short_code": "13"
    }, {
      "code": "JP-14",
      "name": "Kanagawa",
      "short_code": "14"
    }, {
      "code": "JP-15",
      "name": "Niigata",
      "short_code": "15"
    }, {
      "code": "JP-16",
      "name": "Toyama",
      "short_code": "16"
    }, {
      "code": "JP-17",
      "name": "Ishikawa",
      "short_code": "17"
    }, {
      "code": "JP-18",
      "name": "Fukui",
      "short_code": "18"
    }, {
      "code": "JP-19",
      "name": "Yamanashi",
      "short_code": "19"
    }, {
      "code": "JP-20",
      "name": "Nagano",
      "short_code": "20"
    }, {
      "code": "JP-21",
      "name": "Gifu",
      "short_code": "21"
    }, {
      "code": "JP-22",
      "name": "Shizuoka",
      "short_code": "22"
    }, {
      "code": "JP-23",
      "name": "Aichi",
      "short_code": "23"
    }, {
      "code": "JP-24",
      "name": "Mie",
      "short_code": "24"
    }, {
      "code": "JP-25",
      "name": "Shiga",
      "short_code": "25"
    }, {
      "code": "JP-26",
      "name": "Kyoto",
      "short_code": "26"
    }, {
      "code": "JP-27",
      "name": "Osaka",
      "short_code": "27"
    }, {
      "code": "JP-28",
      "name": "Hyogo",
      "short_code": "28"
    }, {
      "code": "JP-29",
      "name": "Nara",
      "short_code": "29"
    }, {
      "code": "JP-30",
      "name": "Wakayama",
      "short_code": "30"
    }, {
      "code": "JP-31",
      "name": "Tottori",
      "short_code": "31"
    }, {
      "code": "JP-32",
      "name": "Shimane",
      "short_code": "32"
    }, {
      "code": "JP-33",
      "name": "Okayama",
      "short_code": "33"
    }, {
      "code": "JP-34",
      "name": "Hiroshima",
      "short_code": "34"
    }, {
      "code": "JP-35",
      "name": "Yamaguchi",
      "short_code": "35"
    }, {
      "code": "JP-36",
      "name": "Tokushima",
      "short_code": "36"
    }, {
      "code": "JP-37",
      "name": "Kagawa",
      "short_code": "37"
    }, {
      "code": "JP-38",
      "name": "Ehime",
      "short_code": "38"
    }, {
      "code": "JP-39",
      "name": "Kochi",
      "short_code": "39"
    }, {
      "code": "JP-40",
      "name": "Fukuoka",
      "short_code": "40"
    }, {
      "code": "JP-41",
      "name": "Saga",
      "short_code": "41"
    }, {
      "code": "JP-42",
      "name": "Nagasaki",
      "short_code": "42"
    }, {
      "code": "JP-43",
      "name": "Kumamoto",
      "short_code": "43"
    }, {
      "code": "JP-44",
      "name": "Oita",
      "short_code": "44"
    }, {
      "code": "JP-45",
      "name": "Miyazaki",
      "short_code": "45"
    }, {
      "code": "JP-46",
      "name": "Kagoshima",
      "short_code": "46"
    }, {
      "code": "JP-47",
      "name": "Okinawa",
      "short_code": "47"
    }]
  }, {
    "code": "KOR",
    "name": "Korea, Republic of",
    "subdivisions": [{
      "code": "KR-11",
      "name": "Seoul",
      "short_code": "11"
    }, {
      "code": "KR-26",
      "name": "Busan",
      "short_code": "26"
    }, {
      "code": "KR-27",
      "name": "Daegu",
      "short_code": "27"
    }, {
      "code": "KR-28",
      "name": "Incheon",
      "short_code": "28"
    }, {
      "code": "KR-29",
      "name": "Gwangju",
      "short_code": "29"
    }, {
      "code": "KR-30",
      "name": "Daejeon",
      "short_code": "30"
    }, {
      "code": "KR-31",
      "name": "Ulsan",
      "short_code": "31"
    }, {
      "code": "KR-41",
      "name": "Gyeonggi",
      "short_code": "41"
    }, {
      "code": "KR-42",
      "name": "Gangwon",
      "short_code": "42"
    }, {
      "code": "KR-43",
      "name": "Chungbuk",
      "short_code": "43"
    }, {
      "code": "KR-44",
      "name": "Chungnam",
      "short_code": "44"
    }, {
      "code": "KR-45",
      "name": "Jeonbuk",
      "short_code": "45"
    }, {
      "code": "KR-46",
      "name": "Jeonnam",
      "short_code": "46"
    }, {
      "code": "KR-47",
      "name": "Gyeongbuk",
      "short_code": "47"
    }, {
      "code": "KR-48",
      "name": "Gyeongnam",
      "short_code": "48"
    }, {
      "code": "KR-49",
      "name": "Jeju",
      "short_code": "49"
    }, {
      "code": "KR-50",
      "name": "Sejong",
      "short_code": "50"
    }]
  }, {
    "code": "LVA",
    "name": "Latvia",
    "subdivisions": [{
      "code": "LV-001",
      "name": "Aglona",
      "short_code": "001"
    }, {
      "code": "LV-002",
      "name": "Aizkraukle",
      "short_code": "002"
    }, {
      "code": "LV-003",
      "name": "Aizpute",
      "short_code": "003"
    }, {
      "code": "LV-004",
      "name": "Aknīste",
      "short_code": "004"
    }, {
      "code": "LV-005",
      "name": "Aloja",
      "short_code": "005"
    }, {
      "code": "LV-006",
      "name": "Alsunga",
      "short_code": "006"
    }, {
      "code": "LV-007",
      "name": "Alūksne",
      "short_code": "007"
    }, {
      "code": "LV-008",
      "name": "Amata",
      "short_code": "008"
    }, {
      "code": "LV-009",
      "name": "Ape",
      "short_code": "009"
    }, {
      "code": "LV-010",
      "name": "Auce",
      "short_code": "010"
    }, {
      "code": "LV-011",
      "name": "Ādaži",
      "short_code": "011"
    }, {
      "code": "LV-012",
      "name": "Babīte",
      "short_code": "012"
    }, {
      "code": "LV-013",
      "name": "Baldone",
      "short_code": "013"
    }, {
      "code": "LV-014",
      "name": "Baltinava",
      "short_code": "014"
    }, {
      "code": "LV-015",
      "name": "Balvi",
      "short_code": "015"
    }, {
      "code": "LV-016",
      "name": "Bauska",
      "short_code": "016"
    }, {
      "code": "LV-017",
      "name": "Beverīna",
      "short_code": "017"
    }, {
      "code": "LV-018",
      "name": "Brocēni",
      "short_code": "018"
    }, {
      "code": "LV-019",
      "name": "Burtnieki",
      "short_code": "019"
    }, {
      "code": "LV-020",
      "name": "Carnikava",
      "short_code": "020"
    }, {
      "code": "LV-021",
      "name": "Cesvaine",
      "short_code": "021"
    }, {
      "code": "LV-022",
      "name": "Cēsis",
      "short_code": "022"
    }, {
      "code": "LV-023",
      "name": "Cibla",
      "short_code": "023"
    }, {
      "code": "LV-024",
      "name": "Dagda",
      "short_code": "024"
    }, {
      "code": "LV-025",
      "name": "Daugavpils",
      "short_code": "025"
    }, {
      "code": "LV-026",
      "name": "Dobele",
      "short_code": "026"
    }, {
      "code": "LV-027",
      "name": "Dundaga",
      "short_code": "027"
    }, {
      "code": "LV-028",
      "name": "Durbe",
      "short_code": "028"
    }, {
      "code": "LV-029",
      "name": "Engure",
      "short_code": "029"
    }, {
      "code": "LV-030",
      "name": "Ērgļi",
      "short_code": "030"
    }, {
      "code": "LV-031",
      "name": "Garkalne",
      "short_code": "031"
    }, {
      "code": "LV-032",
      "name": "Grobiņa",
      "short_code": "032"
    }, {
      "code": "LV-033",
      "name": "Gulbene",
      "short_code": "033"
    }, {
      "code": "LV-034",
      "name": "Iecava",
      "short_code": "034"
    }, {
      "code": "LV-035",
      "name": "Ikšķile",
      "short_code": "035"
    }, {
      "code": "LV-036",
      "name": "Ilūkste",
      "short_code": "036"
    }, {
      "code": "LV-037",
      "name": "Inčukalns",
      "short_code": "037"
    }, {
      "code": "LV-038",
      "name": "Jaunjelgava",
      "short_code": "038"
    }, {
      "code": "LV-039",
      "name": "Jaunpiebalga",
      "short_code": "039"
    }, {
      "code": "LV-040",
      "name": "Jaunpils",
      "short_code": "040"
    }, {
      "code": "LV-041",
      "name": "Jelgava",
      "short_code": "041"
    }, {
      "code": "LV-042",
      "name": "Jēkabpils",
      "short_code": "042"
    }, {
      "code": "LV-043",
      "name": "Kandava",
      "short_code": "043"
    }, {
      "code": "LV-044",
      "name": "Kārsava",
      "short_code": "044"
    }, {
      "code": "LV-045",
      "name": "Kocēni",
      "short_code": "045"
    }, {
      "code": "LV-046",
      "name": "Koknese",
      "short_code": "046"
    }, {
      "code": "LV-047",
      "name": "Krāslava",
      "short_code": "047"
    }, {
      "code": "LV-048",
      "name": "Krimulda",
      "short_code": "048"
    }, {
      "code": "LV-049",
      "name": "Krustpils",
      "short_code": "049"
    }, {
      "code": "LV-050",
      "name": "Kuldīga",
      "short_code": "050"
    }, {
      "code": "LV-051",
      "name": "Ķegums",
      "short_code": "051"
    }, {
      "code": "LV-052",
      "name": "Ķekava",
      "short_code": "052"
    }, {
      "code": "LV-053",
      "name": "Lielvārde",
      "short_code": "053"
    }, {
      "code": "LV-054",
      "name": "Limbaži",
      "short_code": "054"
    }, {
      "code": "LV-055",
      "name": "Līgatne",
      "short_code": "055"
    }, {
      "code": "LV-056",
      "name": "Līvāni",
      "short_code": "056"
    }, {
      "code": "LV-057",
      "name": "Lubāna",
      "short_code": "057"
    }, {
      "code": "LV-058",
      "name": "Ludza",
      "short_code": "058"
    }, {
      "code": "LV-059",
      "name": "Madona",
      "short_code": "059"
    }, {
      "code": "LV-060",
      "name": "Mazsalaca",
      "short_code": "060"
    }, {
      "code": "LV-061",
      "name": "Mālpils",
      "short_code": "061"
    }, {
      "code": "LV-062",
      "name": "Mārupe",
      "short_code": "062"
    }, {
      "code": "LV-063",
      "name": "Mērsrags",
      "short_code": "063"
    }, {
      "code": "LV-064",
      "name": "Naukšēni",
      "short_code": "064"
    }, {
      "code": "LV-065",
      "name": "Nereta",
      "short_code": "065"
    }, {
      "code": "LV-066",
      "name": "Nīca",
      "short_code": "066"
    }, {
      "code": "LV-067",
      "name": "Ogre",
      "short_code": "067"
    }, {
      "code": "LV-068",
      "name": "Olaine",
      "short_code": "068"
    }, {
      "code": "LV-069",
      "name": "Ozolnieki",
      "short_code": "069"
    }, {
      "code": "LV-070",
      "name": "Pārgauja",
      "short_code": "070"
    }, {
      "code": "LV-071",
      "name": "Pāvilosta",
      "short_code": "071"
    }, {
      "code": "LV-072",
      "name": "Pļaviņas",
      "short_code": "072"
    }, {
      "code": "LV-073",
      "name": "Preiļi",
      "short_code": "073"
    }, {
      "code": "LV-074",
      "name": "Priekule",
      "short_code": "074"
    }, {
      "code": "LV-075",
      "name": "Priekuļi",
      "short_code": "075"
    }, {
      "code": "LV-076",
      "name": "Rauna",
      "short_code": "076"
    }, {
      "code": "LV-077",
      "name": "Rēzekne",
      "short_code": "077"
    }, {
      "code": "LV-078",
      "name": "Riebiņi",
      "short_code": "078"
    }, {
      "code": "LV-079",
      "name": "Roja",
      "short_code": "079"
    }, {
      "code": "LV-080",
      "name": "Ropaži",
      "short_code": "080"
    }, {
      "code": "LV-081",
      "name": "Rucava",
      "short_code": "081"
    }, {
      "code": "LV-082",
      "name": "Rugāji",
      "short_code": "082"
    }, {
      "code": "LV-083",
      "name": "Rundāle",
      "short_code": "083"
    }, {
      "code": "LV-084",
      "name": "Rūjiena",
      "short_code": "084"
    }, {
      "code": "LV-085",
      "name": "Sala",
      "short_code": "085"
    }, {
      "code": "LV-086",
      "name": "Salacgrīva",
      "short_code": "086"
    }, {
      "code": "LV-087",
      "name": "Salaspils",
      "short_code": "087"
    }, {
      "code": "LV-088",
      "name": "Saldus",
      "short_code": "088"
    }, {
      "code": "LV-089",
      "name": "Saulkrasti",
      "short_code": "089"
    }, {
      "code": "LV-090",
      "name": "Sēja",
      "short_code": "090"
    }, {
      "code": "LV-091",
      "name": "Sigulda",
      "short_code": "091"
    }, {
      "code": "LV-092",
      "name": "Skrīveri",
      "short_code": "092"
    }, {
      "code": "LV-093",
      "name": "Skrunda",
      "short_code": "093"
    }, {
      "code": "LV-094",
      "name": "Smiltene",
      "short_code": "094"
    }, {
      "code": "LV-095",
      "name": "Stopiņi",
      "short_code": "095"
    }, {
      "code": "LV-096",
      "name": "Strenči",
      "short_code": "096"
    }, {
      "code": "LV-097",
      "name": "Talsi",
      "short_code": "097"
    }, {
      "code": "LV-098",
      "name": "Tērvete",
      "short_code": "098"
    }, {
      "code": "LV-099",
      "name": "Tukums",
      "short_code": "099"
    }, {
      "code": "LV-100",
      "name": "Vaiņode",
      "short_code": "100"
    }, {
      "code": "LV-101",
      "name": "Valka",
      "short_code": "101"
    }, {
      "code": "LV-102",
      "name": "Varakļāni",
      "short_code": "102"
    }, {
      "code": "LV-103",
      "name": "Vārkava",
      "short_code": "103"
    }, {
      "code": "LV-104",
      "name": "Vecpiebalga",
      "short_code": "104"
    }, {
      "code": "LV-105",
      "name": "Vecumnieki",
      "short_code": "105"
    }, {
      "code": "LV-106",
      "name": "Ventspils",
      "short_code": "106"
    }, {
      "code": "LV-107",
      "name": "Viesīte",
      "short_code": "107"
    }, {
      "code": "LV-108",
      "name": "Viļaka",
      "short_code": "108"
    }, {
      "code": "LV-109",
      "name": "Viļāni",
      "short_code": "109"
    }, {
      "code": "LV-110",
      "name": "Zilupe",
      "short_code": "110"
    }, {
      "code": "LV-DGV",
      "name": "Daugavpils",
      "short_code": "DGV"
    }, {
      "code": "LV-JEL",
      "name": "Jelgava",
      "short_code": "JEL"
    }, {
      "code": "LV-JKB",
      "name": "Jēkabpils",
      "short_code": "JKB"
    }, {
      "code": "LV-JUR",
      "name": "Jūrmala",
      "short_code": "JUR"
    }, {
      "code": "LV-LPX",
      "name": "Liepāja",
      "short_code": "LPX"
    }, {
      "code": "LV-REZ",
      "name": "Rēzekne",
      "short_code": "REZ"
    }, {
      "code": "LV-RIX",
      "name": "Rīga",
      "short_code": "RIX"
    }, {
      "code": "LV-VEN",
      "name": "Ventspils",
      "short_code": "VEN"
    }, {
      "code": "LV-VMR",
      "name": "Valmiera",
      "short_code": "VMR"
    }]
  }, {
    "code": "LIE",
    "name": "Liechtenstein",
    "subdivisions": [{
      "code": "LI-01",
      "name": "Balzers",
      "short_code": "01"
    }, {
      "code": "LI-02",
      "name": "Eschen",
      "short_code": "02"
    }, {
      "code": "LI-03",
      "name": "Gamprin",
      "short_code": "03"
    }, {
      "code": "LI-04",
      "name": "Mauren",
      "short_code": "04"
    }, {
      "code": "LI-05",
      "name": "Planken",
      "short_code": "05"
    }, {
      "code": "LI-06",
      "name": "Ruggell",
      "short_code": "06"
    }, {
      "code": "LI-07",
      "name": "Schaan",
      "short_code": "07"
    }, {
      "code": "LI-08",
      "name": "Schellenberg",
      "short_code": "08"
    }, {
      "code": "LI-09",
      "name": "Triesen",
      "short_code": "09"
    }, {
      "code": "LI-10",
      "name": "Triesenberg",
      "short_code": "10"
    }, {
      "code": "LI-11",
      "name": "Vaduz",
      "short_code": "11"
    }]
  }, {
    "code": "LTU",
    "name": "Lithuania",
    "subdivisions": [{
      "code": "LT-AL",
      "name": "Alytaus apskritis",
      "short_code": "AL"
    }, {
      "code": "LT-KL",
      "name": "Klaipėdos apskritis",
      "short_code": "KL"
    }, {
      "code": "LT-KU",
      "name": "Kauno apskritis",
      "short_code": "KU"
    }, {
      "code": "LT-MR",
      "name": "Marijampolės apskritis",
      "short_code": "MR"
    }, {
      "code": "LT-PN",
      "name": "Panevėžio apskritis",
      "short_code": "PN"
    }, {
      "code": "LT-SA",
      "name": "Šiaulių apskritis",
      "short_code": "SA"
    }, {
      "code": "LT-TA",
      "name": "Tauragės apskritis",
      "short_code": "TA"
    }, {
      "code": "LT-TE",
      "name": "Telšių apskritis",
      "short_code": "TE"
    }, {
      "code": "LT-UT",
      "name": "Utenos apskritis",
      "short_code": "UT"
    }, {
      "code": "LT-VL",
      "name": "Vilniaus apskritis",
      "short_code": "VL"
    }, {
      "code": "LT-02",
      "name": "Alytaus miestas",
      "short_code": "02"
    }, {
      "code": "LT-15",
      "name": "Kauno miestas",
      "short_code": "15"
    }, {
      "code": "LT-20",
      "name": "Klaipėdos miestas",
      "short_code": "20"
    }, {
      "code": "LT-31",
      "name": "Palangos miestas",
      "short_code": "31"
    }, {
      "code": "LT-32",
      "name": "Panevėžio miestas",
      "short_code": "32"
    }, {
      "code": "LT-43",
      "name": "Šiaulių miestas",
      "short_code": "43"
    }, {
      "code": "LT-57",
      "name": "Vilniaus miestas",
      "short_code": "57"
    }, {
      "code": "LT-01",
      "name": "Akmenė",
      "short_code": "01"
    }, {
      "code": "LT-03",
      "name": "Alytus",
      "short_code": "03"
    }, {
      "code": "LT-04",
      "name": "Anykščiai",
      "short_code": "04"
    }, {
      "code": "LT-06",
      "name": "Biržai",
      "short_code": "06"
    }, {
      "code": "LT-09",
      "name": "Ignalina",
      "short_code": "09"
    }, {
      "code": "LT-10",
      "name": "Jonava",
      "short_code": "10"
    }, {
      "code": "LT-11",
      "name": "Joniškis",
      "short_code": "11"
    }, {
      "code": "LT-12",
      "name": "Jurbarkas",
      "short_code": "12"
    }, {
      "code": "LT-13",
      "name": "Kaišiadorys",
      "short_code": "13"
    }, {
      "code": "LT-16",
      "name": "Kaunas",
      "short_code": "16"
    }, {
      "code": "LT-18",
      "name": "Kėdainiai",
      "short_code": "18"
    }, {
      "code": "LT-19",
      "name": "Kelmė",
      "short_code": "19"
    }, {
      "code": "LT-21",
      "name": "Klaipėda",
      "short_code": "21"
    }, {
      "code": "LT-22",
      "name": "Kretinga",
      "short_code": "22"
    }, {
      "code": "LT-23",
      "name": "Kupiškis",
      "short_code": "23"
    }, {
      "code": "LT-24",
      "name": "Lazdijai",
      "short_code": "24"
    }, {
      "code": "LT-25",
      "name": "Marijampolė",
      "short_code": "25"
    }, {
      "code": "LT-26",
      "name": "Mažeikiai",
      "short_code": "26"
    }, {
      "code": "LT-27",
      "name": "Molėtai",
      "short_code": "27"
    }, {
      "code": "LT-30",
      "name": "Pakruojis",
      "short_code": "30"
    }, {
      "code": "LT-33",
      "name": "Panevėžys",
      "short_code": "33"
    }, {
      "code": "LT-34",
      "name": "Pasvalys",
      "short_code": "34"
    }, {
      "code": "LT-35",
      "name": "Plungė",
      "short_code": "35"
    }, {
      "code": "LT-36",
      "name": "Prienai",
      "short_code": "36"
    }, {
      "code": "LT-37",
      "name": "Radviliškis",
      "short_code": "37"
    }, {
      "code": "LT-38",
      "name": "Raseiniai",
      "short_code": "38"
    }, {
      "code": "LT-40",
      "name": "Rokiškis",
      "short_code": "40"
    }, {
      "code": "LT-41",
      "name": "Šakiai",
      "short_code": "41"
    }, {
      "code": "LT-42",
      "name": "Šalčininkai",
      "short_code": "42"
    }, {
      "code": "LT-44",
      "name": "Šiauliai",
      "short_code": "44"
    }, {
      "code": "LT-45",
      "name": "Šilalė",
      "short_code": "45"
    }, {
      "code": "LT-46",
      "name": "Šilutė",
      "short_code": "46"
    }, {
      "code": "LT-47",
      "name": "Širvintos",
      "short_code": "47"
    }, {
      "code": "LT-48",
      "name": "Skuodas",
      "short_code": "48"
    }, {
      "code": "LT-49",
      "name": "Švenčionys",
      "short_code": "49"
    }, {
      "code": "LT-50",
      "name": "Tauragė",
      "short_code": "50"
    }, {
      "code": "LT-51",
      "name": "Telšiai",
      "short_code": "51"
    }, {
      "code": "LT-52",
      "name": "Trakai",
      "short_code": "52"
    }, {
      "code": "LT-53",
      "name": "Ukmergė",
      "short_code": "53"
    }, {
      "code": "LT-54",
      "name": "Utena",
      "short_code": "54"
    }, {
      "code": "LT-55",
      "name": "Varėna",
      "short_code": "55"
    }, {
      "code": "LT-56",
      "name": "Vilkaviškis",
      "short_code": "56"
    }, {
      "code": "LT-58",
      "name": "Vilnius",
      "short_code": "58"
    }, {
      "code": "LT-60",
      "name": "Zarasai",
      "short_code": "60"
    }, {
      "code": "LT-05",
      "name": "Birštono",
      "short_code": "05"
    }, {
      "code": "LT-07",
      "name": "Druskininkai",
      "short_code": "07"
    }, {
      "code": "LT-08",
      "name": "Elektrėnai",
      "short_code": "08"
    }, {
      "code": "LT-14",
      "name": "Kalvarijos",
      "short_code": "14"
    }, {
      "code": "LT-17",
      "name": "Kazlų Rūdos",
      "short_code": "17"
    }, {
      "code": "LT-28",
      "name": "Neringa",
      "short_code": "28"
    }, {
      "code": "LT-29",
      "name": "Pagėgiai",
      "short_code": "29"
    }, {
      "code": "LT-39",
      "name": "Rietavo",
      "short_code": "39"
    }, {
      "code": "LT-59",
      "name": "Visaginas",
      "short_code": "59"
    }]
  }, {
    "code": "LUX",
    "name": "Luxembourg",
    "subdivisions": [{
      "code": "LU-CA",
      "name": "Capellen",
      "short_code": "CA"
    }, {
      "code": "LU-CL",
      "name": "Clerf",
      "short_code": "CL"
    }, {
      "code": "LU-DI",
      "name": "Diekirch",
      "short_code": "DI"
    }, {
      "code": "LU-EC",
      "name": "Echternach",
      "short_code": "EC"
    }, {
      "code": "LU-ES",
      "name": "Esch an der Alzette",
      "short_code": "ES"
    }, {
      "code": "LU-GR",
      "name": "Grevenmacher",
      "short_code": "GR"
    }, {
      "code": "LU-LU",
      "name": "Luxemburg",
      "short_code": "LU"
    }, {
      "code": "LU-ME",
      "name": "Mersch",
      "short_code": "ME"
    }, {
      "code": "LU-RD",
      "name": "Redingen",
      "short_code": "RD"
    }, {
      "code": "LU-RM",
      "name": "Remich",
      "short_code": "RM"
    }, {
      "code": "LU-VD",
      "name": "Vianden",
      "short_code": "VD"
    }, {
      "code": "LU-WI",
      "name": "Wiltz",
      "short_code": "WI"
    }]
  }, {
    "code": "MYS",
    "name": "Malaysia",
    "subdivisions": [{
      "code": "MY-14",
      "name": "Wilayah Persekutuan Kuala Lumpur",
      "short_code": "14"
    }, {
      "code": "MY-15",
      "name": "Wilayah Persekutuan Labuan",
      "short_code": "15"
    }, {
      "code": "MY-16",
      "name": "Wilayah Persekutuan Putrajaya",
      "short_code": "16"
    }, {
      "code": "MY-01",
      "name": "Johor",
      "short_code": "01"
    }, {
      "code": "MY-02",
      "name": "Kedah",
      "short_code": "02"
    }, {
      "code": "MY-03",
      "name": "Kelantan",
      "short_code": "03"
    }, {
      "code": "MY-04",
      "name": "Melaka",
      "short_code": "04"
    }, {
      "code": "MY-05",
      "name": "Negeri Sembilan",
      "short_code": "05"
    }, {
      "code": "MY-06",
      "name": "Pahang",
      "short_code": "06"
    }, {
      "code": "MY-07",
      "name": "Pulau Pinang",
      "short_code": "07"
    }, {
      "code": "MY-08",
      "name": "Perak",
      "short_code": "08"
    }, {
      "code": "MY-09",
      "name": "Perlis",
      "short_code": "09"
    }, {
      "code": "MY-10",
      "name": "Selangor",
      "short_code": "10"
    }, {
      "code": "MY-11",
      "name": "Terengganu",
      "short_code": "11"
    }, {
      "code": "MY-12",
      "name": "Sabah",
      "short_code": "12"
    }, {
      "code": "MY-13",
      "name": "Sarawak",
      "short_code": "13"
    }]
  }, {
    "code": "MLT",
    "name": "Malta",
    "subdivisions": [{
      "code": "MT-01",
      "name": "Attard",
      "short_code": "01"
    }, {
      "code": "MT-02",
      "name": "Balzan",
      "short_code": "02"
    }, {
      "code": "MT-03",
      "name": "Birgu",
      "short_code": "03"
    }, {
      "code": "MT-04",
      "name": "Birkirkara",
      "short_code": "04"
    }, {
      "code": "MT-05",
      "name": "Birżebbuġa",
      "short_code": "05"
    }, {
      "code": "MT-06",
      "name": "Bormla",
      "short_code": "06"
    }, {
      "code": "MT-07",
      "name": "Dingli",
      "short_code": "07"
    }, {
      "code": "MT-08",
      "name": "Fgura",
      "short_code": "08"
    }, {
      "code": "MT-09",
      "name": "Floriana",
      "short_code": "09"
    }, {
      "code": "MT-10",
      "name": "Fontana",
      "short_code": "10"
    }, {
      "code": "MT-11",
      "name": "Gudja",
      "short_code": "11"
    }, {
      "code": "MT-12",
      "name": "Gżira",
      "short_code": "12"
    }, {
      "code": "MT-13",
      "name": "Għajnsielem",
      "short_code": "13"
    }, {
      "code": "MT-14",
      "name": "Għarb",
      "short_code": "14"
    }, {
      "code": "MT-15",
      "name": "Għargħur",
      "short_code": "15"
    }, {
      "code": "MT-16",
      "name": "Għasri",
      "short_code": "16"
    }, {
      "code": "MT-17",
      "name": "Għaxaq",
      "short_code": "17"
    }, {
      "code": "MT-18",
      "name": "Ħamrun",
      "short_code": "18"
    }, {
      "code": "MT-19",
      "name": "Iklin",
      "short_code": "19"
    }, {
      "code": "MT-20",
      "name": "Isla",
      "short_code": "20"
    }, {
      "code": "MT-21",
      "name": "Kalkara",
      "short_code": "21"
    }, {
      "code": "MT-22",
      "name": "Kerċem",
      "short_code": "22"
    }, {
      "code": "MT-23",
      "name": "Kirkop",
      "short_code": "23"
    }, {
      "code": "MT-24",
      "name": "Lija",
      "short_code": "24"
    }, {
      "code": "MT-25",
      "name": "Luqa",
      "short_code": "25"
    }, {
      "code": "MT-26",
      "name": "Marsa",
      "short_code": "26"
    }, {
      "code": "MT-27",
      "name": "Marsaskala",
      "short_code": "27"
    }, {
      "code": "MT-28",
      "name": "Marsaxlokk",
      "short_code": "28"
    }, {
      "code": "MT-29",
      "name": "Mdina",
      "short_code": "29"
    }, {
      "code": "MT-30",
      "name": "Mellieħa",
      "short_code": "30"
    }, {
      "code": "MT-31",
      "name": "Mġarr",
      "short_code": "31"
    }, {
      "code": "MT-32",
      "name": "Mosta",
      "short_code": "32"
    }, {
      "code": "MT-33",
      "name": "Mqabba",
      "short_code": "33"
    }, {
      "code": "MT-34",
      "name": "Msida",
      "short_code": "34"
    }, {
      "code": "MT-35",
      "name": "Mtarfa",
      "short_code": "35"
    }, {
      "code": "MT-36",
      "name": "Munxar",
      "short_code": "36"
    }, {
      "code": "MT-37",
      "name": "Nadur",
      "short_code": "37"
    }, {
      "code": "MT-38",
      "name": "Naxxar",
      "short_code": "38"
    }, {
      "code": "MT-39",
      "name": "Paola",
      "short_code": "39"
    }, {
      "code": "MT-40",
      "name": "Pembroke",
      "short_code": "40"
    }, {
      "code": "MT-41",
      "name": "Pietà",
      "short_code": "41"
    }, {
      "code": "MT-42",
      "name": "Qala",
      "short_code": "42"
    }, {
      "code": "MT-43",
      "name": "Qormi",
      "short_code": "43"
    }, {
      "code": "MT-44",
      "name": "Qrendi",
      "short_code": "44"
    }, {
      "code": "MT-45",
      "name": "Rabat Gozo",
      "short_code": "45"
    }, {
      "code": "MT-46",
      "name": "Rabat Malta",
      "short_code": "46"
    }, {
      "code": "MT-47",
      "name": "Safi",
      "short_code": "47"
    }, {
      "code": "MT-48",
      "name": "Saint Julian's",
      "short_code": "48"
    }, {
      "code": "MT-49",
      "name": "Saint John",
      "short_code": "49"
    }, {
      "code": "MT-50",
      "name": "Saint Lawrence",
      "short_code": "50"
    }, {
      "code": "MT-51",
      "name": "Saint Paul's Bay",
      "short_code": "51"
    }, {
      "code": "MT-52",
      "name": "Sannat",
      "short_code": "52"
    }, {
      "code": "MT-53",
      "name": "Saint Lucia's",
      "short_code": "53"
    }, {
      "code": "MT-54",
      "name": "Santa Venera",
      "short_code": "54"
    }, {
      "code": "MT-55",
      "name": "Siġġiewi",
      "short_code": "55"
    }, {
      "code": "MT-56",
      "name": "Sliema",
      "short_code": "56"
    }, {
      "code": "MT-57",
      "name": "Swieqi",
      "short_code": "57"
    }, {
      "code": "MT-58",
      "name": "Ta' Xbiex",
      "short_code": "58"
    }, {
      "code": "MT-59",
      "name": "Tarxien",
      "short_code": "59"
    }, {
      "code": "MT-60",
      "name": "Valletta",
      "short_code": "60"
    }, {
      "code": "MT-61",
      "name": "Xagħra",
      "short_code": "61"
    }, {
      "code": "MT-62",
      "name": "Xewkija",
      "short_code": "62"
    }, {
      "code": "MT-63",
      "name": "Xgħajra",
      "short_code": "63"
    }, {
      "code": "MT-64",
      "name": "Żabbar",
      "short_code": "64"
    }, {
      "code": "MT-65",
      "name": "Żebbuġ Gozo",
      "short_code": "65"
    }, {
      "code": "MT-66",
      "name": "Żebbuġ Malta",
      "short_code": "66"
    }, {
      "code": "MT-67",
      "name": "Żejtun",
      "short_code": "67"
    }, {
      "code": "MT-68",
      "name": "Żurrieq",
      "short_code": "68"
    }]
  }, {
    "code": "MCO",
    "name": "Monaco",
    "subdivisions": [{
      "code": "MC-CL",
      "name": "La Colle",
      "short_code": "CL"
    }, {
      "code": "MC-CO",
      "name": "La Condamine",
      "short_code": "CO"
    }, {
      "code": "MC-FO",
      "name": "Fontvieille",
      "short_code": "FO"
    }, {
      "code": "MC-GA",
      "name": "La Gare",
      "short_code": "GA"
    }, {
      "code": "MC-JE",
      "name": "Jardin Exotique",
      "short_code": "JE"
    }, {
      "code": "MC-LA",
      "name": "Larvotto",
      "short_code": "LA"
    }, {
      "code": "MC-MA",
      "name": "Malbousquet",
      "short_code": "MA"
    }, {
      "code": "MC-MC",
      "name": "Monte-Carlo",
      "short_code": "MC"
    }, {
      "code": "MC-MG",
      "name": "Moneghetti",
      "short_code": "MG"
    }, {
      "code": "MC-MO",
      "name": "Monaco-Ville",
      "short_code": "MO"
    }, {
      "code": "MC-MU",
      "name": "Moulins",
      "short_code": "MU"
    }, {
      "code": "MC-PH",
      "name": "Port-Hercule",
      "short_code": "PH"
    }, {
      "code": "MC-SD",
      "name": "Sainte-Dévote",
      "short_code": "SD"
    }, {
      "code": "MC-SO",
      "name": "La Source",
      "short_code": "SO"
    }, {
      "code": "MC-SP",
      "name": "Spélugues",
      "short_code": "SP"
    }, {
      "code": "MC-SR",
      "name": "Saint-Roman",
      "short_code": "SR"
    }, {
      "code": "MC-VR",
      "name": "Vallon de la Rousse",
      "short_code": "VR"
    }]
  }, {
    "code": "MAR",
    "name": "Morocco",
    "subdivisions": [{
      "code": "MA-01",
      "name": "Tanger-Tétouan-Al Hoceïma",
      "short_code": "01"
    }, {
      "code": "MA-MDF",
      "name": "M’diq-Fnideq",
      "short_code": "MDF"
    }, {
      "code": "MA-TNG",
      "name": "Tanger-Assilah",
      "short_code": "TNG"
    }, {
      "code": "MA-CHE",
      "name": "Chefchaouen",
      "short_code": "CHE"
    }, {
      "code": "MA-FAH",
      "name": "Fahs-Anjra",
      "short_code": "FAH"
    }, {
      "code": "MA-HOC",
      "name": "Al Hoceïma",
      "short_code": "HOC"
    }, {
      "code": "MA-LAR",
      "name": "Larache",
      "short_code": "LAR"
    }, {
      "code": "MA-OUZ",
      "name": "Ouezzane",
      "short_code": "OUZ"
    }, {
      "code": "MA-TET",
      "name": "Tétouan",
      "short_code": "TET"
    }, {
      "code": "MA-02",
      "name": "L'Oriental",
      "short_code": "02"
    }, {
      "code": "MA-OUJ",
      "name": "Oujda-Angad",
      "short_code": "OUJ"
    }, {
      "code": "MA-BER",
      "name": "Berkane",
      "short_code": "BER"
    }, {
      "code": "MA-DRI",
      "name": "Driouch",
      "short_code": "DRI"
    }, {
      "code": "MA-FIG",
      "name": "Figuig",
      "short_code": "FIG"
    }, {
      "code": "MA-GUF",
      "name": "Guercif",
      "short_code": "GUF"
    }, {
      "code": "MA-JRA",
      "name": "Jerada",
      "short_code": "JRA"
    }, {
      "code": "MA-NAD",
      "name": "Nador",
      "short_code": "NAD"
    }, {
      "code": "MA-TAI",
      "name": "Taourirt",
      "short_code": "TAI"
    }, {
      "code": "MA-03",
      "name": "Fès-Meknès",
      "short_code": "03"
    }, {
      "code": "MA-FES",
      "name": "Fès",
      "short_code": "FES"
    }, {
      "code": "MA-MEK",
      "name": "Meknès",
      "short_code": "MEK"
    }, {
      "code": "MA-BOM",
      "name": "Boulemane",
      "short_code": "BOM"
    }, {
      "code": "MA-HAJ",
      "name": "El Hajeb",
      "short_code": "HAJ"
    }, {
      "code": "MA-IFR",
      "name": "Ifrane",
      "short_code": "IFR"
    }, {
      "code": "MA-MOU",
      "name": "Moulay Yacoub",
      "short_code": "MOU"
    }, {
      "code": "MA-SEF",
      "name": "Sefrou",
      "short_code": "SEF"
    }, {
      "code": "MA-TAO",
      "name": "Taounate",
      "short_code": "TAO"
    }, {
      "code": "MA-TAZ",
      "name": "Taza",
      "short_code": "TAZ"
    }, {
      "code": "MA-04",
      "name": "Rabat-Salé-Kénitra",
      "short_code": "04"
    }, {
      "code": "MA-RAB",
      "name": "Rabat",
      "short_code": "RAB"
    }, {
      "code": "MA-SAL",
      "name": "Salé",
      "short_code": "SAL"
    }, {
      "code": "MA-SKH",
      "name": "Skhirate-Témara",
      "short_code": "SKH"
    }, {
      "code": "MA-KEN",
      "name": "Kénitra",
      "short_code": "KEN"
    }, {
      "code": "MA-KHE",
      "name": "Khemisset",
      "short_code": "KHE"
    }, {
      "code": "MA-NOU",
      "name": "Nouaceur",
      "short_code": "NOU"
    }, {
      "code": "MA-SIK",
      "name": "Sidi Kacem",
      "short_code": "SIK"
    }, {
      "code": "MA-SIL",
      "name": "Sidi Slimane",
      "short_code": "SIL"
    }, {
      "code": "MA-05",
      "name": "Béni Mellal-Khénifra",
      "short_code": "05"
    }, {
      "code": "MA-AZI",
      "name": "Azilal",
      "short_code": "AZI"
    }, {
      "code": "MA-BEM",
      "name": "Béni Mellal",
      "short_code": "BEM"
    }, {
      "code": "MA-FQH",
      "name": "Fquih Ben Salah",
      "short_code": "FQH"
    }, {
      "code": "MA-KHN",
      "name": "Khenifra",
      "short_code": "KHN"
    }, {
      "code": "MA-KHO",
      "name": "Khouribga",
      "short_code": "KHO"
    }, {
      "code": "MA-06",
      "name": "Casablanca-Settat",
      "short_code": "06"
    }, {
      "code": "MA-CAS",
      "name": "Dar el Beïda*",
      "short_code": "CAS"
    }, {
      "code": "MA-MOH",
      "name": "Mohammadia",
      "short_code": "MOH"
    }, {
      "code": "MA-BES",
      "name": "Benslimane",
      "short_code": "BES"
    }, {
      "code": "MA-BRR",
      "name": "Berrechid",
      "short_code": "BRR"
    }, {
      "code": "MA-CHT",
      "name": "Chtouka-Ait Baha",
      "short_code": "CHT"
    }, {
      "code": "MA-JDI",
      "name": "El Jadida",
      "short_code": "JDI"
    }, {
      "code": "MA-MED",
      "name": "Médiouna",
      "short_code": "MED"
    }, {
      "code": "MA-SET",
      "name": "Settat",
      "short_code": "SET"
    }, {
      "code": "MA-SIB",
      "name": "Sidi Bennour",
      "short_code": "SIB"
    }, {
      "code": "MA-07",
      "name": "Marrakech-Safi",
      "short_code": "07"
    }, {
      "code": "MA-MAR",
      "name": "Marrakech",
      "short_code": "MAR"
    }, {
      "code": "MA-CHI",
      "name": "Chichaoua",
      "short_code": "CHI"
    }, {
      "code": "MA-ESI",
      "name": "Essaouira",
      "short_code": "ESI"
    }, {
      "code": "MA-HAO",
      "name": "Al Haouz",
      "short_code": "HAO"
    }, {
      "code": "MA-KES",
      "name": "El Kelâa des Sraghna",
      "short_code": "KES"
    }, {
      "code": "MA-REH",
      "name": "Rehamna",
      "short_code": "REH"
    }, {
      "code": "MA-SAF",
      "name": "Safi",
      "short_code": "SAF"
    }, {
      "code": "MA-YUS",
      "name": "Youssoufia",
      "short_code": "YUS"
    }, {
      "code": "MA-08",
      "name": "Drâa-Tafilalet",
      "short_code": "08"
    }, {
      "code": "MA-ERR",
      "name": "Errachidia",
      "short_code": "ERR"
    }, {
      "code": "MA-MID",
      "name": "Midelt",
      "short_code": "MID"
    }, {
      "code": "MA-OUA",
      "name": "Ouarzazate",
      "short_code": "OUA"
    }, {
      "code": "MA-TIN",
      "name": "Tinghir",
      "short_code": "TIN"
    }, {
      "code": "MA-ZAG",
      "name": "Zagora",
      "short_code": "ZAG"
    }, {
      "code": "MA-09",
      "name": "Souss-Massa",
      "short_code": "09"
    }, {
      "code": "MA-AGD",
      "name": "Agadir-Ida-Ou-Tanane",
      "short_code": "AGD"
    }, {
      "code": "MA-INE",
      "name": "Inezgane-Ait Melloul",
      "short_code": "INE"
    }, {
      "code": "MA-TAR",
      "name": "Taroudant",
      "short_code": "TAR"
    }, {
      "code": "MA-TAT",
      "name": "Tata",
      "short_code": "TAT"
    }, {
      "code": "MA-TIZ",
      "name": "Tiznit",
      "short_code": "TIZ"
    }, {
      "code": "MA-10",
      "name": "Guelmim-Oued Noun (EH-partial)",
      "short_code": "10"
    }, {
      "code": "MA-ASZ",
      "name": "Assa-Zag (EH-partial)",
      "short_code": "ASZ"
    }, {
      "code": "MA-GUE",
      "name": "Guelmim",
      "short_code": "GUE"
    }, {
      "code": "MA-SIF",
      "name": "Sidi Ifni",
      "short_code": "SIF"
    }, {
      "code": "MA-TNT",
      "name": "Tan-Tan (EH-partial)",
      "short_code": "TNT"
    }, {
      "code": "MA-11",
      "name": "Laâyoune-Sakia El Hamra (EH-partial)",
      "short_code": "11"
    }, {
      "code": "MA-BOD",
      "name": "Boujdour (EH)",
      "short_code": "BOD"
    }, {
      "code": "MA-ESM",
      "name": "Es-Semara (EH-partial)",
      "short_code": "ESM"
    }, {
      "code": "MA-LAA",
      "name": "Laâyoune (EH)",
      "short_code": "LAA"
    }, {
      "code": "MA-TAF",
      "name": "Tarfaya (EH-partial)",
      "short_code": "TAF"
    }, {
      "code": "MA-12",
      "name": "Dakhla-Oued Ed-Dahab (EH)",
      "short_code": "12"
    }, {
      "code": "MA-AOU",
      "name": "Aousserd (EH)",
      "short_code": "AOU"
    }, {
      "code": "MA-OUD",
      "name": "Oued Ed-Dahab (EH)",
      "short_code": "OUD"
    }]
  }, {
    "code": "NLD",
    "name": "Netherlands",
    "subdivisions": [{
      "code": "NL-DR",
      "name": "Drenthe",
      "short_code": "DR"
    }, {
      "code": "NL-FL",
      "name": "Flevoland",
      "short_code": "FL"
    }, {
      "code": "NL-FR",
      "name": "Fryslân",
      "short_code": "FR"
    }, {
      "code": "NL-GE",
      "name": "Gelderland",
      "short_code": "GE"
    }, {
      "code": "NL-GR",
      "name": "Groningen",
      "short_code": "GR"
    }, {
      "code": "NL-LI",
      "name": "Limburg",
      "short_code": "LI"
    }, {
      "code": "NL-NB",
      "name": "Noord-Brabant",
      "short_code": "NB"
    }, {
      "code": "NL-NH",
      "name": "Noord-Holland",
      "short_code": "NH"
    }, {
      "code": "NL-OV",
      "name": "Overijssel",
      "short_code": "OV"
    }, {
      "code": "NL-UT",
      "name": "Utrecht",
      "short_code": "UT"
    }, {
      "code": "NL-ZE",
      "name": "Zeeland",
      "short_code": "ZE"
    }, {
      "code": "NL-ZH",
      "name": "Zuid-Holland",
      "short_code": "ZH"
    }, {
      "code": "NL-AW",
      "name": "Aruba",
      "short_code": "AW"
    }, {
      "code": "NL-CW",
      "name": "Curaçao",
      "short_code": "CW"
    }, {
      "code": "NL-SX",
      "name": "Sint Maarten",
      "short_code": "SX"
    }, {
      "code": "NL-BQ1",
      "name": "Bonaire",
      "short_code": "BQ1"
    }, {
      "code": "NL-BQ2",
      "name": "Saba",
      "short_code": "BQ2"
    }, {
      "code": "NL-BQ3",
      "name": "Sint Eustatius",
      "short_code": "BQ3"
    }]
  }, {
    "code": "NZL",
    "name": "New Zealand",
    "subdivisions": [{
      "code": "NZ-AUK",
      "name": "Auckland",
      "short_code": "AUK"
    }, {
      "code": "NZ-BOP",
      "name": "Bay of Plenty",
      "short_code": "BOP"
    }, {
      "code": "NZ-CAN",
      "name": "Canterbury",
      "short_code": "CAN"
    }, {
      "code": "NZ-GIS",
      "name": "Gisborne",
      "short_code": "GIS"
    }, {
      "code": "NZ-HKB",
      "name": "Hawke's Bay",
      "short_code": "HKB"
    }, {
      "code": "NZ-MBH",
      "name": "Marlborough",
      "short_code": "MBH"
    }, {
      "code": "NZ-MWT",
      "name": "Manawatu-Wanganui",
      "short_code": "MWT"
    }, {
      "code": "NZ-NSN",
      "name": "Nelson",
      "short_code": "NSN"
    }, {
      "code": "NZ-NTL",
      "name": "Northland",
      "short_code": "NTL"
    }, {
      "code": "NZ-OTA",
      "name": "Otago",
      "short_code": "OTA"
    }, {
      "code": "NZ-STL",
      "name": "Southland",
      "short_code": "STL"
    }, {
      "code": "NZ-TAS",
      "name": "Tasman",
      "short_code": "TAS"
    }, {
      "code": "NZ-TKI",
      "name": "Taranaki",
      "short_code": "TKI"
    }, {
      "code": "NZ-WGN",
      "name": "Wellington",
      "short_code": "WGN"
    }, {
      "code": "NZ-WKO",
      "name": "Waikato",
      "short_code": "WKO"
    }, {
      "code": "NZ-WTC",
      "name": "West Coast",
      "short_code": "WTC"
    }, {
      "code": "NZ-CIT",
      "name": "Chatham Islands Territory",
      "short_code": "CIT"
    }]
  }, {
    "code": "NOR",
    "name": "Norway",
    "subdivisions": [{
      "code": "NO-01",
      "name": "Østfold",
      "short_code": "01"
    }, {
      "code": "NO-02",
      "name": "Akershus",
      "short_code": "02"
    }, {
      "code": "NO-03",
      "name": "Oslo",
      "short_code": "03"
    }, {
      "code": "NO-04",
      "name": "Hedmark",
      "short_code": "04"
    }, {
      "code": "NO-05",
      "name": "Oppland",
      "short_code": "05"
    }, {
      "code": "NO-06",
      "name": "Buskerud",
      "short_code": "06"
    }, {
      "code": "NO-07",
      "name": "Vestfold",
      "short_code": "07"
    }, {
      "code": "NO-08",
      "name": "Telemark",
      "short_code": "08"
    }, {
      "code": "NO-09",
      "name": "Aust-Agder",
      "short_code": "09"
    }, {
      "code": "NO-10",
      "name": "Vest-Agder",
      "short_code": "10"
    }, {
      "code": "NO-11",
      "name": "Rogaland",
      "short_code": "11"
    }, {
      "code": "NO-12",
      "name": "Hordaland",
      "short_code": "12"
    }, {
      "code": "NO-14",
      "name": "Sogn og Fjordane",
      "short_code": "14"
    }, {
      "code": "NO-15",
      "name": "Møre og Romsdal",
      "short_code": "15"
    }, {
      "code": "NO-18",
      "name": "Nordland",
      "short_code": "18"
    }, {
      "code": "NO-19",
      "name": "Troms",
      "short_code": "19"
    }, {
      "code": "NO-20",
      "name": "Finnmark",
      "short_code": "20"
    }, {
      "code": "NO-50",
      "name": "Trøndelag",
      "short_code": "50"
    }, {
      "code": "NO-21",
      "name": "Svalbard (Arctic Region)",
      "short_code": "21"
    }, {
      "code": "NO-22",
      "name": "Jan Mayen (Arctic Region)",
      "short_code": "22"
    }]
  }, {
    "code": "POL",
    "name": "Poland",
    "subdivisions": [{
      "code": "PL-02",
      "name": "Dolnośląskie",
      "short_code": "02"
    }, {
      "code": "PL-04",
      "name": "Kujawsko-pomorskie",
      "short_code": "04"
    }, {
      "code": "PL-06",
      "name": "Lubelskie",
      "short_code": "06"
    }, {
      "code": "PL-08",
      "name": "Lubuskie",
      "short_code": "08"
    }, {
      "code": "PL-10",
      "name": "Łódzkie",
      "short_code": "10"
    }, {
      "code": "PL-12",
      "name": "Małopolskie",
      "short_code": "12"
    }, {
      "code": "PL-14",
      "name": "Mazowieckie",
      "short_code": "14"
    }, {
      "code": "PL-16",
      "name": "Opolskie",
      "short_code": "16"
    }, {
      "code": "PL-18",
      "name": "Podkarpackie",
      "short_code": "18"
    }, {
      "code": "PL-20",
      "name": "Podlaskie",
      "short_code": "20"
    }, {
      "code": "PL-22",
      "name": "Pomorskie",
      "short_code": "22"
    }, {
      "code": "PL-24",
      "name": "Śląskie",
      "short_code": "24"
    }, {
      "code": "PL-26",
      "name": "Świętokrzyskie",
      "short_code": "26"
    }, {
      "code": "PL-28",
      "name": "Warmińsko-mazurskie",
      "short_code": "28"
    }, {
      "code": "PL-30",
      "name": "Wielkopolskie",
      "short_code": "30"
    }, {
      "code": "PL-32",
      "name": "Zachodniopomorskie",
      "short_code": "32"
    }]
  }, {
    "code": "PRT",
    "name": "Portugal",
    "subdivisions": [{
      "code": "PT-20",
      "name": "Região Autónoma dos Açores",
      "short_code": "20"
    }, {
      "code": "PT-30",
      "name": "Região Autónoma da Madeira",
      "short_code": "30"
    }, {
      "code": "PT-01",
      "name": "Aveiro",
      "short_code": "01"
    }, {
      "code": "PT-02",
      "name": "Beja",
      "short_code": "02"
    }, {
      "code": "PT-03",
      "name": "Braga",
      "short_code": "03"
    }, {
      "code": "PT-04",
      "name": "Bragança",
      "short_code": "04"
    }, {
      "code": "PT-05",
      "name": "Castelo Branco",
      "short_code": "05"
    }, {
      "code": "PT-06",
      "name": "Coimbra",
      "short_code": "06"
    }, {
      "code": "PT-07",
      "name": "Évora",
      "short_code": "07"
    }, {
      "code": "PT-08",
      "name": "Faro",
      "short_code": "08"
    }, {
      "code": "PT-09",
      "name": "Guarda",
      "short_code": "09"
    }, {
      "code": "PT-10",
      "name": "Leiria",
      "short_code": "10"
    }, {
      "code": "PT-11",
      "name": "Lisboa",
      "short_code": "11"
    }, {
      "code": "PT-12",
      "name": "Portalegre",
      "short_code": "12"
    }, {
      "code": "PT-13",
      "name": "Porto",
      "short_code": "13"
    }, {
      "code": "PT-14",
      "name": "Santarém",
      "short_code": "14"
    }, {
      "code": "PT-15",
      "name": "Setúbal",
      "short_code": "15"
    }, {
      "code": "PT-16",
      "name": "Viana do Castelo",
      "short_code": "16"
    }, {
      "code": "PT-17",
      "name": "Vila Real",
      "short_code": "17"
    }, {
      "code": "PT-18",
      "name": "Viseu",
      "short_code": "18"
    }]
  }, {
    "code": "QAT",
    "name": "Qatar",
    "subdivisions": [{
      "code": "QA-DA",
      "name": "Ad Dawḩah",
      "short_code": "DA"
    }, {
      "code": "QA-KH",
      "name": "Al Khawr wa adh Dhakhīrah",
      "short_code": "KH"
    }, {
      "code": "QA-MS",
      "name": "Ash Shamāl",
      "short_code": "MS"
    }, {
      "code": "QA-RA",
      "name": "Ar Rayyān",
      "short_code": "RA"
    }, {
      "code": "QA-SH",
      "name": "Ash Shīḩānīyah",
      "short_code": "SH"
    }, {
      "code": "QA-US",
      "name": "Umm Şalāl",
      "short_code": "US"
    }, {
      "code": "QA-WA",
      "name": "Al Wakrah",
      "short_code": "WA"
    }, {
      "code": "QA-ZA",
      "name": "Az̧ Z̧a‘āyin",
      "short_code": "ZA"
    }]
  }, {
    "code": "ROU",
    "name": "Romania",
    "subdivisions": [{
      "code": "RO-AB",
      "name": "Alba",
      "short_code": "AB"
    }, {
      "code": "RO-AG",
      "name": "Argeș",
      "short_code": "AG"
    }, {
      "code": "RO-AR",
      "name": "Arad",
      "short_code": "AR"
    }, {
      "code": "RO-BC",
      "name": "Bacău",
      "short_code": "BC"
    }, {
      "code": "RO-BH",
      "name": "Bihor",
      "short_code": "BH"
    }, {
      "code": "RO-BN",
      "name": "Bistrița-Năsăud",
      "short_code": "BN"
    }, {
      "code": "RO-BR",
      "name": "Brăila",
      "short_code": "BR"
    }, {
      "code": "RO-BT",
      "name": "Botoșani",
      "short_code": "BT"
    }, {
      "code": "RO-BV",
      "name": "Brașov",
      "short_code": "BV"
    }, {
      "code": "RO-BZ",
      "name": "Buzău",
      "short_code": "BZ"
    }, {
      "code": "RO-CJ",
      "name": "Cluj",
      "short_code": "CJ"
    }, {
      "code": "RO-CL",
      "name": "Călărași",
      "short_code": "CL"
    }, {
      "code": "RO-CS",
      "name": "Caraș-Severin",
      "short_code": "CS"
    }, {
      "code": "RO-CT",
      "name": "Constanța",
      "short_code": "CT"
    }, {
      "code": "RO-CV",
      "name": "Covasna",
      "short_code": "CV"
    }, {
      "code": "RO-DB",
      "name": "Dâmbovița",
      "short_code": "DB"
    }, {
      "code": "RO-DJ",
      "name": "Dolj",
      "short_code": "DJ"
    }, {
      "code": "RO-GJ",
      "name": "Gorj",
      "short_code": "GJ"
    }, {
      "code": "RO-GL",
      "name": "Galați",
      "short_code": "GL"
    }, {
      "code": "RO-GR",
      "name": "Giurgiu",
      "short_code": "GR"
    }, {
      "code": "RO-HD",
      "name": "Hunedoara",
      "short_code": "HD"
    }, {
      "code": "RO-HR",
      "name": "Harghita",
      "short_code": "HR"
    }, {
      "code": "RO-IF",
      "name": "Ilfov",
      "short_code": "IF"
    }, {
      "code": "RO-IL",
      "name": "Ialomița",
      "short_code": "IL"
    }, {
      "code": "RO-IS",
      "name": "Iași",
      "short_code": "IS"
    }, {
      "code": "RO-MH",
      "name": "Mehedinți",
      "short_code": "MH"
    }, {
      "code": "RO-MM",
      "name": "Maramureș",
      "short_code": "MM"
    }, {
      "code": "RO-MS",
      "name": "Mureș",
      "short_code": "MS"
    }, {
      "code": "RO-NT",
      "name": "Neamț",
      "short_code": "NT"
    }, {
      "code": "RO-OT",
      "name": "Olt",
      "short_code": "OT"
    }, {
      "code": "RO-PH",
      "name": "Prahova",
      "short_code": "PH"
    }, {
      "code": "RO-SB",
      "name": "Sibiu",
      "short_code": "SB"
    }, {
      "code": "RO-SJ",
      "name": "Sălaj",
      "short_code": "SJ"
    }, {
      "code": "RO-SM",
      "name": "Satu Mare",
      "short_code": "SM"
    }, {
      "code": "RO-SV",
      "name": "Suceava",
      "short_code": "SV"
    }, {
      "code": "RO-TL",
      "name": "Tulcea",
      "short_code": "TL"
    }, {
      "code": "RO-TM",
      "name": "Timiș",
      "short_code": "TM"
    }, {
      "code": "RO-TR",
      "name": "Teleorman",
      "short_code": "TR"
    }, {
      "code": "RO-VL",
      "name": "Vâlcea",
      "short_code": "VL"
    }, {
      "code": "RO-VN",
      "name": "Vrancea",
      "short_code": "VN"
    }, {
      "code": "RO-VS",
      "name": "Vaslui",
      "short_code": "VS"
    }, {
      "code": "RO-B",
      "name": "București",
      "short_code": "B"
    }]
  }, {
    "code": "SRB",
    "name": "Serbia",
    "subdivisions": [{
      "code": "RS-KM",
      "name": "Kosovo-Metohija",
      "short_code": "KM"
    }, {
      "code": "RS-25",
      "name": "Kosovski okrug",
      "short_code": "25"
    }, {
      "code": "RS-26",
      "name": "Pećki okrug",
      "short_code": "26"
    }, {
      "code": "RS-27",
      "name": "Prizrenski okrug",
      "short_code": "27"
    }, {
      "code": "RS-28",
      "name": "Kosovsko-Mitrovački okrug",
      "short_code": "28"
    }, {
      "code": "RS-29",
      "name": "Kosovsko-Pomoravski okrug",
      "short_code": "29"
    }, {
      "code": "RS-VO",
      "name": "Vojvodina",
      "short_code": "VO"
    }, {
      "code": "RS-01",
      "name": "Severnobački okrug",
      "short_code": "01"
    }, {
      "code": "RS-02",
      "name": "Srednjebanatski okrug",
      "short_code": "02"
    }, {
      "code": "RS-03",
      "name": "Severnobanatski okrug",
      "short_code": "03"
    }, {
      "code": "RS-04",
      "name": "Južnobanatski okrug",
      "short_code": "04"
    }, {
      "code": "RS-05",
      "name": "Zapadnobački okrug",
      "short_code": "05"
    }, {
      "code": "RS-06",
      "name": "Južnobački okrug",
      "short_code": "06"
    }, {
      "code": "RS-07",
      "name": "Sremski okrug",
      "short_code": "07"
    }, {
      "code": "RS-00",
      "name": "Beograd",
      "short_code": "00"
    }, {
      "code": "RS-08",
      "name": "Mačvanski okrug",
      "short_code": "08"
    }, {
      "code": "RS-09",
      "name": "Kolubarski okrug",
      "short_code": "09"
    }, {
      "code": "RS-10",
      "name": "Podunavski okrug",
      "short_code": "10"
    }, {
      "code": "RS-11",
      "name": "Braničevski okrug",
      "short_code": "11"
    }, {
      "code": "RS-12",
      "name": "Šumadijski okrug",
      "short_code": "12"
    }, {
      "code": "RS-13",
      "name": "Pomoravski okrug",
      "short_code": "13"
    }, {
      "code": "RS-14",
      "name": "Borski okrug",
      "short_code": "14"
    }, {
      "code": "RS-15",
      "name": "Zaječarski okrug",
      "short_code": "15"
    }, {
      "code": "RS-16",
      "name": "Zlatiborski okrug",
      "short_code": "16"
    }, {
      "code": "RS-17",
      "name": "Moravički okrug",
      "short_code": "17"
    }, {
      "code": "RS-18",
      "name": "Raški okrug",
      "short_code": "18"
    }, {
      "code": "RS-19",
      "name": "Rasinski okrug",
      "short_code": "19"
    }, {
      "code": "RS-20",
      "name": "Nišavski okrug",
      "short_code": "20"
    }, {
      "code": "RS-21",
      "name": "Toplički okrug",
      "short_code": "21"
    }, {
      "code": "RS-22",
      "name": "Pirotski okrug",
      "short_code": "22"
    }, {
      "code": "RS-23",
      "name": "Jablanički okrug",
      "short_code": "23"
    }, {
      "code": "RS-24",
      "name": "Pčinjski okrug",
      "short_code": "24"
    }]
  }, {
    "code": "SGP",
    "name": "Singapore",
    "subdivisions": [{
      "code": "SG-01",
      "name": "Central Singapore",
      "short_code": "01"
    }, {
      "code": "SG-02",
      "name": "North East",
      "short_code": "02"
    }, {
      "code": "SG-03",
      "name": "North West",
      "short_code": "03"
    }, {
      "code": "SG-04",
      "name": "South East",
      "short_code": "04"
    }, {
      "code": "SG-05",
      "name": "South West",
      "short_code": "05"
    }]
  }, {
    "code": "SVK",
    "name": "Slovakia",
    "subdivisions": [{
      "code": "SK-BC",
      "name": "Banskobystrický kraj",
      "short_code": "BC"
    }, {
      "code": "SK-BL",
      "name": "Bratislavský kraj",
      "short_code": "BL"
    }, {
      "code": "SK-KI",
      "name": "Košický kraj",
      "short_code": "KI"
    }, {
      "code": "SK-NI",
      "name": "Nitriansky kraj",
      "short_code": "NI"
    }, {
      "code": "SK-PV",
      "name": "Prešovský kraj",
      "short_code": "PV"
    }, {
      "code": "SK-TA",
      "name": "Trnavský kraj",
      "short_code": "TA"
    }, {
      "code": "SK-TC",
      "name": "Trenčiansky kraj",
      "short_code": "TC"
    }, {
      "code": "SK-ZI",
      "name": "Žilinský kraj",
      "short_code": "ZI"
    }]
  }, {
    "code": "SVN",
    "name": "Slovenia",
    "subdivisions": [{
      "code": "SI-001",
      "name": "Ajdovščina",
      "short_code": "001"
    }, {
      "code": "SI-002",
      "name": "Beltinci",
      "short_code": "002"
    }, {
      "code": "SI-003",
      "name": "Bled",
      "short_code": "003"
    }, {
      "code": "SI-004",
      "name": "Bohinj",
      "short_code": "004"
    }, {
      "code": "SI-005",
      "name": "Borovnica",
      "short_code": "005"
    }, {
      "code": "SI-006",
      "name": "Bovec",
      "short_code": "006"
    }, {
      "code": "SI-007",
      "name": "Brda",
      "short_code": "007"
    }, {
      "code": "SI-008",
      "name": "Brezovica",
      "short_code": "008"
    }, {
      "code": "SI-009",
      "name": "Brežice",
      "short_code": "009"
    }, {
      "code": "SI-010",
      "name": "Tišina",
      "short_code": "010"
    }, {
      "code": "SI-011",
      "name": "Celje",
      "short_code": "011"
    }, {
      "code": "SI-012",
      "name": "Cerklje na Gorenjskem",
      "short_code": "012"
    }, {
      "code": "SI-013",
      "name": "Cerknica",
      "short_code": "013"
    }, {
      "code": "SI-014",
      "name": "Cerkno",
      "short_code": "014"
    }, {
      "code": "SI-015",
      "name": "Črenšovci",
      "short_code": "015"
    }, {
      "code": "SI-016",
      "name": "Črna na Koroškem",
      "short_code": "016"
    }, {
      "code": "SI-017",
      "name": "Črnomelj",
      "short_code": "017"
    }, {
      "code": "SI-018",
      "name": "Destrnik",
      "short_code": "018"
    }, {
      "code": "SI-019",
      "name": "Divača",
      "short_code": "019"
    }, {
      "code": "SI-020",
      "name": "Dobrepolje",
      "short_code": "020"
    }, {
      "code": "SI-021",
      "name": "Dobrova-Polhov Gradec",
      "short_code": "021"
    }, {
      "code": "SI-022",
      "name": "Dol pri Ljubljani",
      "short_code": "022"
    }, {
      "code": "SI-023",
      "name": "Domžale",
      "short_code": "023"
    }, {
      "code": "SI-024",
      "name": "Dornava",
      "short_code": "024"
    }, {
      "code": "SI-025",
      "name": "Dravograd",
      "short_code": "025"
    }, {
      "code": "SI-026",
      "name": "Duplek",
      "short_code": "026"
    }, {
      "code": "SI-027",
      "name": "Gorenja vas-Poljane",
      "short_code": "027"
    }, {
      "code": "SI-028",
      "name": "Gorišnica",
      "short_code": "028"
    }, {
      "code": "SI-029",
      "name": "Gornja Radgona",
      "short_code": "029"
    }, {
      "code": "SI-030",
      "name": "Gornji Grad",
      "short_code": "030"
    }, {
      "code": "SI-031",
      "name": "Gornji Petrovci",
      "short_code": "031"
    }, {
      "code": "SI-032",
      "name": "Grosuplje",
      "short_code": "032"
    }, {
      "code": "SI-033",
      "name": "Šalovci",
      "short_code": "033"
    }, {
      "code": "SI-034",
      "name": "Hrastnik",
      "short_code": "034"
    }, {
      "code": "SI-035",
      "name": "Hrpelje-Kozina",
      "short_code": "035"
    }, {
      "code": "SI-036",
      "name": "Idrija",
      "short_code": "036"
    }, {
      "code": "SI-037",
      "name": "Ig",
      "short_code": "037"
    }, {
      "code": "SI-038",
      "name": "Ilirska Bistrica",
      "short_code": "038"
    }, {
      "code": "SI-039",
      "name": "Ivančna Gorica",
      "short_code": "039"
    }, {
      "code": "SI-040",
      "name": "Isola",
      "short_code": "040"
    }, {
      "code": "SI-041",
      "name": "Jesenice",
      "short_code": "041"
    }, {
      "code": "SI-042",
      "name": "Juršinci",
      "short_code": "042"
    }, {
      "code": "SI-043",
      "name": "Kamnik",
      "short_code": "043"
    }, {
      "code": "SI-044",
      "name": "Kanal",
      "short_code": "044"
    }, {
      "code": "SI-045",
      "name": "Kidričevo",
      "short_code": "045"
    }, {
      "code": "SI-046",
      "name": "Kobarid",
      "short_code": "046"
    }, {
      "code": "SI-047",
      "name": "Kobilje",
      "short_code": "047"
    }, {
      "code": "SI-048",
      "name": "Kočevje",
      "short_code": "048"
    }, {
      "code": "SI-049",
      "name": "Komen",
      "short_code": "049"
    }, {
      "code": "SI-050",
      "name": "Capodistria",
      "short_code": "050"
    }, {
      "code": "SI-051",
      "name": "Kozje",
      "short_code": "051"
    }, {
      "code": "SI-052",
      "name": "Kranj",
      "short_code": "052"
    }, {
      "code": "SI-053",
      "name": "Kranjska Gora",
      "short_code": "053"
    }, {
      "code": "SI-054",
      "name": "Krško",
      "short_code": "054"
    }, {
      "code": "SI-055",
      "name": "Kungota",
      "short_code": "055"
    }, {
      "code": "SI-056",
      "name": "Kuzma",
      "short_code": "056"
    }, {
      "code": "SI-057",
      "name": "Laško",
      "short_code": "057"
    }, {
      "code": "SI-058",
      "name": "Lenart",
      "short_code": "058"
    }, {
      "code": "SI-059",
      "name": "Lendva",
      "short_code": "059"
    }, {
      "code": "SI-060",
      "name": "Litija",
      "short_code": "060"
    }, {
      "code": "SI-061",
      "name": "Ljubljana",
      "short_code": "061"
    }, {
      "code": "SI-062",
      "name": "Ljubno",
      "short_code": "062"
    }, {
      "code": "SI-063",
      "name": "Ljutomer",
      "short_code": "063"
    }, {
      "code": "SI-064",
      "name": "Logatec",
      "short_code": "064"
    }, {
      "code": "SI-065",
      "name": "Loška Dolina",
      "short_code": "065"
    }, {
      "code": "SI-066",
      "name": "Loški Potok",
      "short_code": "066"
    }, {
      "code": "SI-067",
      "name": "Luče",
      "short_code": "067"
    }, {
      "code": "SI-068",
      "name": "Lukovica",
      "short_code": "068"
    }, {
      "code": "SI-069",
      "name": "Majšperk",
      "short_code": "069"
    }, {
      "code": "SI-070",
      "name": "Maribor",
      "short_code": "070"
    }, {
      "code": "SI-071",
      "name": "Medvode",
      "short_code": "071"
    }, {
      "code": "SI-072",
      "name": "Mengeš",
      "short_code": "072"
    }, {
      "code": "SI-073",
      "name": "Metlika",
      "short_code": "073"
    }, {
      "code": "SI-074",
      "name": "Mežica",
      "short_code": "074"
    }, {
      "code": "SI-075",
      "name": "Miren-Kostanjevica",
      "short_code": "075"
    }, {
      "code": "SI-076",
      "name": "Mislinja",
      "short_code": "076"
    }, {
      "code": "SI-077",
      "name": "Moravče",
      "short_code": "077"
    }, {
      "code": "SI-078",
      "name": "Moravske Toplice",
      "short_code": "078"
    }, {
      "code": "SI-079",
      "name": "Mozirje",
      "short_code": "079"
    }, {
      "code": "SI-080",
      "name": "Murska Sobota",
      "short_code": "080"
    }, {
      "code": "SI-081",
      "name": "Muta",
      "short_code": "081"
    }, {
      "code": "SI-082",
      "name": "Naklo",
      "short_code": "082"
    }, {
      "code": "SI-083",
      "name": "Nazarje",
      "short_code": "083"
    }, {
      "code": "SI-084",
      "name": "Nova Gorica",
      "short_code": "084"
    }, {
      "code": "SI-085",
      "name": "Novo Mesto",
      "short_code": "085"
    }, {
      "code": "SI-086",
      "name": "Odranci",
      "short_code": "086"
    }, {
      "code": "SI-087",
      "name": "Ormož",
      "short_code": "087"
    }, {
      "code": "SI-088",
      "name": "Osilnica",
      "short_code": "088"
    }, {
      "code": "SI-089",
      "name": "Pesnica",
      "short_code": "089"
    }, {
      "code": "SI-090",
      "name": "Pirano",
      "short_code": "090"
    }, {
      "code": "SI-091",
      "name": "Pivka",
      "short_code": "091"
    }, {
      "code": "SI-092",
      "name": "Podčetrtek",
      "short_code": "092"
    }, {
      "code": "SI-093",
      "name": "Podvelka",
      "short_code": "093"
    }, {
      "code": "SI-094",
      "name": "Postojna",
      "short_code": "094"
    }, {
      "code": "SI-095",
      "name": "Preddvor",
      "short_code": "095"
    }, {
      "code": "SI-096",
      "name": "Ptuj",
      "short_code": "096"
    }, {
      "code": "SI-097",
      "name": "Puconci",
      "short_code": "097"
    }, {
      "code": "SI-098",
      "name": "Rače-Fram",
      "short_code": "098"
    }, {
      "code": "SI-099",
      "name": "Radeče",
      "short_code": "099"
    }, {
      "code": "SI-100",
      "name": "Radenci",
      "short_code": "100"
    }, {
      "code": "SI-101",
      "name": "Radlje ob Dravi",
      "short_code": "101"
    }, {
      "code": "SI-102",
      "name": "Radovljica",
      "short_code": "102"
    }, {
      "code": "SI-103",
      "name": "Ravne na Koroškem",
      "short_code": "103"
    }, {
      "code": "SI-104",
      "name": "Ribnica",
      "short_code": "104"
    }, {
      "code": "SI-105",
      "name": "Rogašovci",
      "short_code": "105"
    }, {
      "code": "SI-106",
      "name": "Rogaška Slatina",
      "short_code": "106"
    }, {
      "code": "SI-107",
      "name": "Rogatec",
      "short_code": "107"
    }, {
      "code": "SI-108",
      "name": "Ruše",
      "short_code": "108"
    }, {
      "code": "SI-109",
      "name": "Semič",
      "short_code": "109"
    }, {
      "code": "SI-110",
      "name": "Sevnica",
      "short_code": "110"
    }, {
      "code": "SI-111",
      "name": "Sežana",
      "short_code": "111"
    }, {
      "code": "SI-112",
      "name": "Slovenj Gradec",
      "short_code": "112"
    }, {
      "code": "SI-113",
      "name": "Slovenska Bistrica",
      "short_code": "113"
    }, {
      "code": "SI-114",
      "name": "Slovenske Konjice",
      "short_code": "114"
    }, {
      "code": "SI-115",
      "name": "Starše",
      "short_code": "115"
    }, {
      "code": "SI-116",
      "name": "Sveti Jurij",
      "short_code": "116"
    }, {
      "code": "SI-117",
      "name": "Šenčur",
      "short_code": "117"
    }, {
      "code": "SI-118",
      "name": "Šentilj",
      "short_code": "118"
    }, {
      "code": "SI-119",
      "name": "Šentjernej",
      "short_code": "119"
    }, {
      "code": "SI-120",
      "name": "Šentjur",
      "short_code": "120"
    }, {
      "code": "SI-121",
      "name": "Škocjan",
      "short_code": "121"
    }, {
      "code": "SI-122",
      "name": "Škofja Loka",
      "short_code": "122"
    }, {
      "code": "SI-123",
      "name": "Škofljica",
      "short_code": "123"
    }, {
      "code": "SI-124",
      "name": "Šmarje pri Jelšah",
      "short_code": "124"
    }, {
      "code": "SI-125",
      "name": "Šmartno ob Paki",
      "short_code": "125"
    }, {
      "code": "SI-126",
      "name": "Šoštanj",
      "short_code": "126"
    }, {
      "code": "SI-127",
      "name": "Štore",
      "short_code": "127"
    }, {
      "code": "SI-128",
      "name": "Tolmin",
      "short_code": "128"
    }, {
      "code": "SI-129",
      "name": "Trbovlje",
      "short_code": "129"
    }, {
      "code": "SI-130",
      "name": "Trebnje",
      "short_code": "130"
    }, {
      "code": "SI-131",
      "name": "Tržič",
      "short_code": "131"
    }, {
      "code": "SI-132",
      "name": "Turnišče",
      "short_code": "132"
    }, {
      "code": "SI-133",
      "name": "Velenje",
      "short_code": "133"
    }, {
      "code": "SI-134",
      "name": "Velike Lašče",
      "short_code": "134"
    }, {
      "code": "SI-135",
      "name": "Videm",
      "short_code": "135"
    }, {
      "code": "SI-136",
      "name": "Vipava",
      "short_code": "136"
    }, {
      "code": "SI-137",
      "name": "Vitanje",
      "short_code": "137"
    }, {
      "code": "SI-138",
      "name": "Vodice",
      "short_code": "138"
    }, {
      "code": "SI-139",
      "name": "Vojnik",
      "short_code": "139"
    }, {
      "code": "SI-140",
      "name": "Vrhnika",
      "short_code": "140"
    }, {
      "code": "SI-141",
      "name": "Vuzenica",
      "short_code": "141"
    }, {
      "code": "SI-142",
      "name": "Zagorje ob Savi",
      "short_code": "142"
    }, {
      "code": "SI-143",
      "name": "Zavrč",
      "short_code": "143"
    }, {
      "code": "SI-144",
      "name": "Zreče",
      "short_code": "144"
    }, {
      "code": "SI-146",
      "name": "Železniki",
      "short_code": "146"
    }, {
      "code": "SI-147",
      "name": "Žiri",
      "short_code": "147"
    }, {
      "code": "SI-148",
      "name": "Benedikt",
      "short_code": "148"
    }, {
      "code": "SI-149",
      "name": "Bistrica ob Sotli",
      "short_code": "149"
    }, {
      "code": "SI-150",
      "name": "Bloke",
      "short_code": "150"
    }, {
      "code": "SI-151",
      "name": "Braslovče",
      "short_code": "151"
    }, {
      "code": "SI-152",
      "name": "Cankova",
      "short_code": "152"
    }, {
      "code": "SI-153",
      "name": "Cerkvenjak",
      "short_code": "153"
    }, {
      "code": "SI-154",
      "name": "Dobje",
      "short_code": "154"
    }, {
      "code": "SI-155",
      "name": "Dobrna",
      "short_code": "155"
    }, {
      "code": "SI-156",
      "name": "Dobronak",
      "short_code": "156"
    }, {
      "code": "SI-157",
      "name": "Dolenjske Toplice",
      "short_code": "157"
    }, {
      "code": "SI-158",
      "name": "Grad",
      "short_code": "158"
    }, {
      "code": "SI-159",
      "name": "Hajdina",
      "short_code": "159"
    }, {
      "code": "SI-160",
      "name": "Hoče-Slivnica",
      "short_code": "160"
    }, {
      "code": "SI-161",
      "name": "Hodos",
      "short_code": "161"
    }, {
      "code": "SI-162",
      "name": "Horjul",
      "short_code": "162"
    }, {
      "code": "SI-163",
      "name": "Jezersko",
      "short_code": "163"
    }, {
      "code": "SI-164",
      "name": "Komenda",
      "short_code": "164"
    }, {
      "code": "SI-165",
      "name": "Kostel",
      "short_code": "165"
    }, {
      "code": "SI-166",
      "name": "Križevci",
      "short_code": "166"
    }, {
      "code": "SI-167",
      "name": "Lovrenc na Pohorju",
      "short_code": "167"
    }, {
      "code": "SI-168",
      "name": "Markovci",
      "short_code": "168"
    }, {
      "code": "SI-169",
      "name": "Miklavž na Dravskem Polju",
      "short_code": "169"
    }, {
      "code": "SI-170",
      "name": "Mirna Peč",
      "short_code": "170"
    }, {
      "code": "SI-171",
      "name": "Oplotnica",
      "short_code": "171"
    }, {
      "code": "SI-172",
      "name": "Podlehnik",
      "short_code": "172"
    }, {
      "code": "SI-173",
      "name": "Polzela",
      "short_code": "173"
    }, {
      "code": "SI-174",
      "name": "Prebold",
      "short_code": "174"
    }, {
      "code": "SI-175",
      "name": "Prevalje",
      "short_code": "175"
    }, {
      "code": "SI-176",
      "name": "Razkrižje",
      "short_code": "176"
    }, {
      "code": "SI-177",
      "name": "Ribnica na Pohorju",
      "short_code": "177"
    }, {
      "code": "SI-178",
      "name": "Selnica ob Dravi",
      "short_code": "178"
    }, {
      "code": "SI-179",
      "name": "Sodražica",
      "short_code": "179"
    }, {
      "code": "SI-180",
      "name": "Solčava",
      "short_code": "180"
    }, {
      "code": "SI-181",
      "name": "Sveta Ana",
      "short_code": "181"
    }, {
      "code": "SI-182",
      "name": "Sveti Andraž v Slovenskih Goricah",
      "short_code": "182"
    }, {
      "code": "SI-183",
      "name": "Šempeter-Vrtojba",
      "short_code": "183"
    }, {
      "code": "SI-184",
      "name": "Tabor",
      "short_code": "184"
    }, {
      "code": "SI-185",
      "name": "Trnovska Vas",
      "short_code": "185"
    }, {
      "code": "SI-186",
      "name": "Trzin",
      "short_code": "186"
    }, {
      "code": "SI-187",
      "name": "Velika Polana",
      "short_code": "187"
    }, {
      "code": "SI-188",
      "name": "Veržej",
      "short_code": "188"
    }, {
      "code": "SI-189",
      "name": "Vransko",
      "short_code": "189"
    }, {
      "code": "SI-190",
      "name": "Žalec",
      "short_code": "190"
    }, {
      "code": "SI-191",
      "name": "Žetale",
      "short_code": "191"
    }, {
      "code": "SI-192",
      "name": "Žirovnica",
      "short_code": "192"
    }, {
      "code": "SI-193",
      "name": "Žužemberk",
      "short_code": "193"
    }, {
      "code": "SI-194",
      "name": "Šmartno pri Litiji",
      "short_code": "194"
    }, {
      "code": "SI-195",
      "name": "Apače",
      "short_code": "195"
    }, {
      "code": "SI-196",
      "name": "Cirkulane",
      "short_code": "196"
    }, {
      "code": "SI-197",
      "name": "Kosanjevica na Krki",
      "short_code": "197"
    }, {
      "code": "SI-198",
      "name": "Makole",
      "short_code": "198"
    }, {
      "code": "SI-199",
      "name": "Mokronog-Trebelno",
      "short_code": "199"
    }, {
      "code": "SI-200",
      "name": "Poljčane",
      "short_code": "200"
    }, {
      "code": "SI-201",
      "name": "Renče-Vogrsko",
      "short_code": "201"
    }, {
      "code": "SI-202",
      "name": "Središče ob Dravi",
      "short_code": "202"
    }, {
      "code": "SI-203",
      "name": "Straža",
      "short_code": "203"
    }, {
      "code": "SI-204",
      "name": "Sveta Trojica v Slovenskih Goricah",
      "short_code": "204"
    }, {
      "code": "SI-205",
      "name": "Sveti Tomaž",
      "short_code": "205"
    }, {
      "code": "SI-206",
      "name": "Šmarješke Toplice",
      "short_code": "206"
    }, {
      "code": "SI-207",
      "name": "Gorje",
      "short_code": "207"
    }, {
      "code": "SI-208",
      "name": "Log-Dragomer",
      "short_code": "208"
    }, {
      "code": "SI-209",
      "name": "Rečica ob Savinji",
      "short_code": "209"
    }, {
      "code": "SI-210",
      "name": "Sveti Jurij v Slovenskih Goricah",
      "short_code": "210"
    }, {
      "code": "SI-211",
      "name": "Šentrupert",
      "short_code": "211"
    }, {
      "code": "SI-212",
      "name": "Mirna",
      "short_code": "212"
    }, {
      "code": "SI-213",
      "name": "Ancarano",
      "short_code": "213"
    }]
  }, {
    "code": "ESP",
    "name": "Spain",
    "subdivisions": [{
      "code": "ES-AN",
      "name": "Andalucía",
      "short_code": "AN"
    }, {
      "code": "ES-AL",
      "name": "Almería",
      "short_code": "AL"
    }, {
      "code": "ES-CA",
      "name": "Cádiz",
      "short_code": "CA"
    }, {
      "code": "ES-CO",
      "name": "Córdoba",
      "short_code": "CO"
    }, {
      "code": "ES-GR",
      "name": "Granada",
      "short_code": "GR"
    }, {
      "code": "ES-H",
      "name": "Huelva",
      "short_code": "H"
    }, {
      "code": "ES-J",
      "name": "Jaén",
      "short_code": "J"
    }, {
      "code": "ES-MA",
      "name": "Málaga",
      "short_code": "MA"
    }, {
      "code": "ES-SE",
      "name": "Sevilla",
      "short_code": "SE"
    }, {
      "code": "ES-AR",
      "name": "Aragón",
      "short_code": "AR"
    }, {
      "code": "ES-HU",
      "name": "Huesca",
      "short_code": "HU"
    }, {
      "code": "ES-TE",
      "name": "Teruel",
      "short_code": "TE"
    }, {
      "code": "ES-Z",
      "name": "Zaragoza",
      "short_code": "Z"
    }, {
      "code": "ES-AS",
      "name": "Asturias, Principado de",
      "short_code": "AS"
    }, {
      "code": "ES-O",
      "name": "Asturias",
      "short_code": "O"
    }, {
      "code": "ES-CB",
      "name": "Cantabria",
      "short_code": "CB"
    }, {
      "code": "ES-S",
      "name": "Cantabria",
      "short_code": "S"
    }, {
      "code": "ES-CL",
      "name": "Castilla y León",
      "short_code": "CL"
    }, {
      "code": "ES-AV",
      "name": "Ávila",
      "short_code": "AV"
    }, {
      "code": "ES-BU",
      "name": "Burgos",
      "short_code": "BU"
    }, {
      "code": "ES-LE",
      "name": "León",
      "short_code": "LE"
    }, {
      "code": "ES-P",
      "name": "Palencia",
      "short_code": "P"
    }, {
      "code": "ES-SA",
      "name": "Salamanca",
      "short_code": "SA"
    }, {
      "code": "ES-SG",
      "name": "Segovia",
      "short_code": "SG"
    }, {
      "code": "ES-SO",
      "name": "Soria",
      "short_code": "SO"
    }, {
      "code": "ES-VA",
      "name": "Valladolid",
      "short_code": "VA"
    }, {
      "code": "ES-ZA",
      "name": "Zamora",
      "short_code": "ZA"
    }, {
      "code": "ES-CM",
      "name": "Castilla-La Mancha",
      "short_code": "CM"
    }, {
      "code": "ES-AB",
      "name": "Albacete",
      "short_code": "AB"
    }, {
      "code": "ES-CR",
      "name": "Ciudad Real",
      "short_code": "CR"
    }, {
      "code": "ES-CU",
      "name": "Cuenca",
      "short_code": "CU"
    }, {
      "code": "ES-GU",
      "name": "Guadalajara",
      "short_code": "GU"
    }, {
      "code": "ES-TO",
      "name": "Toledo",
      "short_code": "TO"
    }, {
      "code": "ES-CN",
      "name": "Canarias",
      "short_code": "CN"
    }, {
      "code": "ES-GC",
      "name": "Las Palmas",
      "short_code": "GC"
    }, {
      "code": "ES-TF",
      "name": "Santa Cruz de Tenerife",
      "short_code": "TF"
    }, {
      "code": "ES-CT",
      "name": "Catalunya [Cataluña]",
      "short_code": "CT"
    }, {
      "code": "ES-B",
      "name": "Barcelona [Barcelona]",
      "short_code": "B"
    }, {
      "code": "ES-GI",
      "name": "Girona [Gerona]",
      "short_code": "GI"
    }, {
      "code": "ES-L",
      "name": "Lleida [Lérida]",
      "short_code": "L"
    }, {
      "code": "ES-T",
      "name": "Tarragona [Tarragona]",
      "short_code": "T"
    }, {
      "code": "ES-EX",
      "name": "Extremadura",
      "short_code": "EX"
    }, {
      "code": "ES-BA",
      "name": "Badajoz",
      "short_code": "BA"
    }, {
      "code": "ES-CC",
      "name": "Cáceres",
      "short_code": "CC"
    }, {
      "code": "ES-GA",
      "name": "Galicia [Galicia]",
      "short_code": "GA"
    }, {
      "code": "ES-C",
      "name": "A Coruña [La Coruña]",
      "short_code": "C"
    }, {
      "code": "ES-LU",
      "name": "Lugo [Lugo]",
      "short_code": "LU"
    }, {
      "code": "ES-OR",
      "name": "Ourense [Orense]",
      "short_code": "OR"
    }, {
      "code": "ES-PO",
      "name": "Pontevedra [Pontevedra]",
      "short_code": "PO"
    }, {
      "code": "ES-IB",
      "name": "Illes Balears [Islas Baleares]",
      "short_code": "IB"
    }, {
      "code": "ES-PM",
      "name": "Balears [Baleares]",
      "short_code": "PM"
    }, {
      "code": "ES-MC",
      "name": "Murcia, Región de",
      "short_code": "MC"
    }, {
      "code": "ES-MU",
      "name": "Murcia",
      "short_code": "MU"
    }, {
      "code": "ES-MD",
      "name": "Madrid, Comunidad de",
      "short_code": "MD"
    }, {
      "code": "ES-M",
      "name": "Madrid",
      "short_code": "M"
    }, {
      "code": "ES-NC",
      "name": "Nafarroako Foru Komunitatea*",
      "short_code": "NC"
    }, {
      "code": "ES-NA",
      "name": "Nafarroa*",
      "short_code": "NA"
    }, {
      "code": "ES-PV",
      "name": "Euskal Herria",
      "short_code": "PV"
    }, {
      "code": "ES-BI",
      "name": "Bizkaia",
      "short_code": "BI"
    }, {
      "code": "ES-SS",
      "name": "Gipuzkoa",
      "short_code": "SS"
    }, {
      "code": "ES-VI",
      "name": "Araba*",
      "short_code": "VI"
    }, {
      "code": "ES-RI",
      "name": "La Rioja",
      "short_code": "RI"
    }, {
      "code": "ES-LO",
      "name": "La Rioja",
      "short_code": "LO"
    }, {
      "code": "ES-VC",
      "name": "Valenciana, Comunitat*",
      "short_code": "VC"
    }, {
      "code": "ES-A",
      "name": "Alacant*",
      "short_code": "A"
    }, {
      "code": "ES-CS",
      "name": "Castelló*",
      "short_code": "CS"
    }, {
      "code": "ES-V",
      "name": "València*",
      "short_code": "V"
    }, {
      "code": "ES-CE",
      "name": "Ceuta",
      "short_code": "CE"
    }, {
      "code": "ES-ML",
      "name": "Melilla",
      "short_code": "ML"
    }]
  }, {
    "code": "SWE",
    "name": "Sweden",
    "subdivisions": [{
      "code": "SE-AB",
      "name": "Stockholms län [SE-01]",
      "short_code": "AB"
    }, {
      "code": "SE-AC",
      "name": "Västerbottens län [SE-24]",
      "short_code": "AC"
    }, {
      "code": "SE-BD",
      "name": "Norrbottens län [SE-25]",
      "short_code": "BD"
    }, {
      "code": "SE-C",
      "name": "Uppsala län [SE-03]",
      "short_code": "C"
    }, {
      "code": "SE-D",
      "name": "Södermanlands län [SE-04]",
      "short_code": "D"
    }, {
      "code": "SE-E",
      "name": "Östergötlands län [SE-05]",
      "short_code": "E"
    }, {
      "code": "SE-F",
      "name": "Jönköpings län [SE-06]",
      "short_code": "F"
    }, {
      "code": "SE-G",
      "name": "Kronobergs län [SE-07]",
      "short_code": "G"
    }, {
      "code": "SE-H",
      "name": "Kalmar län [SE-08]",
      "short_code": "H"
    }, {
      "code": "SE-I",
      "name": "Gotlands län [SE-09]",
      "short_code": "I"
    }, {
      "code": "SE-K",
      "name": "Blekinge län [SE-10]",
      "short_code": "K"
    }, {
      "code": "SE-M",
      "name": "Skåne län [SE-12]",
      "short_code": "M"
    }, {
      "code": "SE-N",
      "name": "Hallands län [SE-13]",
      "short_code": "N"
    }, {
      "code": "SE-O",
      "name": "Västra Götalands län [SE-14]",
      "short_code": "O"
    }, {
      "code": "SE-S",
      "name": "Värmlands län [SE-17]",
      "short_code": "S"
    }, {
      "code": "SE-T",
      "name": "Örebro län [SE-18]",
      "short_code": "T"
    }, {
      "code": "SE-U",
      "name": "Västmanlands län [SE-19]",
      "short_code": "U"
    }, {
      "code": "SE-W",
      "name": "Dalarnas län [SE-20]",
      "short_code": "W"
    }, {
      "code": "SE-X",
      "name": "Gävleborgs län [SE-21]",
      "short_code": "X"
    }, {
      "code": "SE-Y",
      "name": "Västernorrlands län [SE-22]",
      "short_code": "Y"
    }, {
      "code": "SE-Z",
      "name": "Jämtlands län [SE-23]",
      "short_code": "Z"
    }]
  }, {
    "code": "CHE",
    "name": "Switzerland",
    "subdivisions": [{
      "code": "CH-AG",
      "name": "Aargau",
      "short_code": "AG"
    }, {
      "code": "CH-AI",
      "name": "Appenzell Innerrhoden",
      "short_code": "AI"
    }, {
      "code": "CH-AR",
      "name": "Appenzell Ausserrhoden",
      "short_code": "AR"
    }, {
      "code": "CH-BE",
      "name": "Bern",
      "short_code": "BE"
    }, {
      "code": "CH-BL",
      "name": "Basel-Landschaft",
      "short_code": "BL"
    }, {
      "code": "CH-BS",
      "name": "Basel-Stadt",
      "short_code": "BS"
    }, {
      "code": "CH-FR",
      "name": "Freiburg",
      "short_code": "FR"
    }, {
      "code": "CH-GE",
      "name": "Genève",
      "short_code": "GE"
    }, {
      "code": "CH-GL",
      "name": "Glarus",
      "short_code": "GL"
    }, {
      "code": "CH-GR",
      "name": "Graubünden",
      "short_code": "GR"
    }, {
      "code": "CH-JU",
      "name": "Jura",
      "short_code": "JU"
    }, {
      "code": "CH-LU",
      "name": "Luzern",
      "short_code": "LU"
    }, {
      "code": "CH-NE",
      "name": "Neuchâtel",
      "short_code": "NE"
    }, {
      "code": "CH-NW",
      "name": "Nidwalden",
      "short_code": "NW"
    }, {
      "code": "CH-OW",
      "name": "Obwalden",
      "short_code": "OW"
    }, {
      "code": "CH-SG",
      "name": "Sankt Gallen",
      "short_code": "SG"
    }, {
      "code": "CH-SH",
      "name": "Schaffhausen",
      "short_code": "SH"
    }, {
      "code": "CH-SO",
      "name": "Solothurn",
      "short_code": "SO"
    }, {
      "code": "CH-SZ",
      "name": "Schwyz",
      "short_code": "SZ"
    }, {
      "code": "CH-TG",
      "name": "Thurgau",
      "short_code": "TG"
    }, {
      "code": "CH-TI",
      "name": "Ticino",
      "short_code": "TI"
    }, {
      "code": "CH-UR",
      "name": "Uri",
      "short_code": "UR"
    }, {
      "code": "CH-VD",
      "name": "Vaud",
      "short_code": "VD"
    }, {
      "code": "CH-VS",
      "name": "Wallis",
      "short_code": "VS"
    }, {
      "code": "CH-ZG",
      "name": "Zug",
      "short_code": "ZG"
    }, {
      "code": "CH-ZH",
      "name": "Zürich",
      "short_code": "ZH"
    }]
  }, {
    "code": "TWN",
    "name": "Taiwan",
    "subdivisions": [{
      "code": "TW-CYI",
      "name": "Chiayi",
      "short_code": "CYI"
    }, {
      "code": "TW-HSZ",
      "name": "Hsinchu",
      "short_code": "HSZ"
    }, {
      "code": "TW-KEE",
      "name": "Keelung",
      "short_code": "KEE"
    }, {
      "code": "TW-KHH",
      "name": "Kaohsiung",
      "short_code": "KHH"
    }, {
      "code": "TW-NWT",
      "name": "New Taipei",
      "short_code": "NWT"
    }, {
      "code": "TW-TAO",
      "name": "Taoyuan",
      "short_code": "TAO"
    }, {
      "code": "TW-TNN",
      "name": "Tainan",
      "short_code": "TNN"
    }, {
      "code": "TW-TPE",
      "name": "Taipei",
      "short_code": "TPE"
    }, {
      "code": "TW-TXG",
      "name": "Taichung",
      "short_code": "TXG"
    }, {
      "code": "TW-CHA",
      "name": "Changhua",
      "short_code": "CHA"
    }, {
      "code": "TW-CYQ",
      "name": "Chiayi",
      "short_code": "CYQ"
    }, {
      "code": "TW-HSQ",
      "name": "Hsinchu",
      "short_code": "HSQ"
    }, {
      "code": "TW-HUA",
      "name": "Hualien",
      "short_code": "HUA"
    }, {
      "code": "TW-ILA",
      "name": "Yilan",
      "short_code": "ILA"
    }, {
      "code": "TW-KIN",
      "name": "Kinmen",
      "short_code": "KIN"
    }, {
      "code": "TW-LIE",
      "name": "Lienchiang",
      "short_code": "LIE"
    }, {
      "code": "TW-MIA",
      "name": "Miaoli",
      "short_code": "MIA"
    }, {
      "code": "TW-NAN",
      "name": "Nantou",
      "short_code": "NAN"
    }, {
      "code": "TW-PEN",
      "name": "Penghu",
      "short_code": "PEN"
    }, {
      "code": "TW-PIF",
      "name": "Pingtung",
      "short_code": "PIF"
    }, {
      "code": "TW-TTT",
      "name": "Taitung",
      "short_code": "TTT"
    }, {
      "code": "TW-YUN",
      "name": "Yunlin",
      "short_code": "YUN"
    }]
  }, {
    "code": "THA",
    "name": "Thailand",
    "subdivisions": [{
      "code": "TH-10",
      "name": "Bangkok",
      "short_code": "10"
    }, {
      "code": "TH-11",
      "name": "Samut Prakan",
      "short_code": "11"
    }, {
      "code": "TH-12",
      "name": "Nonthaburi",
      "short_code": "12"
    }, {
      "code": "TH-13",
      "name": "Pathum Thani",
      "short_code": "13"
    }, {
      "code": "TH-14",
      "name": "Phra Nakhon Si Ayutthaya",
      "short_code": "14"
    }, {
      "code": "TH-15",
      "name": "Ang Thong",
      "short_code": "15"
    }, {
      "code": "TH-16",
      "name": "Lop Buri",
      "short_code": "16"
    }, {
      "code": "TH-17",
      "name": "Sing Buri",
      "short_code": "17"
    }, {
      "code": "TH-18",
      "name": "Chai Nat",
      "short_code": "18"
    }, {
      "code": "TH-19",
      "name": "Saraburi",
      "short_code": "19"
    }, {
      "code": "TH-20",
      "name": "Chon Buri",
      "short_code": "20"
    }, {
      "code": "TH-21",
      "name": "Rayong",
      "short_code": "21"
    }, {
      "code": "TH-22",
      "name": "Chanthaburi",
      "short_code": "22"
    }, {
      "code": "TH-23",
      "name": "Trat",
      "short_code": "23"
    }, {
      "code": "TH-24",
      "name": "Chachoengsao",
      "short_code": "24"
    }, {
      "code": "TH-25",
      "name": "Prachin Buri",
      "short_code": "25"
    }, {
      "code": "TH-26",
      "name": "Nakhon Nayok",
      "short_code": "26"
    }, {
      "code": "TH-27",
      "name": "Sa Kaeo",
      "short_code": "27"
    }, {
      "code": "TH-30",
      "name": "Nakhon Ratchasima",
      "short_code": "30"
    }, {
      "code": "TH-31",
      "name": "Buri Ram",
      "short_code": "31"
    }, {
      "code": "TH-32",
      "name": "Surin",
      "short_code": "32"
    }, {
      "code": "TH-33",
      "name": "Si Sa Ket",
      "short_code": "33"
    }, {
      "code": "TH-34",
      "name": "Ubon Ratchathani",
      "short_code": "34"
    }, {
      "code": "TH-35",
      "name": "Yasothon",
      "short_code": "35"
    }, {
      "code": "TH-36",
      "name": "Chaiyaphum",
      "short_code": "36"
    }, {
      "code": "TH-37",
      "name": "Amnat Charoen",
      "short_code": "37"
    }, {
      "code": "TH-38",
      "name": "Bueng Kan",
      "short_code": "38"
    }, {
      "code": "TH-39",
      "name": "Nong Bua Lam Phu",
      "short_code": "39"
    }, {
      "code": "TH-40",
      "name": "Khon Kaen",
      "short_code": "40"
    }, {
      "code": "TH-41",
      "name": "Udon Thani",
      "short_code": "41"
    }, {
      "code": "TH-42",
      "name": "Loei",
      "short_code": "42"
    }, {
      "code": "TH-43",
      "name": "Nong Khai",
      "short_code": "43"
    }, {
      "code": "TH-44",
      "name": "Maha Sarakham",
      "short_code": "44"
    }, {
      "code": "TH-45",
      "name": "Roi Et",
      "short_code": "45"
    }, {
      "code": "TH-46",
      "name": "Kalasin",
      "short_code": "46"
    }, {
      "code": "TH-47",
      "name": "Sakon Nakhon",
      "short_code": "47"
    }, {
      "code": "TH-48",
      "name": "Nakhon Phanom",
      "short_code": "48"
    }, {
      "code": "TH-49",
      "name": "Mukdahan",
      "short_code": "49"
    }, {
      "code": "TH-50",
      "name": "Chiang Mai",
      "short_code": "50"
    }, {
      "code": "TH-51",
      "name": "Lamphun",
      "short_code": "51"
    }, {
      "code": "TH-52",
      "name": "Lampang",
      "short_code": "52"
    }, {
      "code": "TH-53",
      "name": "Uttaradit",
      "short_code": "53"
    }, {
      "code": "TH-54",
      "name": "Phrae",
      "short_code": "54"
    }, {
      "code": "TH-55",
      "name": "Nan",
      "short_code": "55"
    }, {
      "code": "TH-56",
      "name": "Phayao",
      "short_code": "56"
    }, {
      "code": "TH-57",
      "name": "Chiang Rai",
      "short_code": "57"
    }, {
      "code": "TH-58",
      "name": "Mae Hong Son",
      "short_code": "58"
    }, {
      "code": "TH-60",
      "name": "Nakhon Sawan",
      "short_code": "60"
    }, {
      "code": "TH-61",
      "name": "Uthai Thani",
      "short_code": "61"
    }, {
      "code": "TH-62",
      "name": "Kamphaeng Phet",
      "short_code": "62"
    }, {
      "code": "TH-63",
      "name": "Tak",
      "short_code": "63"
    }, {
      "code": "TH-64",
      "name": "Sukhothai",
      "short_code": "64"
    }, {
      "code": "TH-65",
      "name": "Phitsanulok",
      "short_code": "65"
    }, {
      "code": "TH-66",
      "name": "Phichit",
      "short_code": "66"
    }, {
      "code": "TH-67",
      "name": "Phetchabun",
      "short_code": "67"
    }, {
      "code": "TH-70",
      "name": "Ratchaburi",
      "short_code": "70"
    }, {
      "code": "TH-71",
      "name": "Kanchanaburi",
      "short_code": "71"
    }, {
      "code": "TH-72",
      "name": "Suphan Buri",
      "short_code": "72"
    }, {
      "code": "TH-73",
      "name": "Nakhon Pathom",
      "short_code": "73"
    }, {
      "code": "TH-74",
      "name": "Samut Sakhon",
      "short_code": "74"
    }, {
      "code": "TH-75",
      "name": "Samut Songkhram",
      "short_code": "75"
    }, {
      "code": "TH-76",
      "name": "Phetchaburi",
      "short_code": "76"
    }, {
      "code": "TH-77",
      "name": "Prachuap Khiri Khan",
      "short_code": "77"
    }, {
      "code": "TH-80",
      "name": "Nakhon Si Thammarat",
      "short_code": "80"
    }, {
      "code": "TH-81",
      "name": "Krabi",
      "short_code": "81"
    }, {
      "code": "TH-82",
      "name": "Phangnga",
      "short_code": "82"
    }, {
      "code": "TH-83",
      "name": "Phuket",
      "short_code": "83"
    }, {
      "code": "TH-84",
      "name": "Surat Thani",
      "short_code": "84"
    }, {
      "code": "TH-85",
      "name": "Ranong",
      "short_code": "85"
    }, {
      "code": "TH-86",
      "name": "Chumphon",
      "short_code": "86"
    }, {
      "code": "TH-90",
      "name": "Songkhla",
      "short_code": "90"
    }, {
      "code": "TH-91",
      "name": "Satun",
      "short_code": "91"
    }, {
      "code": "TH-92",
      "name": "Trang",
      "short_code": "92"
    }, {
      "code": "TH-93",
      "name": "Phatthalung",
      "short_code": "93"
    }, {
      "code": "TH-94",
      "name": "Pattani",
      "short_code": "94"
    }, {
      "code": "TH-95",
      "name": "Yala",
      "short_code": "95"
    }, {
      "code": "TH-96",
      "name": "Narathiwat",
      "short_code": "96"
    }, {
      "code": "TH-S",
      "name": "Phatthaya",
      "short_code": "S"
    }]
  }, {
    "code": "TUR",
    "name": "Turkey",
    "subdivisions": [{
      "code": "TR-01",
      "name": "Adana",
      "short_code": "01"
    }, {
      "code": "TR-02",
      "name": "Adıyaman",
      "short_code": "02"
    }, {
      "code": "TR-03",
      "name": "Afyonkarahisar",
      "short_code": "03"
    }, {
      "code": "TR-04",
      "name": "Ağrı",
      "short_code": "04"
    }, {
      "code": "TR-05",
      "name": "Amasya",
      "short_code": "05"
    }, {
      "code": "TR-06",
      "name": "Ankara",
      "short_code": "06"
    }, {
      "code": "TR-07",
      "name": "Antalya",
      "short_code": "07"
    }, {
      "code": "TR-08",
      "name": "Artvin",
      "short_code": "08"
    }, {
      "code": "TR-09",
      "name": "Aydın",
      "short_code": "09"
    }, {
      "code": "TR-10",
      "name": "Balıkesir",
      "short_code": "10"
    }, {
      "code": "TR-11",
      "name": "Bilecik",
      "short_code": "11"
    }, {
      "code": "TR-12",
      "name": "Bingöl",
      "short_code": "12"
    }, {
      "code": "TR-13",
      "name": "Bitlis",
      "short_code": "13"
    }, {
      "code": "TR-14",
      "name": "Bolu",
      "short_code": "14"
    }, {
      "code": "TR-15",
      "name": "Burdur",
      "short_code": "15"
    }, {
      "code": "TR-16",
      "name": "Bursa",
      "short_code": "16"
    }, {
      "code": "TR-17",
      "name": "Çanakkale",
      "short_code": "17"
    }, {
      "code": "TR-18",
      "name": "Çankırı",
      "short_code": "18"
    }, {
      "code": "TR-19",
      "name": "Çorum",
      "short_code": "19"
    }, {
      "code": "TR-20",
      "name": "Denizli",
      "short_code": "20"
    }, {
      "code": "TR-21",
      "name": "Diyarbakır",
      "short_code": "21"
    }, {
      "code": "TR-22",
      "name": "Edirne",
      "short_code": "22"
    }, {
      "code": "TR-23",
      "name": "Elazığ",
      "short_code": "23"
    }, {
      "code": "TR-24",
      "name": "Erzincan",
      "short_code": "24"
    }, {
      "code": "TR-25",
      "name": "Erzurum",
      "short_code": "25"
    }, {
      "code": "TR-26",
      "name": "Eskişehir",
      "short_code": "26"
    }, {
      "code": "TR-27",
      "name": "Gaziantep",
      "short_code": "27"
    }, {
      "code": "TR-28",
      "name": "Giresun",
      "short_code": "28"
    }, {
      "code": "TR-29",
      "name": "Gümüşhane",
      "short_code": "29"
    }, {
      "code": "TR-30",
      "name": "Hakkâri",
      "short_code": "30"
    }, {
      "code": "TR-31",
      "name": "Hatay",
      "short_code": "31"
    }, {
      "code": "TR-32",
      "name": "Isparta",
      "short_code": "32"
    }, {
      "code": "TR-33",
      "name": "Mersin",
      "short_code": "33"
    }, {
      "code": "TR-34",
      "name": "İstanbul",
      "short_code": "34"
    }, {
      "code": "TR-35",
      "name": "İzmir",
      "short_code": "35"
    }, {
      "code": "TR-36",
      "name": "Kars",
      "short_code": "36"
    }, {
      "code": "TR-37",
      "name": "Kastamonu",
      "short_code": "37"
    }, {
      "code": "TR-38",
      "name": "Kayseri",
      "short_code": "38"
    }, {
      "code": "TR-39",
      "name": "Kırklareli",
      "short_code": "39"
    }, {
      "code": "TR-40",
      "name": "Kırşehir",
      "short_code": "40"
    }, {
      "code": "TR-41",
      "name": "Kocaeli",
      "short_code": "41"
    }, {
      "code": "TR-42",
      "name": "Konya",
      "short_code": "42"
    }, {
      "code": "TR-43",
      "name": "Kütahya",
      "short_code": "43"
    }, {
      "code": "TR-44",
      "name": "Malatya",
      "short_code": "44"
    }, {
      "code": "TR-45",
      "name": "Manisa",
      "short_code": "45"
    }, {
      "code": "TR-46",
      "name": "Kahramanmaraş",
      "short_code": "46"
    }, {
      "code": "TR-47",
      "name": "Mardin",
      "short_code": "47"
    }, {
      "code": "TR-48",
      "name": "Muğla",
      "short_code": "48"
    }, {
      "code": "TR-49",
      "name": "Muş",
      "short_code": "49"
    }, {
      "code": "TR-50",
      "name": "Nevşehir",
      "short_code": "50"
    }, {
      "code": "TR-51",
      "name": "Niğde",
      "short_code": "51"
    }, {
      "code": "TR-52",
      "name": "Ordu",
      "short_code": "52"
    }, {
      "code": "TR-53",
      "name": "Rize",
      "short_code": "53"
    }, {
      "code": "TR-54",
      "name": "Sakarya",
      "short_code": "54"
    }, {
      "code": "TR-55",
      "name": "Samsun",
      "short_code": "55"
    }, {
      "code": "TR-56",
      "name": "Siirt",
      "short_code": "56"
    }, {
      "code": "TR-57",
      "name": "Sinop",
      "short_code": "57"
    }, {
      "code": "TR-58",
      "name": "Sivas",
      "short_code": "58"
    }, {
      "code": "TR-59",
      "name": "Tekirdağ",
      "short_code": "59"
    }, {
      "code": "TR-60",
      "name": "Tokat",
      "short_code": "60"
    }, {
      "code": "TR-61",
      "name": "Trabzon",
      "short_code": "61"
    }, {
      "code": "TR-62",
      "name": "Tunceli",
      "short_code": "62"
    }, {
      "code": "TR-63",
      "name": "Şanlıurfa",
      "short_code": "63"
    }, {
      "code": "TR-64",
      "name": "Uşak",
      "short_code": "64"
    }, {
      "code": "TR-65",
      "name": "Van",
      "short_code": "65"
    }, {
      "code": "TR-66",
      "name": "Yozgat",
      "short_code": "66"
    }, {
      "code": "TR-67",
      "name": "Zonguldak",
      "short_code": "67"
    }, {
      "code": "TR-68",
      "name": "Aksaray",
      "short_code": "68"
    }, {
      "code": "TR-69",
      "name": "Bayburt",
      "short_code": "69"
    }, {
      "code": "TR-70",
      "name": "Karaman",
      "short_code": "70"
    }, {
      "code": "TR-71",
      "name": "Kırıkkale",
      "short_code": "71"
    }, {
      "code": "TR-72",
      "name": "Batman",
      "short_code": "72"
    }, {
      "code": "TR-73",
      "name": "Şırnak",
      "short_code": "73"
    }, {
      "code": "TR-74",
      "name": "Bartın",
      "short_code": "74"
    }, {
      "code": "TR-75",
      "name": "Ardahan",
      "short_code": "75"
    }, {
      "code": "TR-76",
      "name": "Iğdır",
      "short_code": "76"
    }, {
      "code": "TR-77",
      "name": "Yalova",
      "short_code": "77"
    }, {
      "code": "TR-78",
      "name": "Karabük",
      "short_code": "78"
    }, {
      "code": "TR-79",
      "name": "Kilis",
      "short_code": "79"
    }, {
      "code": "TR-80",
      "name": "Osmaniye",
      "short_code": "80"
    }, {
      "code": "TR-81",
      "name": "Düzce",
      "short_code": "81"
    }]
  }, {
    "code": "ARE",
    "name": "United Arab Emirates",
    "subdivisions": [{
      "code": "AE-AJ",
      "name": "‘Ajmān",
      "short_code": "AJ"
    }, {
      "code": "AE-AZ",
      "name": "Abu Dhabi",
      "short_code": "AZ"
    }, {
      "code": "AE-DU",
      "name": "Dubai",
      "short_code": "DU"
    }, {
      "code": "AE-FU",
      "name": "Fujairah",
      "short_code": "FU"
    }, {
      "code": "AE-RK",
      "name": "Ras Al Khaimah",
      "short_code": "RK"
    }, {
      "code": "AE-SH",
      "name": "Sharjah",
      "short_code": "SH"
    }, {
      "code": "AE-UQ",
      "name": "Umm Al Quwain",
      "short_code": "UQ"
    }]
  }, {
    "code": "GBR",
    "name": "United Kingdom",
    "subdivisions": [{
      "code": "GB-EAW",
      "name": "England and Wales",
      "short_code": "EAW"
    }, {
      "code": "GB-GBN",
      "name": "Great Britain",
      "short_code": "GBN"
    }, {
      "code": "GB-UKM",
      "name": "United Kingdom",
      "short_code": "UKM"
    }, {
      "code": "GB-NIR",
      "name": "Northern Ireland",
      "short_code": "NIR"
    }, {
      "code": "GB-ABC",
      "name": "Armagh, Banbridge and Craigavon",
      "short_code": "ABC"
    }, {
      "code": "GB-AND",
      "name": "Ards and North Down",
      "short_code": "AND"
    }, {
      "code": "GB-ANN",
      "name": "Antrim and Newtownabbey",
      "short_code": "ANN"
    }, {
      "code": "GB-BFS",
      "name": "Belfast",
      "short_code": "BFS"
    }, {
      "code": "GB-CCG",
      "name": "Causeway Coast and Glens",
      "short_code": "CCG"
    }, {
      "code": "GB-DRS",
      "name": "Derry and Strabane",
      "short_code": "DRS"
    }, {
      "code": "GB-FMO",
      "name": "Fermanagh and Omagh",
      "short_code": "FMO"
    }, {
      "code": "GB-LBC",
      "name": "Lisburn and Castlereagh",
      "short_code": "LBC"
    }, {
      "code": "GB-MEA",
      "name": "Mid and East Antrim",
      "short_code": "MEA"
    }, {
      "code": "GB-MUL",
      "name": "Mid Ulster",
      "short_code": "MUL"
    }, {
      "code": "GB-NMD",
      "name": "Newry, Mourne and Down",
      "short_code": "NMD"
    }, {
      "code": "GB-ENG",
      "name": "England",
      "short_code": "ENG"
    }, {
      "code": "GB-BKM",
      "name": "Buckinghamshire",
      "short_code": "BKM"
    }, {
      "code": "GB-CAM",
      "name": "Cambridgeshire",
      "short_code": "CAM"
    }, {
      "code": "GB-CMA",
      "name": "Cumbria",
      "short_code": "CMA"
    }, {
      "code": "GB-DBY",
      "name": "Derbyshire",
      "short_code": "DBY"
    }, {
      "code": "GB-DEV",
      "name": "Devon",
      "short_code": "DEV"
    }, {
      "code": "GB-DOR",
      "name": "Dorset",
      "short_code": "DOR"
    }, {
      "code": "GB-ESS",
      "name": "Essex",
      "short_code": "ESS"
    }, {
      "code": "GB-ESX",
      "name": "East Sussex",
      "short_code": "ESX"
    }, {
      "code": "GB-GLS",
      "name": "Gloucestershire",
      "short_code": "GLS"
    }, {
      "code": "GB-HAM",
      "name": "Hampshire",
      "short_code": "HAM"
    }, {
      "code": "GB-HRT",
      "name": "Hertfordshire",
      "short_code": "HRT"
    }, {
      "code": "GB-KEN",
      "name": "Kent",
      "short_code": "KEN"
    }, {
      "code": "GB-LAN",
      "name": "Lancashire",
      "short_code": "LAN"
    }, {
      "code": "GB-LEC",
      "name": "Leicestershire",
      "short_code": "LEC"
    }, {
      "code": "GB-LIN",
      "name": "Lincolnshire",
      "short_code": "LIN"
    }, {
      "code": "GB-NFK",
      "name": "Norfolk",
      "short_code": "NFK"
    }, {
      "code": "GB-NTH",
      "name": "Northamptonshire",
      "short_code": "NTH"
    }, {
      "code": "GB-NTT",
      "name": "Nottinghamshire",
      "short_code": "NTT"
    }, {
      "code": "GB-NYK",
      "name": "North Yorkshire",
      "short_code": "NYK"
    }, {
      "code": "GB-OXF",
      "name": "Oxfordshire",
      "short_code": "OXF"
    }, {
      "code": "GB-SFK",
      "name": "Suffolk",
      "short_code": "SFK"
    }, {
      "code": "GB-SOM",
      "name": "Somerset",
      "short_code": "SOM"
    }, {
      "code": "GB-SRY",
      "name": "Surrey",
      "short_code": "SRY"
    }, {
      "code": "GB-STS",
      "name": "Staffordshire",
      "short_code": "STS"
    }, {
      "code": "GB-WAR",
      "name": "Warwickshire",
      "short_code": "WAR"
    }, {
      "code": "GB-WOR",
      "name": "Worcestershire",
      "short_code": "WOR"
    }, {
      "code": "GB-WSX",
      "name": "West Sussex",
      "short_code": "WSX"
    }, {
      "code": "GB-BAS",
      "name": "Bath and North East Somerset",
      "short_code": "BAS"
    }, {
      "code": "GB-BBD",
      "name": "Blackburn with Darwen",
      "short_code": "BBD"
    }, {
      "code": "GB-BDF",
      "name": "Bedford",
      "short_code": "BDF"
    }, {
      "code": "GB-BMH",
      "name": "Bournemouth",
      "short_code": "BMH"
    }, {
      "code": "GB-BNH",
      "name": "Brighton and Hove",
      "short_code": "BNH"
    }, {
      "code": "GB-BPL",
      "name": "Blackpool",
      "short_code": "BPL"
    }, {
      "code": "GB-BRC",
      "name": "Bracknell Forest",
      "short_code": "BRC"
    }, {
      "code": "GB-BST",
      "name": "Bristol, City of",
      "short_code": "BST"
    }, {
      "code": "GB-CBF",
      "name": "Central Bedfordshire",
      "short_code": "CBF"
    }, {
      "code": "GB-CHE",
      "name": "Cheshire East",
      "short_code": "CHE"
    }, {
      "code": "GB-CHW",
      "name": "Cheshire West and Chester",
      "short_code": "CHW"
    }, {
      "code": "GB-CON",
      "name": "Cornwall",
      "short_code": "CON"
    }, {
      "code": "GB-DAL",
      "name": "Darlington",
      "short_code": "DAL"
    }, {
      "code": "GB-DER",
      "name": "Derby",
      "short_code": "DER"
    }, {
      "code": "GB-DUR",
      "name": "Durham County",
      "short_code": "DUR"
    }, {
      "code": "GB-ERY",
      "name": "East Riding of Yorkshire",
      "short_code": "ERY"
    }, {
      "code": "GB-HAL",
      "name": "Halton",
      "short_code": "HAL"
    }, {
      "code": "GB-HEF",
      "name": "Herefordshire",
      "short_code": "HEF"
    }, {
      "code": "GB-HPL",
      "name": "Hartlepool",
      "short_code": "HPL"
    }, {
      "code": "GB-IOS",
      "name": "Isles of Scilly",
      "short_code": "IOS"
    }, {
      "code": "GB-IOW",
      "name": "Isle of Wight",
      "short_code": "IOW"
    }, {
      "code": "GB-KHL",
      "name": "Kingston upon Hull",
      "short_code": "KHL"
    }, {
      "code": "GB-LCE",
      "name": "Leicester",
      "short_code": "LCE"
    }, {
      "code": "GB-LUT",
      "name": "Luton",
      "short_code": "LUT"
    }, {
      "code": "GB-MDB",
      "name": "Middlesbrough",
      "short_code": "MDB"
    }, {
      "code": "GB-MDW",
      "name": "Medway",
      "short_code": "MDW"
    }, {
      "code": "GB-MIK",
      "name": "Milton Keynes",
      "short_code": "MIK"
    }, {
      "code": "GB-NBL",
      "name": "Northumberland",
      "short_code": "NBL"
    }, {
      "code": "GB-NEL",
      "name": "North East Lincolnshire",
      "short_code": "NEL"
    }, {
      "code": "GB-NGM",
      "name": "Nottingham",
      "short_code": "NGM"
    }, {
      "code": "GB-NLN",
      "name": "North Lincolnshire",
      "short_code": "NLN"
    }, {
      "code": "GB-NSM",
      "name": "North Somerset",
      "short_code": "NSM"
    }, {
      "code": "GB-PLY",
      "name": "Plymouth",
      "short_code": "PLY"
    }, {
      "code": "GB-POL",
      "name": "Poole",
      "short_code": "POL"
    }, {
      "code": "GB-POR",
      "name": "Portsmouth",
      "short_code": "POR"
    }, {
      "code": "GB-PTE",
      "name": "Peterborough",
      "short_code": "PTE"
    }, {
      "code": "GB-RCC",
      "name": "Redcar and Cleveland",
      "short_code": "RCC"
    }, {
      "code": "GB-RDG",
      "name": "Reading",
      "short_code": "RDG"
    }, {
      "code": "GB-RUT",
      "name": "Rutland",
      "short_code": "RUT"
    }, {
      "code": "GB-SGC",
      "name": "South Gloucestershire",
      "short_code": "SGC"
    }, {
      "code": "GB-SHR",
      "name": "Shropshire",
      "short_code": "SHR"
    }, {
      "code": "GB-SLG",
      "name": "Slough",
      "short_code": "SLG"
    }, {
      "code": "GB-SOS",
      "name": "Southend-on-Sea",
      "short_code": "SOS"
    }, {
      "code": "GB-STE",
      "name": "Stoke-on-Trent",
      "short_code": "STE"
    }, {
      "code": "GB-STH",
      "name": "Southampton",
      "short_code": "STH"
    }, {
      "code": "GB-STT",
      "name": "Stockton-on-Tees",
      "short_code": "STT"
    }, {
      "code": "GB-SWD",
      "name": "Swindon",
      "short_code": "SWD"
    }, {
      "code": "GB-TFW",
      "name": "Telford and Wrekin",
      "short_code": "TFW"
    }, {
      "code": "GB-THR",
      "name": "Thurrock",
      "short_code": "THR"
    }, {
      "code": "GB-TOB",
      "name": "Torbay",
      "short_code": "TOB"
    }, {
      "code": "GB-WBK",
      "name": "West Berkshire",
      "short_code": "WBK"
    }, {
      "code": "GB-WIL",
      "name": "Wiltshire",
      "short_code": "WIL"
    }, {
      "code": "GB-WNM",
      "name": "Windsor and Maidenhead",
      "short_code": "WNM"
    }, {
      "code": "GB-WOK",
      "name": "Wokingham",
      "short_code": "WOK"
    }, {
      "code": "GB-WRT",
      "name": "Warrington",
      "short_code": "WRT"
    }, {
      "code": "GB-YOR",
      "name": "York",
      "short_code": "YOR"
    }, {
      "code": "GB-BIR",
      "name": "Birmingham",
      "short_code": "BIR"
    }, {
      "code": "GB-BNS",
      "name": "Barnsley",
      "short_code": "BNS"
    }, {
      "code": "GB-BOL",
      "name": "Bolton",
      "short_code": "BOL"
    }, {
      "code": "GB-BRD",
      "name": "Bradford",
      "short_code": "BRD"
    }, {
      "code": "GB-BUR",
      "name": "Bury",
      "short_code": "BUR"
    }, {
      "code": "GB-CLD",
      "name": "Calderdale",
      "short_code": "CLD"
    }, {
      "code": "GB-COV",
      "name": "Coventry",
      "short_code": "COV"
    }, {
      "code": "GB-DNC",
      "name": "Doncaster",
      "short_code": "DNC"
    }, {
      "code": "GB-DUD",
      "name": "Dudley",
      "short_code": "DUD"
    }, {
      "code": "GB-GAT",
      "name": "Gateshead",
      "short_code": "GAT"
    }, {
      "code": "GB-KIR",
      "name": "Kirklees",
      "short_code": "KIR"
    }, {
      "code": "GB-KWL",
      "name": "Knowsley",
      "short_code": "KWL"
    }, {
      "code": "GB-LDS",
      "name": "Leeds",
      "short_code": "LDS"
    }, {
      "code": "GB-LIV",
      "name": "Liverpool",
      "short_code": "LIV"
    }, {
      "code": "GB-MAN",
      "name": "Manchester",
      "short_code": "MAN"
    }, {
      "code": "GB-NET",
      "name": "Newcastle upon Tyne",
      "short_code": "NET"
    }, {
      "code": "GB-NTY",
      "name": "North Tyneside",
      "short_code": "NTY"
    }, {
      "code": "GB-OLD",
      "name": "Oldham",
      "short_code": "OLD"
    }, {
      "code": "GB-RCH",
      "name": "Rochdale",
      "short_code": "RCH"
    }, {
      "code": "GB-ROT",
      "name": "Rotherham",
      "short_code": "ROT"
    }, {
      "code": "GB-SAW",
      "name": "Sandwell",
      "short_code": "SAW"
    }, {
      "code": "GB-SFT",
      "name": "Sefton",
      "short_code": "SFT"
    }, {
      "code": "GB-SHF",
      "name": "Sheffield",
      "short_code": "SHF"
    }, {
      "code": "GB-SHN",
      "name": "St. Helens",
      "short_code": "SHN"
    }, {
      "code": "GB-SKP",
      "name": "Stockport",
      "short_code": "SKP"
    }, {
      "code": "GB-SLF",
      "name": "Salford",
      "short_code": "SLF"
    }, {
      "code": "GB-SND",
      "name": "Sunderland",
      "short_code": "SND"
    }, {
      "code": "GB-SOL",
      "name": "Solihull",
      "short_code": "SOL"
    }, {
      "code": "GB-STY",
      "name": "South Tyneside",
      "short_code": "STY"
    }, {
      "code": "GB-TAM",
      "name": "Tameside",
      "short_code": "TAM"
    }, {
      "code": "GB-TRF",
      "name": "Trafford",
      "short_code": "TRF"
    }, {
      "code": "GB-WGN",
      "name": "Wigan",
      "short_code": "WGN"
    }, {
      "code": "GB-WKF",
      "name": "Wakefield",
      "short_code": "WKF"
    }, {
      "code": "GB-WLL",
      "name": "Walsall",
      "short_code": "WLL"
    }, {
      "code": "GB-WLV",
      "name": "Wolverhampton",
      "short_code": "WLV"
    }, {
      "code": "GB-WRL",
      "name": "Wirral",
      "short_code": "WRL"
    }, {
      "code": "GB-BDG",
      "name": "Barking and Dagenham",
      "short_code": "BDG"
    }, {
      "code": "GB-BEN",
      "name": "Brent",
      "short_code": "BEN"
    }, {
      "code": "GB-BEX",
      "name": "Bexley",
      "short_code": "BEX"
    }, {
      "code": "GB-BNE",
      "name": "Barnet",
      "short_code": "BNE"
    }, {
      "code": "GB-BRY",
      "name": "Bromley",
      "short_code": "BRY"
    }, {
      "code": "GB-CMD",
      "name": "Camden",
      "short_code": "CMD"
    }, {
      "code": "GB-CRY",
      "name": "Croydon",
      "short_code": "CRY"
    }, {
      "code": "GB-EAL",
      "name": "Ealing",
      "short_code": "EAL"
    }, {
      "code": "GB-ENF",
      "name": "Enfield",
      "short_code": "ENF"
    }, {
      "code": "GB-GRE",
      "name": "Greenwich",
      "short_code": "GRE"
    }, {
      "code": "GB-HAV",
      "name": "Havering",
      "short_code": "HAV"
    }, {
      "code": "GB-HCK",
      "name": "Hackney",
      "short_code": "HCK"
    }, {
      "code": "GB-HIL",
      "name": "Hillingdon",
      "short_code": "HIL"
    }, {
      "code": "GB-HMF",
      "name": "Hammersmith and Fulham",
      "short_code": "HMF"
    }, {
      "code": "GB-HNS",
      "name": "Hounslow",
      "short_code": "HNS"
    }, {
      "code": "GB-HRW",
      "name": "Harrow",
      "short_code": "HRW"
    }, {
      "code": "GB-HRY",
      "name": "Haringey",
      "short_code": "HRY"
    }, {
      "code": "GB-ISL",
      "name": "Islington",
      "short_code": "ISL"
    }, {
      "code": "GB-KEC",
      "name": "Kensington and Chelsea",
      "short_code": "KEC"
    }, {
      "code": "GB-KTT",
      "name": "Kingston upon Thames",
      "short_code": "KTT"
    }, {
      "code": "GB-LBH",
      "name": "Lambeth",
      "short_code": "LBH"
    }, {
      "code": "GB-LEW",
      "name": "Lewisham",
      "short_code": "LEW"
    }, {
      "code": "GB-MRT",
      "name": "Merton",
      "short_code": "MRT"
    }, {
      "code": "GB-NWM",
      "name": "Newham",
      "short_code": "NWM"
    }, {
      "code": "GB-RDB",
      "name": "Redbridge",
      "short_code": "RDB"
    }, {
      "code": "GB-RIC",
      "name": "Richmond upon Thames",
      "short_code": "RIC"
    }, {
      "code": "GB-STN",
      "name": "Sutton",
      "short_code": "STN"
    }, {
      "code": "GB-SWK",
      "name": "Southwark",
      "short_code": "SWK"
    }, {
      "code": "GB-TWH",
      "name": "Tower Hamlets",
      "short_code": "TWH"
    }, {
      "code": "GB-WFT",
      "name": "Waltham Forest",
      "short_code": "WFT"
    }, {
      "code": "GB-WND",
      "name": "Wandsworth",
      "short_code": "WND"
    }, {
      "code": "GB-WSM",
      "name": "Westminster",
      "short_code": "WSM"
    }, {
      "code": "GB-LND",
      "name": "London, City of",
      "short_code": "LND"
    }, {
      "code": "GB-SCT",
      "name": "Scotland",
      "short_code": "SCT"
    }, {
      "code": "GB-ABD",
      "name": "Aberdeenshire",
      "short_code": "ABD"
    }, {
      "code": "GB-ABE",
      "name": "Aberdeen City",
      "short_code": "ABE"
    }, {
      "code": "GB-AGB",
      "name": "Argyll and Bute",
      "short_code": "AGB"
    }, {
      "code": "GB-ANS",
      "name": "Angus",
      "short_code": "ANS"
    }, {
      "code": "GB-CLK",
      "name": "Clackmannanshire",
      "short_code": "CLK"
    }, {
      "code": "GB-DGY",
      "name": "Dumfries and Galloway",
      "short_code": "DGY"
    }, {
      "code": "GB-DND",
      "name": "Dundee City",
      "short_code": "DND"
    }, {
      "code": "GB-EAY",
      "name": "East Ayrshire",
      "short_code": "EAY"
    }, {
      "code": "GB-EDH",
      "name": "Edinburgh, City of",
      "short_code": "EDH"
    }, {
      "code": "GB-EDU",
      "name": "East Dunbartonshire",
      "short_code": "EDU"
    }, {
      "code": "GB-ELN",
      "name": "East Lothian",
      "short_code": "ELN"
    }, {
      "code": "GB-ELS",
      "name": "Eilean Siar",
      "short_code": "ELS"
    }, {
      "code": "GB-ERW",
      "name": "East Renfrewshire",
      "short_code": "ERW"
    }, {
      "code": "GB-FAL",
      "name": "Falkirk",
      "short_code": "FAL"
    }, {
      "code": "GB-FIF",
      "name": "Fife",
      "short_code": "FIF"
    }, {
      "code": "GB-GLG",
      "name": "Glasgow City",
      "short_code": "GLG"
    }, {
      "code": "GB-HLD",
      "name": "Highland",
      "short_code": "HLD"
    }, {
      "code": "GB-IVC",
      "name": "Inverclyde",
      "short_code": "IVC"
    }, {
      "code": "GB-MLN",
      "name": "Midlothian",
      "short_code": "MLN"
    }, {
      "code": "GB-MRY",
      "name": "Moray",
      "short_code": "MRY"
    }, {
      "code": "GB-NAY",
      "name": "North Ayrshire",
      "short_code": "NAY"
    }, {
      "code": "GB-NLK",
      "name": "North Lanarkshire",
      "short_code": "NLK"
    }, {
      "code": "GB-ORK",
      "name": "Orkney Islands",
      "short_code": "ORK"
    }, {
      "code": "GB-PKN",
      "name": "Perth and Kinross",
      "short_code": "PKN"
    }, {
      "code": "GB-RFW",
      "name": "Renfrewshire",
      "short_code": "RFW"
    }, {
      "code": "GB-SAY",
      "name": "South Ayrshire",
      "short_code": "SAY"
    }, {
      "code": "GB-SCB",
      "name": "Scottish Borders, The",
      "short_code": "SCB"
    }, {
      "code": "GB-SLK",
      "name": "South Lanarkshire",
      "short_code": "SLK"
    }, {
      "code": "GB-STG",
      "name": "Stirling",
      "short_code": "STG"
    }, {
      "code": "GB-WDU",
      "name": "West Dunbartonshire",
      "short_code": "WDU"
    }, {
      "code": "GB-WLN",
      "name": "West Lothian",
      "short_code": "WLN"
    }, {
      "code": "GB-ZET",
      "name": "Shetland Islands",
      "short_code": "ZET"
    }, {
      "code": "GB-WLS",
      "name": "Wales [Cymru GB-CYM]",
      "short_code": "WLS"
    }, {
      "code": "GB-AGY",
      "name": "Isle of Anglesey [Sir Ynys Môn GB-YNM]",
      "short_code": "AGY"
    }, {
      "code": "GB-BGE",
      "name": "Bridgend [Pen-y-bont ar Ogwr GB-POG]",
      "short_code": "BGE"
    }, {
      "code": "GB-BGW",
      "name": "Blaenau Gwent",
      "short_code": "BGW"
    }, {
      "code": "GB-CAY",
      "name": "Caerphilly [Caerffili GB-CAF]",
      "short_code": "CAY"
    }, {
      "code": "GB-CGN",
      "name": "Ceredigion [Sir Ceredigion]",
      "short_code": "CGN"
    }, {
      "code": "GB-CMN",
      "name": "Carmarthenshire [Sir Gaerfyrddin GB-GFY]",
      "short_code": "CMN"
    }, {
      "code": "GB-CRF",
      "name": "Cardiff [Caerdydd GB-CRD]",
      "short_code": "CRF"
    }, {
      "code": "GB-CWY",
      "name": "Conwy",
      "short_code": "CWY"
    }, {
      "code": "GB-DEN",
      "name": "Denbighshire [Sir Ddinbych GB-DDB]",
      "short_code": "DEN"
    }, {
      "code": "GB-FLN",
      "name": "Flintshire [Sir y Fflint GB-FFL]",
      "short_code": "FLN"
    }, {
      "code": "GB-GWN",
      "name": "Gwynedd",
      "short_code": "GWN"
    }, {
      "code": "GB-MON",
      "name": "Monmouthshire [Sir Fynwy GB-FYN]",
      "short_code": "MON"
    }, {
      "code": "GB-MTY",
      "name": "Merthyr Tydfil [Merthyr Tudful GB-MTU]",
      "short_code": "MTY"
    }, {
      "code": "GB-NTL",
      "name": "Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]",
      "short_code": "NTL"
    }, {
      "code": "GB-NWP",
      "name": "Newport [Casnewydd GB-CNW]",
      "short_code": "NWP"
    }, {
      "code": "GB-PEM",
      "name": "Pembrokeshire [Sir Benfro GB-BNF]",
      "short_code": "PEM"
    }, {
      "code": "GB-POW",
      "name": "Powys",
      "short_code": "POW"
    }, {
      "code": "GB-RCT",
      "name": "Rhondda, Cynon, Taff [Rhondda, Cynon,Taf]",
      "short_code": "RCT"
    }, {
      "code": "GB-SWA",
      "name": "Swansea [Abertawe GB-ATA]",
      "short_code": "SWA"
    }, {
      "code": "GB-TOF",
      "name": "Torfaen [Tor-faen]",
      "short_code": "TOF"
    }, {
      "code": "GB-VGL",
      "name": "Vale of Glamorgan, The [Bro Morgannwg GB-BMG]",
      "short_code": "VGL"
    }, {
      "code": "GB-WRX",
      "name": "Wrexham [Wrecsam GB-WRC]",
      "short_code": "WRX"
    }]
  }, {
    "code": "USA",
    "name": "United States",
    "subdivisions": [{
      "code": "US-DC",
      "name": "District of Columbia",
      "short_code": "DC"
    }, {
      "code": "US-AS",
      "name": "American Samoa",
      "short_code": "AS"
    }, {
      "code": "US-GU",
      "name": "Guam",
      "short_code": "GU"
    }, {
      "code": "US-MP",
      "name": "Northern Mariana Islands",
      "short_code": "MP"
    }, {
      "code": "US-PR",
      "name": "Puerto Rico",
      "short_code": "PR"
    }, {
      "code": "US-UM",
      "name": "United States Minor Outlying Islands",
      "short_code": "UM"
    }, {
      "code": "US-VI",
      "name": "Virgin Islands, U.S.",
      "short_code": "VI"
    }, {
      "code": "US-AK",
      "name": "Alaska",
      "short_code": "AK"
    }, {
      "code": "US-AL",
      "name": "Alabama",
      "short_code": "AL"
    }, {
      "code": "US-AR",
      "name": "Arkansas",
      "short_code": "AR"
    }, {
      "code": "US-AZ",
      "name": "Arizona",
      "short_code": "AZ"
    }, {
      "code": "US-CA",
      "name": "California",
      "short_code": "CA"
    }, {
      "code": "US-CO",
      "name": "Colorado",
      "short_code": "CO"
    }, {
      "code": "US-CT",
      "name": "Connecticut",
      "short_code": "CT"
    }, {
      "code": "US-DE",
      "name": "Delaware",
      "short_code": "DE"
    }, {
      "code": "US-FL",
      "name": "Florida",
      "short_code": "FL"
    }, {
      "code": "US-GA",
      "name": "Georgia",
      "short_code": "GA"
    }, {
      "code": "US-HI",
      "name": "Hawaii",
      "short_code": "HI"
    }, {
      "code": "US-IA",
      "name": "Iowa",
      "short_code": "IA"
    }, {
      "code": "US-ID",
      "name": "Idaho",
      "short_code": "ID"
    }, {
      "code": "US-IL",
      "name": "Illinois",
      "short_code": "IL"
    }, {
      "code": "US-IN",
      "name": "Indiana",
      "short_code": "IN"
    }, {
      "code": "US-KS",
      "name": "Kansas",
      "short_code": "KS"
    }, {
      "code": "US-KY",
      "name": "Kentucky",
      "short_code": "KY"
    }, {
      "code": "US-LA",
      "name": "Louisiana",
      "short_code": "LA"
    }, {
      "code": "US-MA",
      "name": "Massachusetts",
      "short_code": "MA"
    }, {
      "code": "US-MD",
      "name": "Maryland",
      "short_code": "MD"
    }, {
      "code": "US-ME",
      "name": "Maine",
      "short_code": "ME"
    }, {
      "code": "US-MI",
      "name": "Michigan",
      "short_code": "MI"
    }, {
      "code": "US-MN",
      "name": "Minnesota",
      "short_code": "MN"
    }, {
      "code": "US-MO",
      "name": "Missouri",
      "short_code": "MO"
    }, {
      "code": "US-MS",
      "name": "Mississippi",
      "short_code": "MS"
    }, {
      "code": "US-MT",
      "name": "Montana",
      "short_code": "MT"
    }, {
      "code": "US-NC",
      "name": "North Carolina",
      "short_code": "NC"
    }, {
      "code": "US-ND",
      "name": "North Dakota",
      "short_code": "ND"
    }, {
      "code": "US-NE",
      "name": "Nebraska",
      "short_code": "NE"
    }, {
      "code": "US-NH",
      "name": "New Hampshire",
      "short_code": "NH"
    }, {
      "code": "US-NJ",
      "name": "New Jersey",
      "short_code": "NJ"
    }, {
      "code": "US-NM",
      "name": "New Mexico",
      "short_code": "NM"
    }, {
      "code": "US-NV",
      "name": "Nevada",
      "short_code": "NV"
    }, {
      "code": "US-NY",
      "name": "New York",
      "short_code": "NY"
    }, {
      "code": "US-OH",
      "name": "Ohio",
      "short_code": "OH"
    }, {
      "code": "US-OK",
      "name": "Oklahoma",
      "short_code": "OK"
    }, {
      "code": "US-OR",
      "name": "Oregon",
      "short_code": "OR"
    }, {
      "code": "US-PA",
      "name": "Pennsylvania",
      "short_code": "PA"
    }, {
      "code": "US-RI",
      "name": "Rhode Island",
      "short_code": "RI"
    }, {
      "code": "US-SC",
      "name": "South Carolina",
      "short_code": "SC"
    }, {
      "code": "US-SD",
      "name": "South Dakota",
      "short_code": "SD"
    }, {
      "code": "US-TN",
      "name": "Tennessee",
      "short_code": "TN"
    }, {
      "code": "US-TX",
      "name": "Texas",
      "short_code": "TX"
    }, {
      "code": "US-UT",
      "name": "Utah",
      "short_code": "UT"
    }, {
      "code": "US-VA",
      "name": "Virginia",
      "short_code": "VA"
    }, {
      "code": "US-VT",
      "name": "Vermont",
      "short_code": "VT"
    }, {
      "code": "US-WA",
      "name": "Washington",
      "short_code": "WA"
    }, {
      "code": "US-WI",
      "name": "Wisconsin",
      "short_code": "WI"
    }, {
      "code": "US-WV",
      "name": "West Virginia",
      "short_code": "WV"
    }, {
      "code": "US-WY",
      "name": "Wyoming",
      "short_code": "WY"
    }]
  }, {
    "code": "VNM",
    "name": "VietNam",
    "subdivisions": [{
      "code": "VN-01",
      "name": "Lai Châu",
      "short_code": "01"
    }, {
      "code": "VN-02",
      "name": "Lào Cai",
      "short_code": "02"
    }, {
      "code": "VN-03",
      "name": "Hà Giang",
      "short_code": "03"
    }, {
      "code": "VN-04",
      "name": "Cao Bằng",
      "short_code": "04"
    }, {
      "code": "VN-05",
      "name": "Sơn La",
      "short_code": "05"
    }, {
      "code": "VN-06",
      "name": "Yên Bái",
      "short_code": "06"
    }, {
      "code": "VN-07",
      "name": "Tuyên Quang",
      "short_code": "07"
    }, {
      "code": "VN-09",
      "name": "Lạng Sơn",
      "short_code": "09"
    }, {
      "code": "VN-13",
      "name": "Quảng Ninh",
      "short_code": "13"
    }, {
      "code": "VN-14",
      "name": "Hòa Bình",
      "short_code": "14"
    }, {
      "code": "VN-18",
      "name": "Ninh Bình",
      "short_code": "18"
    }, {
      "code": "VN-20",
      "name": "Thái Bình",
      "short_code": "20"
    }, {
      "code": "VN-21",
      "name": "Thanh Hóa",
      "short_code": "21"
    }, {
      "code": "VN-22",
      "name": "Nghệ An",
      "short_code": "22"
    }, {
      "code": "VN-23",
      "name": "Hà Tĩnh",
      "short_code": "23"
    }, {
      "code": "VN-24",
      "name": "Quảng Bình",
      "short_code": "24"
    }, {
      "code": "VN-25",
      "name": "Quảng Trị",
      "short_code": "25"
    }, {
      "code": "VN-26",
      "name": "Thừa Thiên-Huế",
      "short_code": "26"
    }, {
      "code": "VN-27",
      "name": "Quảng Nam",
      "short_code": "27"
    }, {
      "code": "VN-28",
      "name": "Kon Tum",
      "short_code": "28"
    }, {
      "code": "VN-29",
      "name": "Quảng Ngãi",
      "short_code": "29"
    }, {
      "code": "VN-30",
      "name": "Gia Lai",
      "short_code": "30"
    }, {
      "code": "VN-31",
      "name": "Bình Định",
      "short_code": "31"
    }, {
      "code": "VN-32",
      "name": "Phú Yên",
      "short_code": "32"
    }, {
      "code": "VN-33",
      "name": "Đắk Lắk",
      "short_code": "33"
    }, {
      "code": "VN-34",
      "name": "Khánh Hòa",
      "short_code": "34"
    }, {
      "code": "VN-35",
      "name": "Lâm Đồng",
      "short_code": "35"
    }, {
      "code": "VN-36",
      "name": "Ninh Thuận",
      "short_code": "36"
    }, {
      "code": "VN-37",
      "name": "Tây Ninh",
      "short_code": "37"
    }, {
      "code": "VN-39",
      "name": "Đồng Nai",
      "short_code": "39"
    }, {
      "code": "VN-40",
      "name": "Bình Thuận",
      "short_code": "40"
    }, {
      "code": "VN-41",
      "name": "Long An",
      "short_code": "41"
    }, {
      "code": "VN-43",
      "name": "Bà Rịa - Vũng Tàu",
      "short_code": "43"
    }, {
      "code": "VN-44",
      "name": "An Giang",
      "short_code": "44"
    }, {
      "code": "VN-45",
      "name": "Đồng Tháp",
      "short_code": "45"
    }, {
      "code": "VN-46",
      "name": "Tiền Giang",
      "short_code": "46"
    }, {
      "code": "VN-47",
      "name": "Kiến Giang",
      "short_code": "47"
    }, {
      "code": "VN-49",
      "name": "Vĩnh Long",
      "short_code": "49"
    }, {
      "code": "VN-50",
      "name": "Bến Tre",
      "short_code": "50"
    }, {
      "code": "VN-51",
      "name": "Trà Vinh",
      "short_code": "51"
    }, {
      "code": "VN-52",
      "name": "Sóc Trăng",
      "short_code": "52"
    }, {
      "code": "VN-53",
      "name": "Bắc Kạn",
      "short_code": "53"
    }, {
      "code": "VN-54",
      "name": "Bắc Giang",
      "short_code": "54"
    }, {
      "code": "VN-55",
      "name": "Bạc Liêu",
      "short_code": "55"
    }, {
      "code": "VN-56",
      "name": "Bắc Ninh",
      "short_code": "56"
    }, {
      "code": "VN-57",
      "name": "Bình Dương",
      "short_code": "57"
    }, {
      "code": "VN-58",
      "name": "Bình Phước",
      "short_code": "58"
    }, {
      "code": "VN-59",
      "name": "Cà Mau",
      "short_code": "59"
    }, {
      "code": "VN-61",
      "name": "Hải Dương",
      "short_code": "61"
    }, {
      "code": "VN-63",
      "name": "Hà Nam",
      "short_code": "63"
    }, {
      "code": "VN-66",
      "name": "Hưng Yên",
      "short_code": "66"
    }, {
      "code": "VN-67",
      "name": "Nam Định",
      "short_code": "67"
    }, {
      "code": "VN-68",
      "name": "Phú Thọ",
      "short_code": "68"
    }, {
      "code": "VN-69",
      "name": "Thái Nguyên",
      "short_code": "69"
    }, {
      "code": "VN-70",
      "name": "Vĩnh Phúc",
      "short_code": "70"
    }, {
      "code": "VN-71",
      "name": "Điện Biên",
      "short_code": "71"
    }, {
      "code": "VN-72",
      "name": "Đắk Nông",
      "short_code": "72"
    }, {
      "code": "VN-73",
      "name": "Hậu Giang",
      "short_code": "73"
    }, {
      "code": "VN-CT",
      "name": "Can Tho",
      "short_code": "CT"
    }, {
      "code": "VN-DN",
      "name": "Da Nang",
      "short_code": "DN"
    }, {
      "code": "VN-HN",
      "name": "Ha Noi",
      "short_code": "HN"
    }, {
      "code": "VN-HP",
      "name": "Hai Phong",
      "short_code": "HP"
    }, {
      "code": "VN-SG",
      "name": "Sai Gon",
      "short_code": "SG"
    }]
  }];
  _exports.default = _default;
});