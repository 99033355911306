define("dash/routes/login", ["exports", "dash/lib/unauthenticatedRoute", "dash/lib/userLogin"], function (_exports, _unauthenticatedRoute, _userLogin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _unauthenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    model() {
      return _userLogin.default.create(Ember.getOwner(this).ownerInjection());
    },
    setupController(controller, model) {
      this._super(...arguments);
      return Ember.set(controller, "model", model);
    },
    actions: {
      logIn(evt) {
        if (evt) evt.preventDefault();
        const dashService = Ember.get(this, "dash");
        const model = Ember.get(this, "controller.model");
        const {
          email,
          password
        } = model;
        return dashService.logIn(email, password).then(() => dashService.fetchCurrentUser()).then(() => this._transitionAfterSignin()).catch(errors => dashService.handleServerErrors(errors));
      }
    },
    // Privado
    _transitionAfterSignin() {
      this.transitionTo("home");
    }
  });
  _exports.default = _default;
});