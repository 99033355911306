define("dash/routes/reset", ["exports", "dash/lib/unauthenticatedRoute", "dash/lib/resetPassword"], function (_exports, _unauthenticatedRoute, _resetPassword) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _unauthenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    beforeModel(transition) {
      if (!Ember.get(transition, 'queryParams.t')) {
        return this.transitionTo('login');
      }
    },
    model(params) {
      return _resetPassword.default.create(Ember.getOwner(this).ownerInjection(), {
        resetToken: Ember.get(params, 'token')
      });
    },
    actions: {
      resetPassword(evt) {
        if (evt) evt.preventDefault();
        const dashService = Ember.get(this, 'dash');
        const model = Ember.get(this, 'controller.model');
        const {
          password,
          resetToken
        } = model;
        return dashService.resetPassword(password, resetToken).then(() => {
          dashService.showSuccessMessage('Your password was successfully reset.');
          return this.transitionTo('login');
        }).catch(errors => dashService.handleServerErrors(errors));
      }
    }
  });
  _exports.default = _default;
});