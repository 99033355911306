define("dash/models/affiliate", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var _default = Model.extend({
    tapfiliate_id: attr('string'),
    referral_link: attr('string'),
    has_paypal_address: attr('boolean'),
    total_commission_amount: attr('number'),
    paid_commission_amount: attr('number'),
    total_conversion_amount: attr('number'),
    emails_enabled: attr('boolean'),
    user: belongsTo('user')
  });
  _exports.default = _default;
});