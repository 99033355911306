define("dash/routes/services", ["exports", "dash/lib/authenticatedRoute", "dash/constants/ExternalLinks"], function (_exports, _authenticatedRoute, _ExternalLinks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    model() {
      return {
        currentUser: Ember.get(this, "dash.currentUser"),
        subscriptions: this.store.peekAll("subscription")
      };
    },
    setupController(controller) {
      this._super(...arguments);
      Ember.set(controller, "ExternalLinks", _ExternalLinks.default);
    }
  });
  _exports.default = _default;
});