define("dash/components/tool-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    isMagic8Ball: Ember.computed('deviceTool.tool.namespace', function () {
      const namespace = Ember.get(this, 'deviceTool.tool.namespace');
      return namespace === "magic8ball";
    })
  });
  _exports.default = _default;
});