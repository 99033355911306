define("dash/controllers/account/referrals/terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    hasAffiliate: false,
    backPath: Ember.computed('hasAffiliate', function () {
      if (Ember.get(this, "hasAffiliate")) return 'account.referrals.metrics';
      return 'account.referrals.intro';
    }),
    buttonText: Ember.computed('hasAffiliate', function () {
      if (Ember.get(this, "hasAffiliate")) return 'return to referrals';
      return 'agree';
    })
  });
  _exports.default = _default;
});