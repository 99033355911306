define("dash/validators/phone-number", ["exports", "ember-cp-validations/validators/base", "libphonenumber-js"], function (_exports, _base, _libphonenumberJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const InvalidPhoneMessage = 'Invalid phone number';
  const PhoneNumber = _base.default.extend({
    validate(value) {
      const asYouType = new _libphonenumberJs.AsYouType('US');
      const parsedValue = asYouType.input(value);
      try {
        const countryCode = asYouType.getNumber().country;
        const isValid = (0, _libphonenumberJs.isValidNumber)(parsedValue, countryCode);
        if (isValid) return true;
        return InvalidPhoneMessage;
      } catch (err) {
        return InvalidPhoneMessage;
      }
    }
  });
  var _default = PhoneNumber;
  _exports.default = _default;
});