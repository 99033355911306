define("dash/adapters/application", ["exports", "ember-data", "dash/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.JSONAPIAdapter.extend({
    dash: Ember.inject.service(),
    host: _environment.default.API.baseUrl,
    namespace: 'api',
    pathForType(type) {
      return Ember.String.underscore(this._super(type));
    },
    headers: Ember.computed('dash.{currentToken,currentSupportToken,cartToken}', function () {
      let headers = {};
      const authToken = Ember.get(this, 'dash.currentToken');
      const supportToken = Ember.get(this, 'dash.currentSupportToken');
      const cartToken = Ember.get(this, 'dash.cartToken');
      if (supportToken) {
        headers = Object.assign(headers, {
          authorization: `Bearer ${supportToken}`
        });
      } else if (authToken) {
        headers = Object.assign(headers, {
          authorization: `Bearer ${authToken}`
        });
      }
      if (cartToken) {
        headers = Object.assign(headers, {
          'light-cart-token': cartToken
        });
      }
      return headers;
    })
  });
  _exports.default = _default;
});