define("dash/adapters/contact", ["exports", "dash/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    findRecord(store, type, id, snapshot) {
      let url = this.buildURL(type.modelName, id, snapshot, 'findRecord');
      let query = this.buildQuery(snapshot);
      if (snapshot.adapterOptions.device_id) {
        url += `?device_id=${snapshot.adapterOptions.device_id}`;
      }
      return this.ajax(url, 'GET', {
        data: query
      });
    },
    deleteRecord(store, type, snapshot) {
      let id = snapshot.id;
      let url = this.buildURL(type.modelName, id, snapshot, 'deleteRecord');
      if (snapshot.adapterOptions.device_id) {
        url += `?device_id=${snapshot.adapterOptions.device_id}`;
      }
      return this.ajax(url, 'DELETE');
    }
  });
  _exports.default = _default;
});