define("dash/helpers/format-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatDate = formatDate;
  function formatDate([date /*, date*/]) {
    const formattedDay = `0${date.getDate()}`.slice(-2);
    const month = date.getMonth() + 1;
    const formattedMonth = `0${month}`.slice(-2);
    return `${formattedDay}/${formattedMonth}/${date.getFullYear()}`;
  }
  var _default = Ember.Helper.helper(formatDate);
  _exports.default = _default;
});