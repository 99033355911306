define("dash/templates/devices/device/contacts/import/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+0ZuPo0D",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\"],[\"Import Contacts\",\"devices.device.contacts.actions\",\"home\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"container--wide text-center\"],[9],[0,\"\\n    \"],[1,[27,\"menu-list\",null,[[\"links\"],[[23,[\"links\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/device/contacts/import/index.hbs"
    }
  });
  _exports.default = _default;
});