define("dash/lib/port", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    currentPhoneNumber: (0, _emberCpValidations.validator)('presence', true),
    previousProviderAccountNumber: (0, _emberCpValidations.validator)('presence', true),
    previousProviderAccountPassword: (0, _emberCpValidations.validator)('presence', true)
  });
  var _default = Ember.Object.extend(Validations, {
    currentPhoneNumber: '',
    previousProviderAccountNumber: '',
    previousProviderAccountPassword: '',
    serialize() {
      if (!Ember.get(this, 'validations.isValid')) return {};
      return {
        port_phone_number: Ember.get(this, 'currentPhoneNumber'),
        old_account_number: Ember.get(this, 'previousProviderAccountNumber'),
        old_account_password: Ember.get(this, 'previousProviderAccountPassword')
      };
    }
  });
  _exports.default = _default;
});