define("dash/routes/devices/device/tools/music/delete-all", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    setupController(controller) {
      this._super(...arguments);
      const deviceTool = Ember.get(this.modelFor('devices.device'), 'deviceTools').find(deviceTool => {
        return Ember.get(deviceTool, 'tool.namespace') === 'music';
      });
      Ember.set(controller, 'deviceToolId', deviceTool.id);
    }
  });
  _exports.default = _default;
});