define("dash/lib/calDavCalendar", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    account: [(0, _emberCpValidations.validator)('presence', true)],
    password: [(0, _emberCpValidations.validator)('presence', true)],
    link: [(0, _emberCpValidations.validator)('presence', true)]
  });
  var _default = Ember.Object.extend(Validations, {
    account: '',
    password: '',
    link: '',
    serialize() {
      if (!Ember.get(this, 'validations.isValid')) return {};
      return {
        account: Ember.get(this, 'account'),
        password: Ember.get(this, 'password'),
        link: Ember.get(this, 'link')
      };
    }
  });
  _exports.default = _default;
});