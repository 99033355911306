define("dash/routes/devices/device/tools/music", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    audioProcessingUpdatesSubscriber: null,
    deactivate() {
      clearInterval(this.audioProcessingUpdatesSubscriber);
    },
    async refreshModelAndCheckForCompletedProcessing() {
      await this.refresh();
      const currentRoute = Ember.get(this, 'routeName');
      const model = this.modelFor(currentRoute);
      const processingCount = Ember.get(model, 'processingCount');
      if (processingCount === 0) {
        clearInterval(this.audioProcessingUpdatesSubscriber);
        this.audioProcessingUpdatesSubscriber = null;
      }
    },
    actions: {
      subscribeToAudioProcessingUpdates: function () {
        if (this.audioProcessingUpdatesSubscriber === null) {
          this.audioProcessingUpdatesSubscriber = setInterval(() => {
            this.refreshModelAndCheckForCompletedProcessing();
          }, 10000);
        }
      }
    },
    upload: Ember.inject.service(),
    async model() {
      const musicDeviceTool = Ember.get(this.modelFor('devices.device'), 'deviceTools').find(deviceTool => {
        return Ember.get(deviceTool, 'tool.namespace') === 'music';
      });
      const tool = Ember.get(this, 'store').peekAll('tool').find(tool => tool.namespace === 'music');
      if (!musicDeviceTool) {
        return {
          tool,
          installed: false,
          device: this.modelFor('devices.device')
        };
      }
      const uploadService = Ember.get(this, 'upload');
      const {
        remainingCapacity,
        totalCapacity,
        processingCount,
        failedCount
      } = await uploadService.checkRemainingCapacity(Ember.get(musicDeviceTool, 'id'));
      return {
        tool,
        musicDeviceTool,
        processingCount,
        failedCount,
        installed: true,
        supportLink: 'https://support.thelightphone.com/hc/en-us/articles/360048240532',
        usedCapacityPercentage: Math.ceil((totalCapacity - remainingCapacity) / totalCapacity * 100)
      };
    },
    afterModel(model, transition) {
      const processingCount = Ember.get(model, 'processingCount');
      if (processingCount > 0) {
        transition.send('subscribeToAudioProcessingUpdates');
      }
    }
  });
  _exports.default = _default;
});