define("dash/templates/devices/device/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wLPrnVgm",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\"],[\"Device Info\",\"devices.device.index\",\"home\"]],{\"statements\":[[4,\"if\",[[23,[\"model\",\"error\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[0,\"Error finding this device.\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"attribute-list\",null,[[\"items\"],[[23,[\"attributeList\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/device/info.hbs"
    }
  });
  _exports.default = _default;
});