define("dash/routes/account/referrals/intro", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.transitionReferrals = _exports.default = void 0;
  const transitionReferrals = (transitionTo, currentUser) => {
    const affiliate = Ember.get(currentUser, 'affiliate');
    if (!affiliate) {
      return transitionTo('account.referrals.intro');
    }
    const tapfiliateId = Ember.get(affiliate, 'tapfiliate_id');
    const hasPaypalAddress = Ember.get(affiliate, 'has_paypal_address');
    const referralLink = Ember.get(affiliate, 'referral_link');
    if (referralLink) {
      return transitionTo('account.referrals.metrics');
    }
    if (hasPaypalAddress) {
      return transitionTo('account.referrals.confirm');
    }
    if (tapfiliateId) {
      return transitionTo('account.referrals.paypal');
    }
    return transitionTo('account.referrals.intro');
  };
  _exports.transitionReferrals = transitionReferrals;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    affiliate: Ember.inject.service(),
    beforeModel() {
      const currentUser = Ember.get(this, 'dash.currentUser');
      transitionReferrals(route => this.transitionTo(route), currentUser);
    },
    model() {
      const affiliateService = Ember.get(this, 'affiliate');
      return affiliateService.getAffiliateCommissionRate().then(res => {
        return {
          commision: res.commission_percentage
        };
      });
    },
    actions: {
      continue() {
        this.transitionTo('account.referrals.terms');
      }
    }
  });
  _exports.default = _default;
});