define("dash/routes/devices/index", ["exports", "dash/lib/authenticatedRoute", "dash/constants/ExternalLinks"], function (_exports, _authenticatedRoute, _ExternalLinks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    model() {
      return this.modelFor('devices');
    },
    setupController(controller, model) {
      this._super(...arguments);
      Ember.set(controller, 'ExternalLinks', _ExternalLinks.default);
      Ember.set(controller, 'links', this.getMenuLinks(model));
    },
    getMenuLinks(devices = []) {
      if (!devices || !devices.length) return [];
      return devices.map(device => {
        return {
          path: 'devices.device',
          data: Ember.get(device, 'id'),
          text: Ember.get(device, 'sim.formattedPhoneNumber') || Ember.get(device, 'imei')
        };
      });
    }
  });
  _exports.default = _default;
});