define("dash/routes/devices/device/contacts/actions", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    model() {
      return this.modelFor('devices.device');
    },
    afterModel(device) {
      if (!device || !Ember.get(device, 'id')) this.transitionTo('devices');
    },
    setupController(controller, device) {
      this._super(...arguments);
      Ember.set(controller, 'links', this.getMenuLinks(device));
    },
    getMenuLinks(device) {
      const deviceId = Ember.get(device, 'id');
      return [{
        text: 'Add Manually',
        path: 'devices.device.contacts.new',
        data: deviceId
      }, {
        text: 'Import Contacts',
        path: 'devices.device.contacts.import',
        data: deviceId
      }, {
        text: 'Export Contacts',
        path: 'devices.device.contacts.export',
        data: deviceId
      }, {
        text: 'Edit Contacts',
        path: 'devices.device.contacts',
        data: deviceId
      }, {
        text: 'Delete All',
        path: 'devices.device.contacts.delete-all',
        data: deviceId
      }];
    }
  });
  _exports.default = _default;
});