define("dash/routes/onboarding", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    beforeModel() {
      const currentUser = Ember.get(this, 'dash.currentUser');
      if (Ember.get(currentUser, 'givenName') && Ember.get(currentUser, 'familyName')) {
        return this.transitionTo('home');
      }
    },
    model() {
      return Ember.get(this, 'dash.currentUser');
    },
    actions: {
      updateAccount(evt) {
        if (evt) evt.preventDefault();
        const dashService = Ember.get(this, 'dash');
        const currentUser = Ember.get(this, 'controller.model');
        return currentUser.save().then(() => {
          dashService.showSuccessMessage('Your account was successfully updated');
          return this.transitionTo('home');
        }).catch(errors => {
          return dashService.handleServerErrors(errors);
        });
      },
      willTransition(transition) {
        const model = Ember.get(this, 'controller.model');
        if (!Ember.getWithDefault(model, 'givenName', '').length || !Ember.getWithDefault(model, 'familyName', '').length) {
          return transition.abort();
        }
      }
    }
  });
  _exports.default = _default;
});