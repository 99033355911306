define("dash/controllers/forgot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    queryParams: [{
      email: 'email'
    }],
    email: null
  });
  _exports.default = _default;
});