define("dash/services/dash", ["exports", "dash/config/environment", "@sentry/browser"], function (_exports, _environment, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LightAPIException extends Error {
    constructor(message) {
      super(message);
      this.name = "LightAPIException";
    }
  }
  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    notify: Ember.inject.service(),
    router: Ember.inject.service(),
    currentToken: null,
    currentSupportToken: null,
    currentUser: null,
    // Auth
    signup(email, password) {
      const ajaxService = Ember.get(this, "ajax");
      if (!ajaxService) return;
      return ajaxService.request("/users", {
        method: "POST",
        data: {
          email: email,
          password: password
        }
      });
    },
    logIn(email, password) {
      const ajaxService = Ember.get(this, "ajax");
      if (!ajaxService) return;
      return ajaxService.request("/authorizations", {
        method: "POST",
        data: {
          email: email,
          password: password
        }
      }).then(res => {
        return this._authorizeUser(res);
      });
    },
    logOut() {
      const ajaxService = Ember.get(this, "ajax");
      if (!ajaxService) return;
      Sentry.setUser(null);
      return ajaxService.request("/tokens/revoke", {
        method: "DELETE"
      }).then(() => {
        this.store.unloadAll();
        return this._clearTokensAndRedirect();
      });
    },
    forgotPassword(email) {
      const ajaxService = Ember.get(this, "ajax");
      return ajaxService.request("/passwords/forgot", {
        method: "POST",
        data: {
          email: email
        }
      });
    },
    resetPassword(password, token) {
      const ajaxService = Ember.get(this, "ajax");
      return ajaxService.request("/passwords/reset", {
        method: "POST",
        data: {
          password: password,
          token: token
        }
      });
    },
    fetchCurrentUser() {
      const ajaxService = Ember.get(this, "ajax");
      if (!ajaxService) return;
      return ajaxService.request("/users/current", {
        method: "GET"
      }).then(currentUserData => {
        return this.setCurrentUser(currentUserData);
      });
    },
    setCurrentUser(currentUserData) {
      const userId = Ember.get(currentUserData, "data.id");
      if (!userId) {
        return;
      }
      const storeService = Ember.get(this, "store");
      storeService.pushPayload("user", currentUserData);
      const currentUser = storeService.peekRecord("user", userId);
      return Ember.set(this, "currentUser", currentUser);
    },
    currentUserIsAdminOrSupportAgent: Ember.computed("currentUser", "currentSupportToken", function () {
      if (this.currentUser && this.currentUser.isAdmin) {
        return true;
      }
      if (this.currentSupportToken) {
        return true;
      }
      return false;
    }),
    getInvoiceUrl(invoiceId, secretToken) {
      return `${_environment.default.API.baseUrl}/api/invoice_documents/${invoiceId}?secret_token=${secretToken}`;
    },
    downloadInvoicePdf(invoice) {
      const ajaxService = Ember.get(this, "ajax");
      if (!ajaxService) return;
      return ajaxService.raw(`invoice_documents/${invoice.id}`, {
        dataType: "binary",
        data: {
          id: invoice.id,
          secret_token: invoice.secret_token
        },
        xhr: () => {
          // Because we are returning a blob and not json we need to
          // hook into the base xhr
          let myXhr = Ember.$.ajaxSettings.xhr();
          myXhr.responseType = "blob";
          return myXhr;
        }
      });
    },
    searchNotes(deviceToolId, searchTerm) {
      const ajaxService = Ember.get(this, "ajax");
      if (!ajaxService) return;
      return ajaxService.request(`/notes/${deviceToolId}/search`, {
        method: "GET",
        data: {
          query: searchTerm
        }
      }).then(data => {
        const notes = data.data;
        return notes;
      });
    },
    getPreSignedNoteUrl(noteId) {
      const ajaxService = Ember.get(this, "ajax");
      if (!ajaxService) return;
      return ajaxService.request(`/notes/${noteId}/generate_presigned_get_url`, {
        method: "GET"
      }).then(res => {
        return res.presigned_get_url;
      });
    },
    getNoteText(presignedUrl) {
      return fetch(presignedUrl, {
        method: "GET",
        headers: {
          "Content-Type": "text/plain"
        }
      }).then(res => {
        return res.text();
      });
    },
    performAddressSearch(addressString) {
      const ajaxService = Ember.get(this, "ajax");
      if (!ajaxService) return;
      const store = Ember.get(this, "store");

      // Removes existing search results from store before making new search
      store.unloadAll("address");
      return ajaxService.request("addresses", {
        method: "GET",
        data: {
          address_string: addressString
        }
      }).then(({
        items: addresses
      }) => {
        const addressesForStore = addresses.map(address => {
          const formattedAddress = {
            id: address.id,
            type: "address"
          };
          Ember.set(formattedAddress, "attributes", address);
          return formattedAddress;
        });
        return store.push({
          data: addressesForStore
        });
      });
    },
    createDirectionsOrDirectoryTool({
      deviceId,
      toolId,
      latitude,
      longitude,
      address,
      deviceToolLocationId
    }) {
      const ajaxService = Ember.get(this, "ajax");
      if (!ajaxService) return;
      return ajaxService.request("device_tools", {
        method: "POST",
        data: {
          data: {
            type: "device_tools",
            attributes: {
              device_id: deviceId,
              tool_id: toolId,
              latitude,
              longitude,
              address,
              device_tool_location_id: deviceToolLocationId
            }
          }
        }
      });
    },
    updateDeviceToolLocation({
      latitude,
      longitude,
      address,
      deviceToolLocationId
    }) {
      const ajaxService = Ember.get(this, "ajax");
      if (!ajaxService) return;
      return ajaxService.request("device_tool_location", {
        method: "PATCH",
        data: {
          id: deviceToolLocationId,
          data: {
            attributes: {
              config: {
                latitude,
                longitude,
                address
              }
            }
          }
        }
      });
    },
    createCalendarTool({
      deviceId,
      toolId
    }) {
      const ajaxService = Ember.get(this, "ajax");
      if (!ajaxService) return;
      return ajaxService.request("device_tools", {
        method: "POST",
        data: {
          data: {
            type: "device_tools",
            attributes: {
              device_id: deviceId,
              tool_id: toolId,
              calendar: true
            }
          }
        }
      });
    },
    triggerDeleteAllContacts(currentDevice) {
      return Ember.get(this, "ajax").request("/contacts/delete_all", {
        method: "POST",
        data: {
          device_id: Ember.get(currentDevice, "id")
        }
      });
    },
    // Sync
    triggerICloudImport(iCloudCredentials, currentDevice) {
      return Ember.get(this, "ajax").request("/contacts/import/icloud", {
        method: "POST",
        data: {
          icloud_username: Ember.get(iCloudCredentials, "username"),
          app_specific_password: Ember.get(iCloudCredentials, "password"),
          device_id: Ember.get(currentDevice, "id")
        }
      });
    },
    uploadVCard(file, currentDevice) {
      const uploadURL = `${_environment.default.API.baseUrl}/api/contacts/import/file`;
      return file.upload(uploadURL, {
        data: {
          device_id: Ember.get(currentDevice, "id")
        },
        headers: {
          Authorization: `Bearer ${this._getAuthToken()}`
        }
      });
    },
    exportVCard(currentDevice) {
      return Ember.get(this, "ajax").raw("/addressbooks", {
        method: "GET",
        data: {
          device_id: Ember.get(currentDevice, "id")
        }
      });
    },
    associateImeis(token, imeis) {
      const ajaxService = Ember.get(this, "ajax");
      if (!ajaxService) return;
      return ajaxService.request("/akko_insurance/associate_imeis", {
        method: "POST",
        data: {
          token: token,
          imeis: imeis
        }
      });
    },
    // Error Handling
    handleServerErrors(error) {
      const serverErrors = Ember.get(error, "payload.errors") || Ember.get(error, "errors");
      if (serverErrors) {
        const [baseError, ...additionalErrors] = serverErrors;

        // We use "detail" as the unique error string here, as it's the most
        // useful when you take into account changeset / validation error sets
        Sentry.withScope(function (scope) {
          scope.setExtra("error_payload", serverErrors);
          scope.setFingerprint(["LightAPIException", baseError.detail]);
          Sentry.captureException(new LightAPIException(baseError.detail));
        });
        if (additionalErrors.length > 0) {
          // These are validation errors
          serverErrors.forEach(error => {
            const message = this._formatJsonApiErrorPointer(error);
            return this.showErrorMessage(message);
          });
        } else {
          // These are base errors
          return this._handleLightApiError(baseError);
        }
      } else {
        return this.showErrorMessage("Something went wrong, please try again.");
      }
    },
    showErrorMessage(message) {
      return Ember.get(this, "notify").error(message);
    },
    showWarningMessage(message) {
      return Ember.get(this, "notify").warning(message);
    },
    showSuccessMessage(message) {
      return Ember.get(this, "notify").success(message);
    },
    // Private
    _authorizeUser(authData) {
      const storeService = Ember.get(this, "store");
      const tokenData = (Ember.get(authData, "included") || []).find(sideload => sideload.type === "tokens");
      if (!Ember.get(authData, "data.id") || !tokenData || !Ember.get(tokenData, "attributes.token")) return;
      this.setCurrentUser(authData);
      const currentUser = Ember.get(this, "currentUser");
      if (currentUser) {
        this._storeCurrentUserToken(tokenData);
        Sentry.setUser({
          id: Ember.get(currentUser, "id"),
          email: Ember.get(currentUser, "email")
        });
        return currentUser;
      }
      storeService.unloadAll();
      Ember.set(this, "currentUser", null);
      throw new Error("Not authorized");
    },
    _getAuthToken() {
      const supportToken = Ember.get(this, "currentSupportToken");
      const authToken = Ember.get(this, "currentToken");
      return supportToken ? supportToken : authToken;
    },
    _storeCurrentUserToken(tokenData) {
      const token = Ember.get(tokenData, "attributes.token");
      const maxAge = Ember.get(tokenData, "attributes.max_age");
      const cookiesService = Ember.get(this, "cookies");
      if (!cookiesService) return;
      Ember.set(this, "currentToken", token);
      cookiesService.write("token", token, {
        maxAge: maxAge,
        domain: _environment.default.API.domain
      });
    },
    _clearTokensAndRedirect() {
      const routerService = Ember.get(this, "router");
      return this._clearCurrentUser().then(() => routerService.transitionTo("login"));
    },
    _clearCurrentUser() {
      const cookiesService = Ember.get(this, "cookies");
      return new Promise(resolve => {
        Ember.set(this, "currentToken", null);
        Ember.set(this, "currentSupportToken", null);
        Ember.set(this, "currentUser", null);
        cookiesService.clear("token", {
          domain: _environment.default.API.domain
        });
        cookiesService.clear("support_token", {
          domain: _environment.default.API.domain
        });
        return resolve();
      });
    },
    _handleLightApiError(error) {
      switch (Ember.get(error, "status")) {
        case 403:
          // Resource ownership error
          this._clearTokensAndRedirect();
          if (error.detail === "Bad Credentials") {
            return this.showWarningMessage("The password entered was incorrect. Please try again.");
          } else if (error.detail === "Email Not Found") {
            return this.showWarningMessage("There is not a light account associated with this email address. Please try again.");
          } else {
            return this.showWarningMessage("Your session has expired. Please log back in.");
          }
        case 401:
          // Unauthorized
          this._clearTokensAndRedirect();
          return this.showWarningMessage("Your session has expired. Please log back in.");
        default:
          return this.showWarningMessage(error.detail);
      }
    },
    _formatJsonApiErrorPointer(error) {
      if (!error || !Ember.get(error, "source.pointer")) return "";
      let splat = (Ember.get(error, "source.pointer") || "").split("/");
      let pointer = splat[splat.length - 1];
      const pointerString = (Ember.String.capitalize(pointer) || "").split("_").join(" ");
      const formattedPointerString = pointerString ? `${pointerString} — ` : "";
      return `${formattedPointerString}${Ember.get(error, "title")}.`;
    }
  });
  _exports.default = _default;
});