define("dash/templates/devices/device/settings/set-default-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "r60hZneM",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\",\"withoutContainer\"],[\"Set Search Origin\",\"devices.device.settings\",\"home\",true]],{\"statements\":[[0,\"  \"],[1,[27,\"default-location-form\",null,[[\"submitLocation\",\"submitButtonText\"],[[27,\"route-action\",[\"updateDeviceToolLocation\"],[[\"target\"],[[23,[\"thisClosure\"]]]]],\"update\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/device/settings/set-default-location.hbs"
    }
  });
  _exports.default = _default;
});