define("dash/routes/forgot", ["exports", "dash/lib/unauthenticatedRoute", "dash/lib/forgotPassword"], function (_exports, _unauthenticatedRoute, _forgotPassword) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _unauthenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    model(params) {
      return _forgotPassword.default.create(Ember.getOwner(this).ownerInjection(), {
        email: Ember.get(params, 'email')
      });
    },
    resetController(controller, isExiting) {
      if (isExiting && Ember.get(controller, 'showConfirmation')) {
        this._clearConfirmation();
      }
    },
    actions: {
      sendResetPassword(evt) {
        if (evt) evt.preventDefault();
        const dashService = Ember.get(this, 'dash');
        const model = Ember.get(this, 'controller.model');
        const {
          email
        } = model;
        return dashService.forgotPassword(email).finally(() => this._showConfirmation());
      }
    },
    _showConfirmation() {
      Ember.set(this, 'controller.showConfirmation', true);
    },
    _clearConfirmation() {
      Ember.set(this, 'controller.model', null);
      Ember.set(this, 'controller.showConfirmation', false);
    }
  });
  _exports.default = _default;
});