define("dash/templates/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QXuIavkL",
    "block": "{\"symbols\":[],\"statements\":[[4,\"random-background\",null,null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"vh100 flex flex-col items-center justify-between p1\"],[9],[0,\"\\n\"],[4,\"if\",[[22,0,[\"model\",\"error\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\"],[11,\"class\",\"myauto\"],[9],[1,[22,0,[\"model\",\"error\",\"detail\"]],false],[10],[0,\"\\n      \"],[7,\"img\"],[11,\"class\",\"Home__logo\"],[11,\"src\",\"assets/images/light-ii.svg\"],[11,\"alt\",\"light ii logo\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"p\"],[11,\"class\",\"sub-heading\"],[9],[1,[22,0,[\"model\",\"fullName\"]],false],[10],[0,\"\\n      \"],[1,[27,\"main-menu\",null,[[\"items\"],[[23,[\"items\"]]]]],false],[0,\"\\n      \"],[7,\"img\"],[11,\"class\",\"Home__logo\"],[11,\"src\",\"assets/images/light-ii.svg\"],[11,\"alt\",\"light ii logo\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/home.hbs"
    }
  });
  _exports.default = _default;
});