define("dash/controllers/devices/device/tools/calendar/add/caldav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    headingText: Ember.computed('stage', function () {
      let stage = Ember.get(this, 'stage');
      return stage === 'SUCCESS' ? 'Calendar Sync Initiated' : 'Add CalDAV Server';
    })
  });
  _exports.default = _default;
});