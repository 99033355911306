define("dash/lib/audio", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', true),
    artist: (0, _emberCpValidations.validator)('presence', true)
  });
  var _default = Ember.Object.extend(Validations, {
    title: '',
    artist: '',
    from(audioModel) {
      if (!audioModel) audioModel = {};
      this.setProperties({
        title: Ember.get(audioModel, 'title'),
        artist: Ember.get(audioModel, 'artist')
      });
      return this;
    },
    serialize() {
      if (!Ember.get(this, 'validations.isValid')) return {};
      return {
        title: Ember.get(this, 'title'),
        artist: Ember.get(this, 'artist')
      };
    }
  });
  _exports.default = _default;
});