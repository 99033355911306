define("dash/components/subscription-detail", ["exports", "luxon", "dash/utils/phoneNumber", "dash/constants/ExternalLinks"], function (_exports, _luxon, _phoneNumber, _ExternalLinks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    ExternalLinks: _ExternalLinks.default,
    cost: Ember.computed('cost_in_cents', function () {
      const costInCents = Ember.get(this, 'subscription.cost_in_cents');
      const costInDollars = Math.round(costInCents / 100).toFixed(2);
      return `$${costInDollars}`;
    }),
    phoneNumber: Ember.computed('subscription.phone_number', function () {
      return (0, _phoneNumber.formatPhoneNumber)(Ember.get(this, 'subscription.phone_number'));
    }),
    nextRenewal: Ember.computed('subscription.current_period_end', function () {
      const nextRenewal = Ember.get(this, 'subscription.current_period_end');
      const luxonDate = _luxon.default.DateTime.fromISO(nextRenewal);
      return `Next Renewal - ${luxonDate.toFormat('EEEE, MMM d')} at ${luxonDate.toFormat('h:mm a')}`;
    })
  });
  _exports.default = _default;
});