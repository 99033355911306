define("dash/templates/devices/device/contacts/delete-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fYJ9/Yjh",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\"],[\"Confirm Delete All\",\"devices.device.contacts.actions\",\"home\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"stage\"]],\"INTRO\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"container--wide text-center pt_5\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"heading\"],[9],[0,\"\\n        This will delete all of your contacts and conversations and can't be undone.\\n      \"],[10],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"heading mt3\"],[9],[0,\"\\n        Are you sure?\\n      \"],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"Button--hollow mt6 mb_5\"],[12,\"disabled\",[21,\"isDeleting\"]],[12,\"onClick\",[27,\"route-action\",[\"deleteAll\"],null]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isDeleting\"]]],null,{\"statements\":[[0,\"          deleting...\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          yes, delete all\\n\"]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"stage\"]],\"SUCCESS\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"container--wide text-center pt_5\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"heading\"],[9],[0,\"\\n        We've started deleting your contacts, and will email you when the operation is complete.\\n      \"],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"Button--hollow mt6 mb_5\"],[12,\"onClick\",[27,\"route-action\",[\"confirm\"],null]],[9],[0,\"\\n        got it\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/device/contacts/delete-all.hbs"
    }
  });
  _exports.default = _default;
});