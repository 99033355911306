define("dash/routes/devices/device/info", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    model() {
      return this.modelFor('devices.device');
    },
    setupController(controller, device) {
      this._super(...arguments);
      return Ember.set(controller, 'attributeList', this.getAttributeList(device));
    },
    getAttributeList(device = {}) {
      const {
        imei,
        sku,
        softwareVersion
      } = device;
      return [{
        label: 'IMEI Number',
        value: imei
      }, {
        label: 'Model',
        value: sku
      }, {
        label: 'Software Version',
        value: softwareVersion
      }];
    }
  });
  _exports.default = _default;
});