define("dash/templates/components/attribute-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "UhUsW4Xw",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"each\",[[23,[\"items\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\"],[11,\"class\",\"my4 md:my6\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"color-gray-dark mb_75 md:mb1\"],[9],[1,[22,1,[\"label\"]],false],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"color-gray-light\"],[9],[1,[22,1,[\"value\"]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/components/attribute-list.hbs"
    }
  });
  _exports.default = _default;
});