define("dash/routes/akko-insurance/associate-imeis", ["exports", "dash/utils/luhn"], function (_exports, _luhn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const validateImei = imei => {
    // North-American IMEI TAC
    const imeiPattern = /^352744\d{9}$/;
    return imeiPattern.test(imei) && imei.length === 15 && (0, _luhn.default)(imei);
  };
  const stripNonNumericalChars = str => {
    return str.replace(/\D/g, "");
  };
  var _default = Ember.Route.extend({
    dash: Ember.inject.service(),
    model(params) {
      const expiresAt = Ember.get(params, "expiresAt");
      const numberOfImeis = Ember.get(params, "numberOfImeis");
      const token = Ember.get(params, "token");
      const isTokenValid = new Date(expiresAt) > new Date();
      return {
        isTokenValid,
        numberOfImeis,
        token
      };
    },
    setupController(controller, model) {
      this._super(...arguments);
      Ember.set(controller, "imeis", Array.from({
        length: model.numberOfImeis
      }, () => {
        return {
          imei: "",
          showValidationError: false
        };
      }));
      Ember.set(controller, "token", model.token);
      Ember.set(controller, "hasEmptyInput", true);
    },
    actions: {
      onRowUnfocus(index) {
        const imeis = Ember.get(this, "controller.imeis");
        const isValid = validateImei(imeis[index].imei);
        if (!isValid) {
          const imeis = Ember.get(this, "controller.imeis");
          Ember.set(imeis[index], "showValidationError", true);
          Ember.set(this, "controller.imeis", imeis);
        }
      },
      updateImei(index, value) {
        const imeis = Ember.get(this, "controller.imeis");
        const toSet = stripNonNumericalChars(value);
        Ember.set(imeis[index], "imei", toSet);
        if (imeis[index].showValidationError) {
          const isValid = validateImei(toSet);
          Ember.set(imeis[index], "showValidationError", !isValid);
        }
        const hasEmptyInput = imeis.some(imei => {
          return imei.imei === "";
        });
        Ember.set(this, "controller.imeis", imeis);
        Ember.set(this, "controller.hasEmptyInput", hasEmptyInput);
      },
      submit() {
        const imeis = Ember.get(this, "controller.imeis");
        const joinedImeis = imeis.reduce((acc, imei) => {
          acc.push(imei.imei);
          return acc;
        }, []);
        const token = Ember.get(this, "controller.token");
        Ember.get(this, "dash").associateImeis(token, joinedImeis).then(() => {
          Ember.get(this, "dash").showSuccessMessage("Success! Your IMEIs have been registered.");
        }).catch(errors => {
          return Ember.get(this, "dash").handleServerErrors(errors);
        });
      }
    }
  });
  _exports.default = _default;
});