define("dash/components/music-edit-audio-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['AudioEditForm', 'container--wide', 'text-center', 'mt2_5', 'md:px0'],
    hasValidationError: false,
    hideSaveButton: false,
    actions: {
      onUpdateValue(audio, field, val) {
        Ember.set(audio, field, val);
        Ember.set(this, 'audio', audio);
      },
      submit(evt) {
        if (evt) evt.preventDefault();
        Ember.get(this, 'audio').validate().then(({
          validations
        }) => {
          const isValid = Ember.get(validations, 'isValid');
          Ember.set(this, 'hasValidationError', !isValid);
          if (isValid) {
            Ember.get(this, 'saveAudio')();
          }
        });
      }
    }
  });
  _exports.default = _default;
});