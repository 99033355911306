define("dash/controllers/akko-insurance/associate-imeis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    queryParams: [{
      token: 'token',
      expiresAt: 'expires-at',
      numberOfImeis: 'number-of-imeis'
    }]
  });
  _exports.default = _default;
});