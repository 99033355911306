define("dash/templates/onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ped+/r9z",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"random-background\",null,null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"vh100 flex flex-col items-center justify-between p1 md:py4 w100\"],[9],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"heading heading-underline inline-block\"],[9],[0,\"\\n        Add Your Name\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"form-for\",[[23,[\"model\"]]],[[\"submit\"],[[27,\"route-action\",[\"updateAccount\"],null]]],{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"Input--primary text-left mb3\"],[9],[0,\"\\n        \"],[1,[27,\"component\",[[22,1,[\"text-field\"]],\"givenName\"],[[\"label\",\"fieldClasses\",\"errorClasses\"],[\"First Name\",\"flex flex-col mb1\",\"validation-error\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"Input--primary text-left\"],[9],[0,\"\\n        \"],[1,[27,\"component\",[[22,1,[\"text-field\"]],\"familyName\"],[[\"label\",\"fieldClasses\",\"errorClasses\"],[\"Last Name\",\"flex flex-col mb1\",\"validation-error\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"id\",\"login-submit\"],[11,\"class\",\"hidden\"],[12,\"disabled\",[27,\"or\",[[27,\"not\",[[23,[\"model\",\"validations\",\"isValid\"]]],null],[23,[\"model\",\"isSaving\"]]],null]],[12,\"onClick\",[27,\"route-action\",[\"updateAccount\"],null]],[9],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[7,\"div\"],[11,\"class\",\"flex flex-col items-center\"],[9],[0,\"\\n      \"],[7,\"label\"],[11,\"for\",\"login-submit\"],[11,\"class\",\"Button--primary mb_5 md:mb1 text-center\"],[9],[0,\"Save\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/onboarding.hbs"
    }
  });
  _exports.default = _default;
});