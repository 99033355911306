define("dash/templates/account/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1TyIWETg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\"],[[23,[\"model\",\"displayTag\"]],\"home\",\"home\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"flex flex-col items-center justify-between p1\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"title text-center pointer color-white border-color-transparent bg-color-transparent hover-gray-light\"],[3,\"action\",[[22,0,[]],[27,\"route-action\",[\"referrals\"],null]]],[9],[0,\"\\n      Referrals\\n    \"],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"title text-center pointer color-white border-color-transparent bg-color-transparent hover-gray-light\"],[3,\"action\",[[22,0,[]],[27,\"route-action\",[\"editAccount\"],null]]],[9],[0,\"\\n      Edit Account\\n    \"],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"title text-center pointer color-white border-color-transparent bg-color-transparent hover-gray-light\"],[3,\"action\",[[22,0,[]],[27,\"route-action\",[\"logOut\"],null]]],[9],[0,\"\\n      Log Out\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/account/show.hbs"
    }
  });
  _exports.default = _default;
});