define("dash/routes/account/show", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    actions: {
      logOut() {
        const dashService = Ember.get(this, 'dash');
        return dashService.logOut().then(() => this.transitionTo('login'));
      },
      referrals() {
        this.transitionTo('account.referrals.intro');
      },
      editAccount() {
        this.transitionTo('account.edit');
      }
    }
  });
  _exports.default = _default;
});