define("dash/lib/paypal", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })]
  });
  var _default = Ember.Object.extend(Validations, {
    email: '',
    serialize() {
      if (!Ember.get(this, 'validations.isValid')) return {};
      return {
        email: Ember.get(this, 'email')
      };
    }
  });
  _exports.default = _default;
});