define("dash/components/form-field", ["exports", "ember-form-for/components/form-field"], function (_exports, _formField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _formField.default.extend({
    classNameBindings: [`shouldDisplayValidations:display-errors`],
    hasContent: Ember.computed.notEmpty('value').readOnly(),
    shouldDisplayValidations: Ember.computed.or('showValidations', 'hasContent').readOnly(),
    showErrors: Ember.computed.and('hasErrors', 'shouldDisplayValidations'),
    finalErrorClasses: Ember.computed('errorClasses', function () {
      let passedErrorClasses = Ember.get(this, 'errorClasses');
      if (typeof passedErrorClasses === 'string') passedErrorClasses = [passedErrorClasses];
      return (passedErrorClasses || []).concat('form-field--errors');
    }),
    focusOut() {
      this._super(...arguments);
      this.set('showValidations', true);
    }
  });
  _exports.default = _default;
});