define("dash/lib/contactsCache", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CACHE = {};
  var _default = {
    add(device, contact) {
      const deviceId = device.get('id');
      if (CACHE[deviceId]) {
        CACHE[deviceId].push(contact);
        CACHE[deviceId] = CACHE[deviceId].sortBy('firstName');
      } else {
        CACHE[deviceId] = [contact];
      }
      return contact;
    },
    remove(device, contact) {
      const deviceId = device.get('id');
      if (CACHE[deviceId]) {
        CACHE[deviceId] = CACHE[deviceId].filter(cachedContact => {
          return cachedContact.get('id') !== contact.get('id');
        });
        return true;
      }
      return false;
    },
    getAll(device) {
      const deviceId = device.get('id');
      return CACHE[deviceId];
    },
    setAll(device, set) {
      const deviceId = device.get('id');
      CACHE[deviceId] = set;
      CACHE[deviceId] = CACHE[deviceId].sortBy('firstName');
      return CACHE[deviceId];
    }
  };
  _exports.default = _default;
});