define("dash/routes/devices/device", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    model({
      device_id
    }) {
      return Ember.get(this, 'store').peekRecord('device', device_id);
    }
  });
  _exports.default = _default;
});