define("dash/routes/devices/device/tools", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    beforeModel() {
      const device = this.modelFor('devices.device');
      this.store.query('tool', {
        device_id: device.id
      });
    },
    model() {
      return this.modelFor('devices.device');
    }
  });
  _exports.default = _default;
});