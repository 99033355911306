define("dash/components/random-background", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const BACKGROUNDS = ['assets/images/backgrounds/aerial.jpg', 'assets/images/backgrounds/ambient.jpg', 'assets/images/backgrounds/birds.jpg', 'assets/images/backgrounds/bricks.jpg', 'assets/images/backgrounds/city.jpg', 'assets/images/backgrounds/clouds.jpg', 'assets/images/backgrounds/clouds.png', 'assets/images/backgrounds/clouds2.jpg', 'assets/images/backgrounds/clouds3.jpg', 'assets/images/backgrounds/clouds4.jpg', 'assets/images/backgrounds/clouds5.jpg', 'assets/images/backgrounds/clouds6.jpg', 'assets/images/backgrounds/cloudy.jpg', 'assets/images/backgrounds/cove.jpg', 'assets/images/backgrounds/dam.jpg', 'assets/images/backgrounds/dune.jpg', 'assets/images/backgrounds/fence.jpg', 'assets/images/backgrounds/flowers.jpg', 'assets/images/backgrounds/hills.jpg', 'assets/images/backgrounds/islands.jpg', 'assets/images/backgrounds/lights.jpg', 'assets/images/backgrounds/mist.jpg', 'assets/images/backgrounds/mountains.jpg', 'assets/images/backgrounds/pavement.jpg', 'assets/images/backgrounds/port.jpg', 'assets/images/backgrounds/road.jpg', 'assets/images/backgrounds/road2.jpg', 'assets/images/backgrounds/sand.jpg', 'assets/images/backgrounds/sky.jpg', 'assets/images/backgrounds/space.jpg', 'assets/images/backgrounds/sprinkle.jpg', 'assets/images/backgrounds/train.jpg', 'assets/images/backgrounds/trees.jpg', 'assets/images/backgrounds/water.jpg', 'assets/images/backgrounds/water2.jpg'];
  const getImageAtRandom = () => {
    const index = Math.floor(Math.random() * BACKGROUNDS.length);
    return BACKGROUNDS[index];
  };
  var _default = Ember.Component.extend({
    attributeBindings: ['bgStyle:style'],
    classNames: ['RandomBackground', 'relative', 'wrapper', 'overlay'],
    classNameBindings: ['isLoaded::opacity-0'],
    isLoaded: false,
    imgUrl: getImageAtRandom(),
    bgStyle: Ember.computed('imgUrl', function () {
      return Ember.String.htmlSafe(`background-image: url('${this.imgUrl}');`);
    }),
    didRender() {
      this._super(...arguments);
      const loader = new window.Image();
      loader.src = '';
      loader.onload = () => this.onLoad();
      loader.src = this.imgUrl;
    },
    onLoad() {
      if (Ember.get(this, 'isDestroyed') || Ember.get(this, 'isDestroying')) return;
      Ember.set(this, 'isLoaded', true);
    }
  });
  _exports.default = _default;
});