define("dash/models/user", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;
  const Validations = (0, _emberCpValidations.buildValidations)({
    givenName: (0, _emberCpValidations.validator)("presence", true),
    familyName: (0, _emberCpValidations.validator)("presence", true),
    email: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("format", {
      type: "email"
    })]
  });
  var _default = Model.extend(Validations, {
    givenName: attr("string"),
    familyName: attr("string"),
    email: attr("string"),
    devices: hasMany("device"),
    roles: attr("raw"),
    affiliate: belongsTo("affiliate", {
      async: false
    }),
    subscriptions: hasMany("subscription", {
      async: false
    }),
    isAdmin: Ember.computed("roles", function () {
      return (Ember.get(this, "roles") || []).includes("admin");
    }),
    fullName: Ember.computed("givenName", "familyName", function () {
      return `${Ember.get(this, "givenName")} ${Ember.get(this, "familyName")}`;
    }),
    displayTag: Ember.computed("phoneNumber", function () {
      const fullName = Ember.get(this, "fullName");
      if (fullName && fullName.length) return fullName;
      return Ember.get(this, "email");
    })
  });
  _exports.default = _default;
});