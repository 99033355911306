define("dash/routes/devices/device/tools/music/add", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function preventDragDropEventDefault(e) {
    e = e || event;
    e.preventDefault();
  }
  var _default = _authenticatedRoute.default.extend({
    activate() {
      window.addEventListener('dragover', preventDragDropEventDefault, false);
      window.addEventListener('drop', preventDragDropEventDefault, false);
    },
    deactivate() {
      window.removeEventListener('dragover', preventDragDropEventDefault, false);
      window.removeEventListener('drop', preventDragDropEventDefault, false);
    },
    setupController(controller) {
      this._super(...arguments);
      const deviceTool = Ember.get(this.modelFor('devices.device'), 'deviceTools').find(deviceTool => {
        return Ember.get(deviceTool, 'tool.namespace') === 'music';
      });
      Ember.set(controller, 'deviceToolId', deviceTool.id);
    }
  });
  _exports.default = _default;
});