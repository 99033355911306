define("dash/routes/devices/device/settings/set-default-location", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    setupController(controller) {
      this._super(...arguments);
      // hack since we can't pass closured action from route to component
      // and we need access to 'this' in installDirectoryTool
      controller.set('thisClosure', this);
    },
    actions: {
      updateDeviceToolLocation(selectedAddress) {
        const {
          attributes: {
            address,
            position: {
              lat: latitude,
              lng: longitude
            }
          }
        } = selectedAddress;
        const dashService = Ember.get(this, 'dash');
        const deviceToolLocationId = Ember.get(this.modelFor('devices.device'), 'deviceToolLocation.id');
        return dashService.updateDeviceToolLocation({
          deviceToolLocationId,
          address,
          latitude,
          longitude
        }).then(() => {
          return this.send('refreshDevices');
        }).then(() => {
          Ember.getOwner(this).lookup('route:devices.device').refresh();
        }).then(() => {
          dashService.showSuccessMessage('Your default location has been updated.');
          this.transitionTo('devices.device');
        }).catch(errors => dashService.handleServerErrors(errors));
      }
    }
  });
  _exports.default = _default;
});