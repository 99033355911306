define("dash/templates/components/music-edit-audio-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "MTnFUOS6",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"form-for\",[[23,[\"audio\"]]],[[\"update\",\"submit\"],[[27,\"action\",[[22,0,[]],\"onUpdateValue\"],null],[27,\"action\",[[22,0,[]],\"submit\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"Input--primary text-left\"],[9],[0,\"\\n    \"],[1,[27,\"component\",[[22,1,[\"text-field\"]],\"title\"],[[\"label\",\"errorClasses\"],[\"Song Name\",\"validation-error\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"Input--primary text-left\"],[9],[0,\"\\n    \"],[1,[27,\"component\",[[22,1,[\"text-field\"]],\"artist\"],[[\"label\",\"errorClasses\"],[\"Artist Name\",\"validation-error\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"unless\",[[23,[\"hideSaveButton\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"Button--hollow mt3\"],[12,\"disabled\",[27,\"or\",[[27,\"not\",[[23,[\"audio\",\"validations\",\"isValid\"]]],null],[23,[\"audio\",\"isSaving\"]]],null]],[12,\"onClick\",[27,\"action\",[[22,0,[]],\"submit\"],null]],[9],[0,\"\\n      \"],[4,\"if\",[[23,[\"audio\",\"isSaving\"]]],null,{\"statements\":[[0,\"saving...\"]],\"parameters\":[]},{\"statements\":[[0,\"save\"]],\"parameters\":[]}],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"hasValidationError\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\"],[11,\"class\",\"error\"],[11,\"data-test\",\"form-error\"],[9],[0,\"Oops! Check for errors to save.\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"submissionError\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\"],[11,\"class\",\"error\"],[11,\"data-test\",\"form-error\"],[9],[0,\"\\n        \"],[1,[21,\"submissionError\"],false],[0,\"\\n      \"],[10],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/components/music-edit-audio-form.hbs"
    }
  });
  _exports.default = _default;
});