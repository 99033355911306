define("dash/routes/home", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const homeMenuItems = [{
    text: 'Phone',
    path: 'devices'
  }, {
    text: 'Services',
    path: 'services'
  },
  //{ text: 'Tools', to: '/tools' },
  {
    text: 'Account',
    path: 'account'
  }, {
    text: 'Support',
    external: 'https://support.thelightphone.com'
  }];
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    model() {
      return Ember.get(this, 'dash.currentUser');
    },
    setupController(controller) {
      this._super(...arguments);
      Ember.set(controller, 'items', homeMenuItems);
    }
  });
  _exports.default = _default;
});