define("dash/routes/devices/device/tools/calendar/add/google", ["exports", "dash/lib/authenticatedRoute", "dash/lib/calendar"], function (_exports, _authenticatedRoute, _calendar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Stages = {
    INTRO: 'INTRO',
    IDLE: 'IDLE',
    SUCCESS: 'SUCCESS'
  };
  var _default = _authenticatedRoute.default.extend({
    setupController(controller) {
      this._super(...arguments);
      const deviceId = Ember.get(this.modelFor('devices.device'), 'id');
      Ember.set(controller, 'deviceId', deviceId);
      Ember.set(controller, 'stage', Stages.INTRO);
    },
    model() {
      const calendar = _calendar.default.create(Ember.getOwner(this).ownerInjection());
      return calendar;
    },
    actions: {
      dismissIntroMessage() {
        Ember.set(this, 'controller.stage', Stages.IDLE);
      },
      onCompleteSubmit() {
        Ember.set(this, 'controller.stage', Stages.SUCCESS);
      },
      returnToCalendarIndex() {
        this.transitionTo('devices.device.tools.calendar');
      }
    }
  });
  _exports.default = _default;
});