define("dash/components/audio-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const convertAudioTimestampToReadableString = timestamp => {
    let seconds = timestamp % 60;
    let minutes = (timestamp - seconds) / 60;
    if (seconds < 10) {
      seconds = '0' + seconds.toString();
    }
    if (minutes < 10) {
      minutes = '0' + minutes.toString();
    }
    return minutes + ':' + seconds;
  };
  var _default = Ember.Component.extend({
    isPlaying: false,
    duration: 0,
    currentProgress: 0,
    dash: Ember.inject.service(),
    classNames: ['AudioPlayer'],
    readableDuration: Ember.computed('duration', function () {
      return convertAudioTimestampToReadableString(Math.floor(this.duration));
    }),
    readableCurrentProgress: Ember.computed('currentProgress', function () {
      return convertAudioTimestampToReadableString(Math.ceil(this.currentProgress));
    }),
    attachEventListeners() {
      const audioElement = document.getElementById(this.get('audioId'));
      const progressBarContainerElement = document.getElementById('progress-bar-container');
      audioElement.addEventListener('canplaythrough', () => {
        this.set('duration', audioElement.duration);
      });
      audioElement.addEventListener('ended', () => {
        this.set('isPlaying', false);
      });
      audioElement.addEventListener('timeupdate', () => {
        const currentProgress = audioElement.currentTime;
        this.set('currentProgress', currentProgress);
        const progressBarWidth = progressBarContainerElement.offsetWidth;
        const percentagePlayed = currentProgress / this.get('duration');
        const progressIndicatorElementPosition = progressBarWidth * percentagePlayed;
        const progressIndicatorElement = document.getElementById('progress-indicator');
        progressIndicatorElement.style.left = `${progressIndicatorElementPosition}px`;
      });
      progressBarContainerElement.addEventListener('click', event => {
        const progressBarContainerElementRect = progressBarContainerElement.getBoundingClientRect();
        const clickedPosition = event.clientX - progressBarContainerElementRect.left;
        const progressBarWidth = progressBarContainerElement.offsetWidth;
        const percentageClicked = clickedPosition / progressBarWidth;
        audioElement.currentTime = this.get('duration') * percentageClicked;
      });
    },
    didInsertElement() {
      this.attachEventListeners();
    },
    actions: {
      handleClickPlayer: function () {
        const newState = !this.isPlaying;
        this.set('isPlaying', newState);
        const audioElement = document.getElementById(this.get('audioId'));
        if (newState === true) {
          audioElement.play();
        } else {
          audioElement.pause();
        }
      }
    }
  });
  _exports.default = _default;
});