define("dash/templates/devices/device/contacts/contact/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7T829aV8",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backAction\",\"cancelPath\"],[\"Edit Contact\",[27,\"route-action\",[\"onCancel\",\"devices.device.contacts\"],null],\"home\"]],{\"statements\":[[4,\"if\",[[23,[\"model\",\"error\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[0,\"Something went wrong! Please try again.\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"contact-form\",null,[[\"contact\",\"saveContact\",\"submissionError\"],[[23,[\"model\"]],[27,\"route-action\",[\"saveContact\"],null],[23,[\"submissionError\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/device/contacts/contact/index.hbs"
    }
  });
  _exports.default = _default;
});