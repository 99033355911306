define("dash/models/sim", ["exports", "ember-data", "dash/utils/phoneNumber"], function (_exports, _emberData, _phoneNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var _default = _emberData.default.Model.extend({
    iccid: attr('string'),
    phoneNumber: attr('string'),
    device: belongsTo('device'),
    formattedPhoneNumber: Ember.computed('phoneNumber', function () {
      return (0, _phoneNumber.formatPhoneNumber)(Ember.get(this, 'phoneNumber'));
    })
  });
  _exports.default = _default;
});