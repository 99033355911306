define("dash/initializers/coordinator-setup", ["exports", "dash/models/coordinator"], function (_exports, _coordinator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "setup coordinator",
    initialize: function () {
      let app = arguments[1] || arguments[0];
      app.register("drag:coordinator", _coordinator.default);
      app.inject("component", "coordinator", "drag:coordinator");
    }
  };
  _exports.default = _default;
});