define("dash/templates/components/imeis-form-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "w/d9YZj3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"flex flex-col items-start mt1\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"Input--primary text-left\"],[9],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"value\",\"focusOut\",\"input\",\"size\"],[[23,[\"imei\",\"imei\"]],[27,\"action\",[[22,0,[]],\"onFocusOut\"],null],[27,\"action\",[[22,0,[]],\"onInputChange\"],null],60]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"imei\",\"showValidationError\"]]],null,{\"statements\":[[0,\"      \"],[7,\"label\"],[11,\"class\",\"body Label--error\"],[9],[0,\"\\n        Please enter a valid 15 digit IMEI\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"label\"],[11,\"class\",\"body\"],[9],[0,\"\\n        Enter IMEI for the Light Phone you'd like to register\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/components/imeis-form-row.hbs"
    }
  });
  _exports.default = _default;
});