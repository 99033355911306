define("dash/controllers/devices/device/tools/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    deviceTools: Ember.computed('model.deviceTools.@each.tool.title', function () {
      return Ember.get(this.model, 'deviceTools').sortBy('tool.title');
    })
  });
  _exports.default = _default;
});