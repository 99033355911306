define("dash/templates/forgot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Tm1380fP",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\"],[\"Reset Password\",\"login\",\"home\"]],{\"statements\":[[4,\"if\",[[23,[\"showConfirmation\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"container--wide text-center\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"heading\"],[9],[0,\"\\n        If an account with email \"],[1,[23,[\"model\",\"email\"]],false],[0,\" exists, you will receive an email with instructions for resetting your password.\\n      \"],[10],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"heading mt4\"],[9],[0,\"\\n        Didn't get it?\\n      \"],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"Button--hollow mt2\"],[12,\"onClick\",[27,\"route-action\",[\"sendResetPassword\"],null]],[9],[0,\"\\n        Resend email\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"form-for\",[[23,[\"model\"]]],[[\"submit\"],[[27,\"route-action\",[\"sendResetPassword\"],null]]],{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"Input--primary text-left mb1\"],[9],[0,\"\\n        \"],[1,[27,\"component\",[[22,1,[\"text-field\"]],\"email\"],[[\"label\",\"errorClasses\"],[\"Email\",\"validation-error\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"Button--hollow block mxauto mt8 mb_5\"],[12,\"disabled\",[27,\"or\",[[27,\"not\",[[23,[\"model\",\"validations\",\"isValid\"]]],null],[23,[\"model\",\"isSaving\"]]],null]],[12,\"onClick\",[27,\"route-action\",[\"sendResetPassword\"],null]],[9],[0,\"\\n        \"],[4,\"if\",[[23,[\"model\",\"isSaving\"]]],null,{\"statements\":[[0,\"Sending...\"]],\"parameters\":[]},{\"statements\":[[0,\"Send\"]],\"parameters\":[]}],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/forgot.hbs"
    }
  });
  _exports.default = _default;
});