define("dash/routes/account/referrals/terms", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    model() {
      // user has to scroll down on previous page (intro) to click the 'sign up' button, causing this page to initially load
      // with a non-zero scroll position - we scrollTo 0 here to address this
      window.scrollTo(0, 0);
      return Ember.get(this, 'dash.currentUser');
    },
    setupController(controller, user) {
      this._super(...arguments);
      if (!Ember.get(user, 'affiliate')) {
        Ember.set(controller, 'hasAffiliate', false);
      } else {
        Ember.set(controller, 'hasAffiliate', true);
      }
    },
    actions: {
      continue() {
        if (Ember.get(this, 'controller.hasAffiliate')) {
          return this.transitionTo('account.referrals.metrics');
        }
        return this.transitionTo('account.referrals.signup');
      }
    }
  });
  _exports.default = _default;
});