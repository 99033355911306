define("dash/templates/components/audio-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "587HoOIP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"audio\"],[12,\"id\",[21,\"audioId\"]],[12,\"src\",[21,\"src\"]],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"duration-label sub-heading mt_5 mb6 text-center\"],[9],[0,\"\\n  \"],[1,[21,\"readableDuration\"],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"flex flex-col sub-heading text-center justify-center h-25\"],[9],[0,\"\\n\"],[0,\"  \"],[7,\"div\"],[11,\"id\",\"progress-bar-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"id\",\"progress-bar\"],[11,\"class\",\"mt6 mb2\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"id\",\"progress-indicator\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"audio-button-container my4\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"href\",\"\"],[3,\"action\",[[22,0,[]],\"handleClickPlayer\"]],[9],[0,\"\\n      \"],[7,\"img\"],[12,\"src\",[27,\"if\",[[23,[\"isPlaying\"]],\"/assets/images/icons/pause.svg\",\"/assets/images/icons/play.svg\"],null]],[12,\"alt\",[27,\"if\",[[23,[\"isPlaying\"]],\"pause icon\",\"play icon\"],null]],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"duration-label sub-heading mt_5 mb6 text-center\"],[9],[0,\"\\n  \"],[1,[21,\"readableCurrentProgress\"],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/components/audio-player.hbs"
    }
  });
  _exports.default = _default;
});