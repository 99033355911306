define("dash/templates/account/referrals/paypal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "R3/r5rC5",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\",\"withoutContainer\"],[\"Payout Information\",\"account.show\",\"home\",true]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"flex-3 flex-col sub-heading items-center my2 md:my4\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex-col flex items-center mb8\"],[9],[0,\"\\n      \"],[7,\"p\"],[9],[0,\"\\n        We are only able to make payouts via Paypal,\\n        \"],[7,\"br\"],[9],[10],[0,\"\\n        please enter your Paypal Account Email below:\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"form-for\",[[23,[\"model\"]]],[[\"submit\"],[[27,\"route-action\",[\"savePaypal\"],null]]],{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"Input--primary text-left mb3\"],[9],[0,\"\\n        \"],[1,[27,\"component\",[[22,1,[\"text-field\"]],\"email\"],[[\"label\",\"errorClasses\"],[\"Paypal Account Email\",\"validation-error\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"Button--hollow block mxauto mt8 mb_5\"],[12,\"disabled\",[27,\"or\",[[27,\"not\",[[23,[\"model\",\"validations\",\"isValid\"]]],null],[23,[\"isSaving\"]]],null]],[12,\"onClick\",[27,\"route-action\",[\"savePaypal\"],null]],[9],[0,\"\\n        \"],[4,\"if\",[[23,[\"isSaving\"]]],null,{\"statements\":[[0,\"saving...\"]],\"parameters\":[]},{\"statements\":[[0,\"continue\"]],\"parameters\":[]}],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/account/referrals/paypal.hbs"
    }
  });
  _exports.default = _default;
});