define("dash/helpers/form-for/merge-custom-form-field", ["exports", "ember-form-for/helpers/form-for/merge-custom-form-field"], function (_exports, _mergeCustomFormField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _mergeCustomFormField.default;
    }
  });
  Object.defineProperty(_exports, "formForMergeCustomFormField", {
    enumerable: true,
    get: function () {
      return _mergeCustomFormField.formForMergeCustomFormField;
    }
  });
});