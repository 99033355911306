define("dash/templates/devices/device/tools/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XgG4aGlZ",
    "block": "{\"symbols\":[\"deviceTool\"],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\",\"withoutContainer\"],[[23,[\"model\",\"formattedPhoneNumber\"]],\"devices.device\",\"home\",true]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"flex flex-col flex-1 mb4\"],[9],[0,\"\\n\"],[4,\"transition-loader\",null,[[\"imgUrl\",\"classNames\",\"contentClasses\",\"hasRequiredData\"],[\"/assets/images/icons/warning.svg\",\"flex flex-1\",\"flex flex-col flex-1 justify-center title\",[23,[\"model\",\"isLoaded\"]]]],{\"statements\":[[0,\"\\n      \"],[7,\"ul\"],[11,\"class\",\"flex-none mt0 mxauto pr3 md:pr5\"],[9],[0,\"\\n        \"],[7,\"li\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"ml4 md:ml6 color-gray-dark\"],[9],[0,\"Phone\"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"deviceTools\"]]],null,{\"statements\":[[0,\"          \"],[1,[27,\"tool-title\",null,[[\"deviceTool\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[7,\"li\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"ml4 md:ml6 color-gray-dark\"],[9],[0,\"Settings\"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"flex flex-row justify-center\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"devices.device.tools.add\",[23,[\"model\",\"id\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[11,\"class\",\"Button--title-icon flex-none\"],[9],[0,\"\\n            \"],[7,\"img\"],[11,\"src\",\"/assets/images/icons/add.svg\"],[11,\"alt\",\"Additional tools\"],[11,\"data-test\",\"add-tools-button\"],[11,\"class\",\"wh100\"],[9],[10],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\" \\n  \\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/device/tools/index.hbs"
    }
  });
  _exports.default = _default;
});