define("dash/controllers/devices/device/tools/directions/terms-and-conditions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    buttonText: Ember.computed('existingDeviceToolLocationId', function () {
      const existingDeviceToolLocationId = Ember.get(this, 'existingDeviceToolLocationId');
      const buttonText = existingDeviceToolLocationId ? 'install' : 'understood';
      return buttonText;
    })
  });
  _exports.default = _default;
});