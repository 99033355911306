define("dash/routes/devices/device/tools/notes/add", ["exports", "dash/lib/authenticatedRoute", "dash/constants/monthNames"], function (_exports, _authenticatedRoute, _monthNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    ajax: Ember.inject.service(),
    model() {
      const today = new Date();
      const day = today.getDate();
      const currentMonth = today.getMonth();
      const year = today.getFullYear();
      const dateString = `${_monthNames.default[currentMonth]} ${day}, ${year}`;
      return {
        date: dateString,
        title: ""
      };
    },
    setupController(controller) {
      this._super(...arguments);
      Ember.set(this, "controller.noteText", null);
      Ember.set(this, "controller.noteTitle", null);
      Ember.set(controller, "deviceToolId", Ember.get(this.modelFor("devices.device.tools.notes"), "noteDeviceTool.id"));
    },
    actions: {
      editTitle() {
        Ember.set(this, "controller.editingTitle", true);
      },
      updateTitle(title) {
        Ember.set(this, "controller.noteTitle", title);
        Ember.set(this, "controller.editingTitle", false);
      },
      discardNote() {
        Ember.set(this, "controller.noteText", null);
        Ember.set(this, "controller.noteTitle", null);
        this.transitionTo("devices.device.tools.notes");
      },
      saveNote(noteText, noteTitle) {
        const ajaxService = Ember.get(this, "ajax");
        const deviceTool = Ember.get(this.modelFor("devices.device"), "deviceTools").find(deviceTool => {
          return Ember.get(deviceTool, "tool.namespace") === "notes";
        });
        const data = {
          data: {
            type: "notes",
            attributes: {
              filename: "note.txt",
              device_tool_id: deviceTool.id,
              title: noteTitle
            }
          }
        };
        const dashService = Ember.get(this, "dash");
        return ajaxService.request("/notes", {
          method: "POST",
          data: data
        }).then(res => {
          const {
            presigned_url
          } = res.included[0].attributes;
          return fetch(presigned_url, {
            method: "PUT",
            body: noteText,
            headers: {
              "Content-Type": "text/plain"
            }
          });
        }).then(() => {
          dashService.showSuccessMessage(`Note was created.`);
          return this.transitionTo("devices.device.tools.notes.view");
        }).catch(err => {
          return dashService.handleServerErrors(err);
        });
      }
    }
  });
  _exports.default = _default;
});