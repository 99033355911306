define("dash/models/followed-podcast", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var _default = Model.extend({
    deviceTool: belongsTo('device-tool', {
      async: false
    }),
    podcast: belongsTo('podcast', {
      async: false
    })
  });
  _exports.default = _default;
});