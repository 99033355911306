define("dash/routes/devices/device/tools/music/edit/audio", ["exports", "dash/lib/authenticatedRoute", "dash/lib/audio"], function (_exports, _authenticatedRoute, _audio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    model({
      audio_id
    }) {
      const audio = Ember.get(this, 'store').peekRecord('audio', audio_id);
      Ember.set(this, 'editingAudio', audio);
      return _audio.default.create(Ember.getOwner(this).ownerInjection()).from(audio);
    },
    actions: {
      saveAudio() {
        const dashService = Ember.get(this, 'dash');
        const audio = Ember.get(this, 'editingAudio');
        Ember.set(audio, 'title', Ember.get(this, 'controller.model.title'));
        Ember.set(audio, 'artist', Ember.get(this, 'controller.model.artist'));
        Ember.set(this, 'controller.model.isSaving', true);
        return audio.save().then(() => {
          return this.transitionTo('devices.device.tools.music.edit');
        }).catch(errors => {
          return dashService.handleServerErrors(errors);
        }).finally(() => {
          Ember.set(this, 'controller.model.isSaving', false);
        });
      }
    }
  });
  _exports.default = _default;
});