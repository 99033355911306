define("dash/controllers/devices/device/tools/podcasts/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    dash: Ember.inject.service(),
    queryParams: ['searchTerm'],
    searchTerm: null,
    isNoSearchTerm: Ember.computed('searchTerm', function () {
      return Ember.isEmpty(Ember.get(this, 'searchTerm'));
    })
  });
  _exports.default = _default;
});