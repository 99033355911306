define("dash/templates/signup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KYht3V6P",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\"],[\"Create a New Account\",\"login\",\"home\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"container--wide text-center\"],[9],[0,\"\\n\"],[4,\"form-for\",[[23,[\"model\"]]],[[\"submit\"],[[27,\"route-action\",[\"signup\"],null]]],{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"Input--primary text-left mb3\"],[9],[0,\"\\n        \"],[1,[27,\"component\",[[22,1,[\"text-field\"]],\"email\"],[[\"label\",\"fieldClasses\",\"errorClasses\"],[\"Email\",\"flex flex-col mb1\",\"validation-error\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"Input--primary text-left mb3\"],[9],[0,\"\\n        \"],[1,[27,\"component\",[[22,1,[\"password-field\"]],\"password\"],[[\"label\",\"fieldClasses\",\"errorClasses\"],[\"Password\",\"flex flex-col mb1\",\"validation-error\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"Input--primary text-left\"],[9],[0,\"\\n        \"],[1,[27,\"component\",[[22,1,[\"password-field\"]],\"confirmPassword\"],[[\"label\",\"fieldClasses\",\"errorClasses\"],[\"Confirm Password\",\"flex flex-col mb1\",\"validation-error\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"Button--hollow block mxauto mt8 mb_5\"],[12,\"disabled\",[27,\"or\",[[27,\"not\",[[23,[\"model\",\"validations\",\"isValid\"]]],null],[23,[\"model\",\"isSaving\"]]],null]],[12,\"onClick\",[27,\"route-action\",[\"signup\"],null]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"isSaving\"]]],null,{\"statements\":[[0,\"          Creating Account...\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          Create Account\\n\"]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/signup.hbs"
    }
  });
  _exports.default = _default;
});