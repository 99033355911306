define("dash/routes/account/referrals/confirm", ["exports", "dash/lib/authenticatedRoute", "dash/routes/account/referrals/intro"], function (_exports, _authenticatedRoute, _intro) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    affiliate: Ember.inject.service(),
    beforeModel() {
      const currentUser = Ember.get(this, 'dash.currentUser');
      (0, _intro.transitionReferrals)(route => this.transitionTo(route), currentUser);
    },
    setupController(controller) {
      this._super(...arguments);
      Ember.set(controller, 'isSaving', false);
    },
    actions: {
      continue() {
        Ember.set(this, 'controller.isSaving', true);
        const dashService = Ember.get(this, 'dash');
        const affiliateService = Ember.get(this, 'affiliate');
        return affiliateService.addAffiliateToProgram().catch(errors => {
          return dashService.handleServerErrors(errors);
        }).finally(() => {
          Ember.set(this, 'controller.isSaving', false);
          this.transitionTo('account.referrals.metrics');
        });
      }
    }
  });
  _exports.default = _default;
});