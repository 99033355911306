define("dash/templates/devices/device/settings/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6QTXhWh3",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\"],[\"Settings\",\"devices.device.index\",\"home\"]],{\"statements\":[[4,\"link-to\",[[23,[\"link\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"flex flex-col\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"sub-heading text-center\"],[9],[0,\"\\n        Default Location\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"title text-center mb4\"],[9],[0,\"\\n        \"],[1,[21,\"defaultLocationText\"],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/device/settings/show.hbs"
    }
  });
  _exports.default = _default;
});