define("dash/routes/devices/device/tools/podcasts", ["exports", "dash/lib/authenticatedRoute", "dash/constants/ExternalLinks"], function (_exports, _authenticatedRoute, _ExternalLinks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    model() {
      const podcastDeviceTool = Ember.get(this.modelFor('devices.device'), 'deviceTools').find(deviceTool => {
        return Ember.get(deviceTool, 'tool.namespace') === 'podcasts';
      });
      if (!podcastDeviceTool) {
        return {
          installed: false,
          followedPodcasts: [],
          device: this.modelFor('devices.device'),
          tool: Ember.get(this, 'store').peekAll('tool').find(tool => tool.namespace === 'podcasts'),
          supportLink: _ExternalLinks.default.SUPPORT.podcasts
        };
      }
      const deviceToolId = Ember.get(podcastDeviceTool, 'id');
      return Ember.get(this, 'store').query('followed-podcast', {
        device_tool_id: deviceToolId
      }).then(followedPodcasts => {
        return {
          podcastDeviceTool,
          installed: true,
          followedPodcasts: followedPodcasts.sortBy('podcast.title'),
          supportLink: _ExternalLinks.default.SUPPORT.podcasts
        };
      }).catch(errors => Ember.get(this, 'dash').handleServerErrors(errors));
    },
    actions: {
      refreshFollowedPodcastModel() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});