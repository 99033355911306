define("dash/lib/address", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    givenName: (0, _emberCpValidations.validator)('presence', true),
    familyName: (0, _emberCpValidations.validator)('presence', true),
    address1: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('disallow-po-boxes', true)],
    phone: (0, _emberCpValidations.validator)('phone-number'),
    country: (0, _emberCpValidations.validator)('presence', true),
    state: (0, _emberCpValidations.validator)('presence', true),
    city: (0, _emberCpValidations.validator)('presence', true),
    zip: (0, _emberCpValidations.validator)('presence', true)
  });
  var _default = Ember.Object.extend(Validations, {
    givenName: '',
    familyName: '',
    address1: '',
    address2: '',
    phone: '',
    country: '',
    state: '',
    city: '',
    zip: '',
    from(rawShippingAddress) {
      if (!rawShippingAddress) rawShippingAddress = {};
      this.setProperties({
        givenName: Ember.get(rawShippingAddress, 'given_name'),
        familyName: Ember.get(rawShippingAddress, 'family_name'),
        address1: Ember.get(rawShippingAddress, 'address_1'),
        address2: Ember.get(rawShippingAddress, 'address_2'),
        phone: Ember.get(rawShippingAddress, 'phone'),
        country: Ember.get(rawShippingAddress, 'country'),
        state: Ember.get(rawShippingAddress, 'state'),
        city: Ember.get(rawShippingAddress, 'city'),
        zip: Ember.get(rawShippingAddress, 'zip')
      });
      return this;
    },
    serialize() {
      if (!Ember.get(this, 'validations.isValid')) return {};
      return {
        given_name: Ember.get(this, 'givenName'),
        family_name: Ember.get(this, 'familyName'),
        address_1: Ember.get(this, 'address1'),
        address_2: Ember.get(this, 'address2'),
        phone: Ember.get(this, 'phone'),
        country: Ember.get(this, 'country'),
        state: Ember.get(this, 'state'),
        city: Ember.get(this, 'city'),
        zip: Ember.get(this, 'zip')
      };
    }
  });
  _exports.default = _default;
});