define("dash/templates/devices/device/tools/music/edit/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "70djj0Jt",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\",\"withoutContainer\"],[\"Edit Playlist\",\"devices.device.tools.music\",\"home\",true]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"container--wider self-center flex flex-col pb4\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"processingCount\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n        \"],[7,\"p\"],[11,\"class\",\"sub-heading flex-col mt1 mb1 color-gray-light\"],[9],[0,\"(\"],[1,[23,[\"model\",\"processingCount\"]],false],[0,\" track(s) currently processing)\"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"model\",\"failedCount\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n        \"],[7,\"p\"],[11,\"class\",\"sub-heading flex-col mt1 mb1 color-gray-light\"],[9],[0,\"(\"],[1,[23,[\"model\",\"failedCount\"]],false],[0,\" track(s) failed processing)\"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[27,\"music-audio-list\",null,[[\"playlistItems\",\"sortMode\",\"changeSortMode\",\"sortEndAction\",\"dragStartAction\",\"deleteAction\"],[[23,[\"sortedPlaylistItems\"]],[23,[\"sortMode\"]],[27,\"action\",[[22,0,[]],\"changeSortMode\"],null],[27,\"action\",[[22,0,[]],\"sortEndAction\"],null],[27,\"action\",[[22,0,[]],\"onDragStart\"],null],[27,\"action\",[[22,0,[]],\"delete\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/device/tools/music/edit/index.hbs"
    }
  });
  _exports.default = _default;
});