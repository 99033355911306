define("dash/components/contact-form", ["exports", "dash/utils/phoneNumber"], function (_exports, _phoneNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['ContactForm', 'w100'],
    hasValidationError: false,
    actions: {
      onUpdateValue(contact, field, val) {
        if (field === 'number') {
          Ember.set(contact, 'number', (0, _phoneNumber.formatPhoneNumber)(val));
        } else {
          Ember.set(contact, field, val);
        }
        Ember.set(this, 'contact', contact);
      },
      submit(evt) {
        if (evt) {
          evt.preventDefault();
        }
        Ember.get(this, 'contact').validate().then(({
          validations
        }) => {
          const isValid = Ember.get(validations, 'isValid');
          Ember.set(this, 'hasValidationError', !isValid);
          if (isValid) {
            Ember.get(this, 'saveContact')();
          }
        });
      }
    }
  });
  _exports.default = _default;
});