define("dash/routes/devices/device/contacts/export", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    setupController() {
      this._super(...arguments);
    },
    actions: {
      confirm() {
        this.transitionTo('home');
      },
      fetchAndDownload() {
        if (Ember.get(this, 'controller.isFetching')) return;
        Ember.set(this, 'controller.isFetching', true);
        const currentDevice = this.modelFor('devices.device');
        Ember.get(this, 'dash').exportVCard(currentDevice).then(response => {
          const data = Ember.get(response, 'payload.data');
          var filename = 'light_addressbook.vcf';
          var element = document.createElement('a');
          element.setAttribute('href', 'data:text/vcard;charset=utf-8,' + encodeURIComponent(data));
          element.setAttribute('download', filename);
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        }).catch(errors => {
          Ember.get(this, 'dash').handleServerErrors(errors);
        }).finally(() => {
          Ember.set(this, 'controller.isFetching', false);
        });
      }
    }
  });
  _exports.default = _default;
});