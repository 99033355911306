define("dash/routes/devices/device/tools/notes/view/audio-note", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    ajax: Ember.inject.service(),
    dash: Ember.inject.service(),
    model({
      audio_note_id
    }) {
      const dashService = Ember.get(this, "dash");
      let note;
      return Ember.get(this, "store").findRecord("note", audio_note_id, {
        reload: true
      }).then(res => {
        Ember.set(this, "editingNote", res);
        note = res;
      }).then(() => {
        return dashService.getPreSignedNoteUrl(audio_note_id);
      }).then(presignedUrl => {
        return Ember.RSVP.hash({
          note: note,
          presignedUrl: presignedUrl
        });
      }).catch(err => {
        if (err.status == 404) {
          dashService.showErrorMessage("The note you selected was deleted and no longer exists.");
        } else {
          dashService.handleServerErrors(err);
        }
        this.send("refreshNotesModel");
        return this.transitionTo("devices.device.tools.notes.view");
      });
    },
    setupController(controller) {
      this._super(...arguments);
      Ember.set(controller, "editingTitle", false);
      Ember.set(controller, "deviceToolId", Ember.get(this.modelFor("devices.device.tools.notes"), "noteDeviceTool.id"));
    },
    actions: {
      editTitle() {
        Ember.set(this, "controller.editingTitle", true);
      },
      displayConfirmation() {
        return this.transitionTo("devices.device.tools.notes.view");
      },
      updateTitle() {
        const dashService = Ember.get(this, "dash");
        const note = Ember.get(this, "editingNote");
        return note.save().then(() => {
          dashService.showSuccessMessage(`Note title was updated.`);
          this.send("refreshNotesModel");
          Ember.set(this, "controller.editingTitle", false);
        }).catch(err => {
          return dashService.handleServerErrors(err);
        });
      },
      deleteNote() {
        const dashService = Ember.get(this, "dash");
        const note = Ember.get(this, "editingNote");
        const noteTitle = Ember.get(note, "title");
        let message = `Are you sure you'd like to delete '${noteTitle}'?`;
        if (confirm(message) == true) {
          note.destroyRecord().then(() => {
            sessionStorage.removeItem('noteId');
            dashService.showSuccessMessage(`${noteTitle} was deleted.`);
            this.send("refreshNotesModel");
            return this.transitionTo("devices.device.tools.notes.view");
          }).catch(err => {
            return dashService.handleServerErrors(err);
          });
        }
      },
      download(presignedUrl, noteTitle) {
        fetch(presignedUrl).then(response => response.blob()).then(blob => {
          const url = window.URL.createObjectURL(blob);
          var filename = `${noteTitle}.mp3`;
          var element = document.createElement("a");
          element.setAttribute("href", url);
          element.setAttribute("target", "_blank");
          element.setAttribute("download", filename);
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        });
      }
    }
  });
  _exports.default = _default;
});