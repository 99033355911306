define("dash/components/music-audio-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement() {
      if (sessionStorage.getItem("musicScrollX") && sessionStorage.getItem("musicScrollY")) {
        window.scroll(sessionStorage.getItem("musicScrollX"), sessionStorage.getItem("musicScrollY"));
        sessionStorage.clear();
      }
    },
    actions: {
      delete(playlistItem) {
        this.get('deleteAction')(playlistItem);
      }
    }
  });
  _exports.default = _default;
});