define("dash/services/ajax", ["exports", "ember-ajax/services/ajax", "dash/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _ajax.default.extend({
    dash: Ember.inject.service(),
    host: _environment.default.API.baseUrl,
    namespace: 'api',
    headers: Ember.computed('dash.{currentToken,currentSupportToken}', function () {
      const headers = {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json'
      };
      const supportToken = Ember.get(this, 'dash.currentSupportToken');
      const authToken = Ember.get(this, 'dash.currentToken');
      if (supportToken) {
        Object.assign(headers, {
          authorization: `Bearer ${supportToken}`
        });
      } else if (authToken) {
        Object.assign(headers, {
          authorization: `Bearer ${authToken}`
        });
      }
      return headers;
    })
  });
  _exports.default = _default;
});