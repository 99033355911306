define("dash/routes/devices/device/tools/podcasts/add-rss", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    setupController(controller) {
      this._super(...arguments);
      Ember.set(controller, 'deviceToolId', Ember.get(this.modelFor('devices.device.tools.podcasts'), 'podcastDeviceTool.id'));
    },
    actions: {
      findPodcast() {
        const feedUrl = Ember.get(this, 'controller.feedUrl');
        if (!feedUrl || !feedUrl.length) return;
        const podcast = Ember.get(this, 'store').createRecord('podcast', {
          rssFeedUrl: feedUrl
        });
        return podcast.save().then(podcast => {
          Ember.set(this, 'controller.isInvalidPodcast', false);
          Ember.set(this, 'controller.model.podcast', podcast);
        }).catch(() => {
          Ember.set(this, 'controller.isInvalidPodcast', true);
        });
      },
      follow(podcast) {
        const dashService = Ember.get(this, 'dash');
        const deviceTool = Ember.get(this.modelFor('devices.device'), 'deviceTools').find(deviceTool => {
          return Ember.get(deviceTool, 'tool.namespace') === 'podcasts';
        });
        this.store.createRecord('followed-podcast', {
          podcast,
          deviceTool
        }).save().then(() => {
          dashService.showSuccessMessage(`${podcast.title} was added.`);
          Ember.set(this, 'controller.feedUrl', null);
          this.send('refreshFollowedPodcastModel');
          this.refresh();
        }).catch(errors => dashService.handleServerErrors(errors));
      }
    }
  });
  _exports.default = _default;
});