define("dash/lib/editPassword", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    currentPassword: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 8
    })],
    newPassword: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 8
    })],
    confirmNewPassword: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 8
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'newPassword',
      message: "Passwords don't match"
    })]
  });
  var _default = Ember.Object.extend(Validations, {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });
  _exports.default = _default;
});