define("dash/controllers/account/referrals/signup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    dash: Ember.inject.service(),
    headerText: Ember.computed('showPasswordDialog', function () {
      const showPasswordDialog = Ember.get(this, 'showPasswordDialog');
      return showPasswordDialog ? 'Password' : 'Advocate Signup';
    })
  });
  _exports.default = _default;
});