define("dash/routes/devices/device/tools/notes/view", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_TITLE_LENGTH = 17;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    model() {
      const noteDeviceTool = Ember.get(this.modelFor("devices.device"), "deviceTools").find(deviceTool => {
        return Ember.get(deviceTool, "tool.namespace") === "notes";
      });
      const deviceToolId = Ember.get(noteDeviceTool, "id");
      return Ember.get(this, "store").query("note", {
        device_tool_id: deviceToolId
      }).then(notes => {
        return this.getNotesWithFormattedTitles(notes).then(notesWithFormattedTitles => {
          return {
            noteDeviceTool,
            installed: true,
            notes: notesWithFormattedTitles
          };
        });
      }).catch(errors => Ember.get(this, "dash").handleServerErrors(errors));
    },
    getNotesWithFormattedTitles(notes) {
      return Promise.all(notes.map(note => {
        return this.getFormattedTitle(note).then(formattedTitle => {
          Ember.set(note, "formattedTitle", formattedTitle);
          return note;
        });
      }));
    },
    getFormattedTitle(note) {
      if (note["note_type"] === "text" && note.title === "Untitled") {
        const dashService = Ember.get(this, "dash");
        return dashService.getPreSignedNoteUrl(note.id).then(presignedUrl => {
          return dashService.getNoteText(presignedUrl);
        }).then(noteText => {
          return noteText.length > MAX_TITLE_LENGTH ? `${noteText.slice(0, MAX_TITLE_LENGTH)}...` : noteText;
        }).catch(err => {
          if (err.status == 404) {
            this.refresh();
          } else {
            dashService.handleServerErrors(err);
          }
        });
      }
      return new Promise(resolve => resolve(note.title));
    },
    actions: {
      refreshNotesModel() {
        this.refresh();
      },
      delete(note) {
        const noteTitle = Ember.get(note, 'title');
        const dashService = Ember.get(this, 'dash');
        let message = `Are you sure you'd like to delete '${noteTitle}'?`;
        if (confirm(message) == true) {
          note.destroyRecord().then(() => {
            dashService.showSuccessMessage(`${noteTitle} was deleted.`);
            this.refresh();
          }).catch(errors => dashService.handleServerErrors(errors));
        }
      }
    }
  });
  _exports.default = _default;
});