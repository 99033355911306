define("dash/initializers/autoresize", ["exports", "ember-autoresize/ext/text-field", "ember-autoresize/ext/text-area"], function (_exports, _textField, _textArea) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "autoresize",
    initialize: function () {}
  };
  _exports.default = _default;
});