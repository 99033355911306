define("dash/initializers/ember-form-for-i18n", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  const {
    getOwner
  } = Ember;
  function initialize(app) {
    // HACK: This can be undefined in the FastBoot environment.
    let owner = getOwner(app) || app.__container__;
    if (!owner) {
      return;
    }
    let i18n = owner.lookup('service:i18n');
    if (!i18n) {
      return;
    }
    app.inject('component', 'i18n', 'service:i18n');
  }
  var _default = {
    name: 'ember-form-for-i18n',
    initialize
  };
  _exports.default = _default;
});