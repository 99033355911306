define("dash/serializers/application", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.JSONAPISerializer.extend({
    keyForAttribute: function (attr) {
      return Ember.String.underscore(attr);
    },
    keyForRelationship: function (attr) {
      return Ember.String.underscore(attr);
    },
    payloadKeyFromModelName(modelName) {
      return Ember.String.underscore((0, _emberInflector.pluralize)(modelName));
    }
  });
  _exports.default = _default;
});