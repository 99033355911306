define("dash/constants/ExternalLinks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    SUPPORT: {
      DEFAULT: 'https://support.thelightphone.com',
      alarm: 'https://support.thelightphone.com/hc/en-us/articles/360031126871-Alarm',
      calculator: 'https://support.thelightphone.com/hc/en-us/articles/360039579752',
      directions: 'https://support.thelightphone.com/hc/en-us/articles/4402123489300',
      notes: 'https://support.thelightphone.com/hc/en-us/articles/8418578128404-Notes-Tool',
      magic8ball: 'https://support.thelightphone.com/hc/en-us/articles/20499491784596/preview/eyJhbGciOiJIUzI1NiJ9.eyJpZCI6MjA0OTk0OTE3ODQ1OTYsInJldmlzaW9uX2lkIjoiMDFIRTM1UERYOEcyN0ZKOUhTTkNUQVY5OEEiLCJleHAiOjE2OTg3Njk4MTB9.ayu5gB8xk0PiJpWd40uRXYRgGSH_IxzRgYHt0ofJYdk',
      timer: 'https://support.thelightphone.com/hc/en-us/articles/24571548717716-Timer-Tool',
      calendar: 'https://support.thelightphone.com/hc/en-us/articles/13711965991188-Calendar-Tool',
      podcasts: 'https://support.thelightphone.com/hc/en-us/articles/360045122851-Podcast-Tool',
      directory: 'https://support.thelightphone.com/hc/en-us/articles/26836340716180-Directory-Tool',
      directory_terms: 'https://support.thelightphone.com/hc/en-us/articles/26837520448916-Directory-Tool-Terms-Conditions'
    },
    PORTING_SUPPORT: {
      DEFAULT: 'https://support.thelightphone.com/hc/en-us/articles/360036151711',
      ATT: 'https://support.thelightphone.com/hc/en-us/articles/360038640392',
      LEAVING: 'https://support.thelightphone.com/hc/en-us/articles/360039033131'
    },
    SIM: 'https://www.thelightphone.com/cart/sim',
    DEVICE_MISSING: 'https://support.thelightphone.com/hc/en-us/articles/360036074752',
    INSERT_SIM: 'https://support.thelightphone.com/hc/en-us/articles/360021918972-Inserting-an-Active-SIM-Card',
    TAPFILIATE: 'https://thelightphone.tapfiliate.com/login',
    GIGS: {
      SIGNUP: 'https://lightphone.gigs.com',
      SIGNIN: 'https://lightphone.gigs.com/sign-in'
    }
  };
  _exports.default = _default;
});