define("dash/templates/devices/device/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "UbtdYJ1Z",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\"],[[23,[\"model\",\"formattedPhoneNumber\"]],[23,[\"backPath\"]],\"home\"]],{\"statements\":[[4,\"if\",[[27,\"or\",[[23,[\"model\",\"error\"]],[27,\"not\",[[23,[\"links\",\"length\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[0,\"Unable to find this device.\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"menu-list\",null,[[\"links\"],[[23,[\"links\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/device/index.hbs"
    }
  });
  _exports.default = _default;
});