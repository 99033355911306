define("dash/controllers/devices/device/tools/podcasts/add-rss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    dash: Ember.inject.service(),
    queryParams: ['feedUrl'],
    feedUrl: null,
    needsUrl: Ember.computed('feedUrl', function () {
      return Ember.isEmpty(Ember.get(this, 'feedUrl'));
    }),
    isInvalidPodcast: false
  });
  _exports.default = _default;
});