define("dash/validators/disallow-po-boxes", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DisallowPoBoxes = _base.default.extend({
    validate(value) {
      const addressContainsPoBox = value.replace('.', '').toLowerCase().includes('po box');
      return addressContainsPoBox ? 'Address cannot be a PO box' : true;
    }
  });
  DisallowPoBoxes.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor( /* attribute, options */
    ) {
      return [];
    }
  });
  var _default = DisallowPoBoxes;
  _exports.default = _default;
});