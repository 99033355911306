define("dash/lib/authenticatedRoute", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    dash: Ember.inject.service(),
    beforeModel() {
      const currentUser = Ember.get(this, 'dash.currentUser');
      // Redirect to Login if not authenticated
      if (!currentUser) {
        this.transitionTo('login');
      }
      // Redirect to Onboarding if authenticated
      // but lacking name attributes
      if (!Ember.get(currentUser, 'givenName') || !Ember.get(currentUser, 'familyName')) {
        this.transitionTo('onboarding');
      }
    }
  });
  _exports.default = _default;
});