define("dash/router", ["exports", "dash/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route("login");
    this.route("signup");
    this.route("home", {
      path: "/"
    });
    this.route("account", function () {
      this.route("show", {
        path: "/"
      });
      this.route("referrals", function () {
        this.route("intro", {
          path: "/intro"
        });
        this.route("terms", {
          path: "/terms"
        });
        this.route("signup", {
          path: "/signup"
        });
        this.route("paypal", {
          path: "/paypal"
        });
        this.route("confirm", {
          path: "/confirm"
        });
        this.route("metrics", {
          path: "/metrics"
        });
      });
      this.route("edit", function () {
        this.route("password", {
          path: "/password"
        });
      });
    });
    this.route("devices", function () {
      this.route("device", {
        path: "/:device_id"
      }, function () {
        this.route("info");
        this.route("settings", function () {
          this.route("show", {
            path: "/"
          });
          this.route("about-default-location", {
            path: "/about-default-location"
          });
          this.route("set-default-location", {
            path: "/set-default-location"
          });
        });
        this.route("contacts", function () {
          this.route("new");
          this.route("actions", {
            path: "/actions"
          });
          this.route("import", function () {
            this.route("google");
            this.route("icloud");
            this.route("outlook");
            this.route("other");
          });
          this.route("export");
          this.route("contact", {
            path: "/:contact_id"
          }, function () {
            this.route("delete");
          });
          this.route("delete-all", {
            path: "/delete-all"
          });
        });
        this.route("history");
        this.route("tools", function () {
          this.route("podcasts", function () {
            this.route("edit");
            this.route("add");
            this.route("add-rss");
          });
          this.route("calendar", function () {
            this.route("add", function () {
              this.route("icloud");
              this.route("google");
              this.route("caldav");
            });
          });
          this.route("magic8ball", function () {});
          this.route("music", function () {
            this.route("edit", function () {
              this.route("audio", {
                path: "/:audio_id"
              });
            });
            this.route("add");
            this.route("delete-all");
          });
          this.route("directions", function () {
            this.route("terms-and-conditions");
            this.route("set-default-location");
          });
          this.route("directory", function () {
            this.route("terms-and-conditions");
            this.route("set-default-location");
          });
          this.route("notes", function () {
            this.route("add");
            this.route("view", function () {
              this.route("note", {
                path: "/:note_id"
              });
              this.route("audio-note", {
                path: "/:audio_note_id"
              });
            });
            this.route("search");
            this.route("delete-all");
          });
          this.route("tool", {
            path: "/:namespace"
          });
          this.route("add");
        });
      });
    });
    this.route("services");
    this.route("reset");
    this.route("forgot");
    this.route("onboarding");
    this.route("download-note", {
      path: "download-note/:note_id"
    });
    this.route("akko-insurance", function () {
      this.route("associate-imeis", {
        path: "associate-imeis"
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});