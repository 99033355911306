define("dash/components/default-location-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    dash: Ember.inject.service(),
    store: Ember.inject.service(),
    searchResults: null,
    searchTerm: null,
    selectedAddress: null,
    isEmptySearchResults: Ember.computed('searchResults', function () {
      return Ember.get(this, 'searchResults') && Ember.get(this, 'searchResults') === 0;
    }),
    noAddressSelected: Ember.computed('selectedAddress', function () {
      return !Ember.get(this, 'selectedAddress');
    }),
    init() {
      this._super(...arguments);
    },
    actions: {
      onUpdateValue(address, field, val) {
        if (field === '_state') {
          this.applyStateToAddress(val);
        } else if (field === '_country') {
          this.applyCountryToAddress(val);
        } else {
          Ember.set(address, field, val);
        }
        Ember.set(this, 'address', address);
      },
      search() {
        const searchTerm = Ember.get(this, 'searchTerm');
        return Ember.get(this, 'dash').performAddressSearch(searchTerm).then(addresses => {
          Ember.set(this, 'searchResults', addresses);
        });
      },
      selectAddress(selectedAddressId) {
        const addresses = this.store.peekAll('address');
        const updatedSelections = addresses.map(address => {
          const updatedAddress = {
            id: address.id,
            type: 'address',
            attributes: {
              title: address.title,
              address: address.address,
              position: address.position
            }
          };
          if (address.id === selectedAddressId) {
            Ember.set(this, 'selectedAddress', updatedAddress);
            Ember.set(updatedAddress, 'attributes.selected', true);
            return updatedAddress;
          }
          Ember.set(updatedAddress, 'attributes.selected', false);
          return updatedAddress;
        });
        this.store.push({
          data: updatedSelections
        });
      },
      submit() {
        const parentSubmitFunction = this.get('submitLocation');
        const selectedAddress = Ember.get(this, 'selectedAddress');
        parentSubmitFunction(selectedAddress);
      }
    }
  });
  _exports.default = _default;
});