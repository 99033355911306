define("dash/components/attribute-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['AttributeList', 'container', 'text-center', 'heading', 'h100', 'flex-1']
  });
  _exports.default = _default;
});