define("dash/templates/account/edit/password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4BO4fL1V",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\"],[\"Edit Password\",\"account.edit\",\"home\"]],{\"statements\":[[4,\"form-for\",[[23,[\"model\"]]],[[\"submit\"],[[27,\"route-action\",[\"updatePassword\"],null]]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"Input--primary text-left mb3\"],[9],[0,\"\\n      \"],[1,[27,\"component\",[[22,1,[\"password-field\"]],\"currentPassword\"],[[\"label\",\"errorClasses\"],[\"Current Password\",\"validation-error\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"Input--primary text-left mb3\"],[9],[0,\"\\n      \"],[1,[27,\"component\",[[22,1,[\"password-field\"]],\"newPassword\"],[[\"label\",\"errorClasses\"],[\"New Password\",\"validation-error\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"Input--primary text-left\"],[9],[0,\"\\n      \"],[1,[27,\"component\",[[22,1,[\"password-field\"]],\"confirmNewPassword\"],[[\"label\",\"errorClasses\"],[\"Confirm New Password\",\"validation-error\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"Button--hollow block mxauto mt8 mb_5\"],[12,\"onClick\",[27,\"route-action\",[\"updatePassword\"],null]],[12,\"disabled\",[27,\"or\",[[27,\"not\",[[23,[\"model\",\"validations\",\"isValid\"]]],null],[23,[\"model\",\"isSaving\"]]],null]],[9],[0,\"\\n      \"],[4,\"if\",[[23,[\"model\",\"isSaving\"]]],null,{\"statements\":[[0,\"Updating...\"]],\"parameters\":[]},{\"statements\":[[0,\"save\"]],\"parameters\":[]}],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/account/edit/password.hbs"
    }
  });
  _exports.default = _default;
});