define("dash/templates/devices/device/tools/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9OafquL7",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\"],[\"Additional Tools\",\"devices.device.tools\",\"home\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[27,\"or\",[[23,[\"model\",\"error\"]],[27,\"not\",[[23,[\"links\",\"length\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[0,\"You've added all available tools. Nice!\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"menu-list\",null,[[\"links\"],[[23,[\"links\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/device/tools/add.hbs"
    }
  });
  _exports.default = _default;
});