define("dash/templates/devices/device/contacts/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+3lU5BP6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backAction\",\"cancelPath\"],[\"Add Manually\",[27,\"route-action\",[\"onCancel\",\"devices.device.contacts.actions\"],null],\"home\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"flex flex-col items-center justify-between p1 md:py4 w100 text-center\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"error\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\"],[9],[0,\"Somehing went wrong! Please try again.\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"contact-form\",null,[[\"contact\",\"saveContact\",\"submissionError\"],[[23,[\"model\"]],[27,\"route-action\",[\"saveContact\"],null],[23,[\"submissionError\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/device/contacts/new.hbs"
    }
  });
  _exports.default = _default;
});