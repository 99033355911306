define("dash/routes/devices/device/contacts/import/index", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    model() {
      return this.modelFor('devices.device');
    },
    afterModel(device) {
      if (!device || !Ember.get(device, 'id')) this.transitionTo('devices');
    },
    setupController(controller, device) {
      this._super(...arguments);
      Ember.set(controller, 'links', this.getMenuLinks(device));
    },
    getMenuLinks(device) {
      const deviceId = Ember.get(device, 'id');
      return [{
        text: 'iCloud',
        path: 'devices.device.contacts.import.icloud',
        data: deviceId
      },
      //{
      //  text: 'Google',
      //  path: 'devices.device.contacts.import.google',
      //  data: deviceId,
      //},
      //{
      //  text: 'Outlook',
      //  path: 'devices.device.contacts.import.outlook',
      //  data: deviceId,
      //},
      {
        text: 'Other',
        path: 'devices.device.contacts.import.other',
        data: deviceId
      }];
    }
  });
  _exports.default = _default;
});