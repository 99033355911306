define("dash/templates/services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "E701vkDN",
    "block": "{\"symbols\":[\"subscription\"],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\"],[[22,0,[\"model\",\"currentUser\",\"fullName\"]],\"home\",\"home\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"container--wide text-center\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex flex-col items-center m2\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"subscriptions\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"mb3\"],[9],[0,\"\\n\"],[4,\"each\",[[22,0,[\"model\",\"subscriptions\"]]],null,{\"statements\":[[0,\"            \"],[1,[27,\"subscription-detail\",null,[[\"subscription\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"mb1\"],[9],[0,\"\\n          \"],[7,\"a\"],[12,\"href\",[23,[\"ExternalLinks\",\"GIGS\",\"SIGNIN\"]]],[11,\"class\",\"heading pointer hover-gray-light my4 md:my1\"],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener\"],[9],[0,\"\\n            Manage / Cancel Service Plan\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"a\"],[12,\"href\",[23,[\"ExternalLinks\",\"GIGS\",\"SIGNUP\"]]],[11,\"class\",\"heading pointer hover-gray-light my4 md:my1\"],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener\"],[9],[0,\"\\n        Activate a New SIM\\n      \"],[10],[0,\"\\n      \"],[7,\"a\"],[12,\"href\",[23,[\"ExternalLinks\",\"SIM\"]]],[11,\"class\",\"heading pointer hover-gray-light my4 md:my1\"],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener\"],[9],[0,\"\\n        Request a Light SIM\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/services.hbs"
    }
  });
  _exports.default = _default;
});