define("dash/templates/components/playlist-item-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZhuILLzn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"flex flex-row items-center mt1\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"playlist-table-button Button--list-item-icon\"],[12,\"onClick\",[21,\"deleteAction\"]],[12,\"hidden\",[27,\"not\",[[23,[\"playlistItem\",\"audio\"]]],null]],[11,\"draggable\",\"false\"],[9],[0,\"\\n    \"],[7,\"img\"],[11,\"src\",\"/assets/images/icons/warning.svg\"],[11,\"alt\",\"remove playlist item\"],[11,\"class\",\"wh100\"],[11,\"draggable\",\"false\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"link-to\",[\"devices.device.tools.music.edit.audio\",[23,[\"playlistItem\",\"audio\",\"id\"]]],[[\"class\"],[\"flex flex-row playlist-table-row pointer hover-gray-light\"]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"heading playlist-table-song\"],[11,\"draggable\",\"false\"],[9],[0,\"\\n      \"],[1,[23,[\"playlistItem\",\"audio\",\"title\"]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"heading playlist-table-artist\"],[11,\"draggable\",\"false\"],[9],[0,\"\\n      \"],[1,[23,[\"playlistItem\",\"audio\",\"artist\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/components/playlist-item-row.hbs"
    }
  });
  _exports.default = _default;
});