define("dash/components/transition-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNameBindings: ['isLoaded:TransitionLoader--loaded:TransitionLoader'],
    imageIsLoaded: false,
    didRender() {
      this._super(...arguments);
      const imgUrl = Ember.get(this, 'imgUrl');
      if (!imgUrl) return;
      const loader = new window.Image();
      loader.src = '';
      loader.onload = () => this.onLoad();
      loader.src = imgUrl;
    },
    onLoad() {
      if (Ember.get(this, 'isDestroyed') || Ember.get(this, 'isDestroying')) return;
      Ember.set(this, 'imageIsLoaded', true);
    },
    isLoaded: Ember.computed('{imgUrl,imageIsLoaded,hasRequiredData}', function () {
      const imageIsLoaded = !Ember.get(this, 'imgUrl') || Ember.get(this, 'imageIsLoaded');
      const hasRequiredData = Ember.get(this, 'hasRequiredData');
      return imageIsLoaded && (!!hasRequiredData || typeof hasRequiredData === 'undefined');
    }),
    finalContentClasses: Ember.computed('contentClasses', function () {
      const contentClasses = Ember.get(this, 'contentClasses') || '';
      return `TransitionLoader__content ${contentClasses}`;
    })
  });
  _exports.default = _default;
});