define("dash/templates/devices/device/contacts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1oRXFzs2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\",\"withoutContainer\"],[\"Edit Contacts\",\"devices.device.contacts.actions\",\"home\",[27,\"not-eq\",[[23,[\"model\",\"length\"]],0],null]]],{\"statements\":[[4,\"if\",[[23,[\"model\",\"error\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"flex flex-1 md:justify-center\"],[9],[0,\"\\n      \"],[7,\"p\"],[9],[0,\"There was a problem finding your contacts.\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"model\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"flex flex-1 md:justify-center pb4\"],[9],[0,\"\\n\"],[4,\"transition-loader\",null,[[\"tagName\",\"imgUrl\",\"contentClasses\",\"hasRequiredData\"],[\"ul\",\"/assets/images/icons/warning.svg\",\"container\",[23,[\"model\",\"isLoaded\"]]]],{\"statements\":[[0,\"        \"],[1,[27,\"contact-list\",null,[[\"contacts\"],[[23,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"container--wide text-center\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"heading\"],[9],[0,\"\\n        no contacts yet\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/device/contacts/index.hbs"
    }
  });
  _exports.default = _default;
});