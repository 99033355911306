define("dash/components/note-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement() {
      if (sessionStorage.getItem("noteScrollX") && sessionStorage.getItem("noteScrollY")) {
        window.scroll(sessionStorage.getItem("noteScrollX"), sessionStorage.getItem("noteScrollY"));
        sessionStorage.clear();
      }
    },
    actions: {
      delete(note) {
        this.get('deleteAction')(note);
      }
    }
  });
  _exports.default = _default;
});