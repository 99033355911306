define("dash/models/device", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  const VERSION_NUMBER_REGEX = /^\d+/;
  var _default = Model.extend({
    imei: attr('string'),
    phoneNumber: attr('string'),
    deviceModel: attr('string'),
    lightOsVersionName: attr('string'),
    sku: attr('string'),
    sim: belongsTo('sim', {
      async: false
    }),
    user: belongsTo('user'),
    deviceToolLocation: belongsTo('device-tool-location'),
    contacts: hasMany('contact'),
    deviceTools: hasMany('device-tool'),
    formattedPhoneNumber: Ember.computed('sim', 'imei', function () {
      const simFormatted = Ember.get(this, 'sim.formattedPhoneNumber');
      if (simFormatted && simFormatted.length) return simFormatted;
      return Ember.get(this, 'imei');
    }),
    softwareVersion: Ember.computed('lightosVersionName', function () {
      const versionName = Ember.get(this, 'lightOsVersionName');
      if (versionName && VERSION_NUMBER_REGEX.test(versionName)) {
        const match = VERSION_NUMBER_REGEX.exec(versionName)[0];
        if (match) return parseInt(match);
      }
      return 0;
    })
  });
  _exports.default = _default;
});