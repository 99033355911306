define("dash/models/note", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var _default = Model.extend({
    title: attr('string'),
    updated_at: attr('string'),
    note_type: attr('string'),
    deviceTool: belongsTo('device-tool'),
    file: belongsTo('file'),
    is_text: Ember.computed('note_type', function () {
      var note_type = Ember.get(this, 'note_type');
      if (note_type == "text") {
        return true;
      }
      return false;
    })
  });
  _exports.default = _default;
});