define("dash/components/dash-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['DashPage', 'wrapper', 'flex', 'flex-col'],
    isMagic8Ball: Ember.computed('heading', function () {
      const heading = Ember.get(this, 'heading');
      return heading === "Language";
    })
  });
  _exports.default = _default;
});