define("dash/routes/devices/device/tools/directions", ["exports", "dash/lib/authenticatedRoute", "dash/constants/ExternalLinks"], function (_exports, _authenticatedRoute, _ExternalLinks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    needsApkUpgrade: Ember.computed("model.{tool.minApkVersion,device.softwareVersion}", function () {
      const minApkVersion = Ember.get(this.model, "tool.minApkVersion") || 0;
      const currentApkVersion = Ember.get(this.model, "device.softwareVersion");
      return !currentApkVersion || currentApkVersion < minApkVersion;
    }),
    model() {
      const directionsDeviceTool = Ember.get(this.modelFor("devices.device"), "deviceTools").find(deviceTool => {
        return Ember.get(deviceTool, "tool.namespace") === "directions";
      });
      const device = this.modelFor("devices.device");
      const tool = Ember.get(this, "store").peekAll("tool").find(tool => tool.namespace === "directions");
      if (!directionsDeviceTool) {
        return {
          installed: false,
          device: this.modelFor("devices.device"),
          tool,
          supportLink: _ExternalLinks.default.SUPPORT.directions,
          existingDeviceToolLocationId: Ember.get(device, "deviceToolLocation.id")
        };
      }
      return {
        tool,
        installed: true,
        device: this.modelFor("devices.device"),
        supportLink: _ExternalLinks.default.SUPPORT.directions,
        existingDeviceToolLocationId: Ember.get(device, "deviceToolLocation.id"),
        minApkVersion: tool.minApkVersion
      };
    },
    actions: {
      viewTermsAndConditions() {
        this.transitionTo("devices.device.tools.directions.terms-and-conditions");
      }
    }
  });
  _exports.default = _default;
});