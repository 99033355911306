define("dash/routes/devices/device/tools/index", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    model() {
      return this.modelFor('devices.device.tools');
    },
    actions: {
      uninstall(deviceTool) {
        const dashService = Ember.get(this, 'dash');
        const toolTitle = Ember.get(deviceTool, 'tool.title');
        deviceTool.destroyRecord().then(() => {
          dashService.showSuccessMessage(`${toolTitle} was removed from your device.`);
          this.refresh();
        }).catch(errors => dashService.handleServerErrors(errors));
      }
    }
  });
  _exports.default = _default;
});