define("dash/models/file", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var _default = Model.extend({
    position: attr('number'),
    bucket: attr('string'),
    key: attr('string'),
    presigned_url: attr('string'),
    uploaded_at: attr('string'),
    content_type: attr('string'),
    audio: belongsTo('audio', {
      async: false
    })
  });
  _exports.default = _default;
});