define("dash/controllers/devices/device/tools/music/delete-all", ["exports", "@sentry/browser"], function (_exports, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    init(...args) {
      this._super(...args);
    },
    upload: Ember.inject.service(),
    dash: Ember.inject.service(),
    actions: {
      deleteAll: async function () {
        const uploadService = Ember.get(this, 'upload');
        const dashService = Ember.get(this, 'dash');
        uploadService.deleteAll(Ember.get(this, 'deviceToolId')).then(() => {
          dashService.showSuccessMessage('All songs deleted from device.');
          this.transitionToRoute('devices.device.tools.music');
        }).catch(err => {
          Sentry.captureException(err);
          dashService.showWarningMessage('All songs have been flagged for deletion, it may take a couple minutes for this operation to complete.');
          this.transitionToRoute('devices.device.tools.music');
        });
      }
    }
  });
  _exports.default = _default;
});