define("dash/controllers/devices/device/tools/notes/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    dash: Ember.inject.service(),
    queryParams: ['searchTerm'],
    searchTerm: null,
    promptForSearch: Ember.computed('searchTerm', 'model.searchedTerm', function () {
      let searchTerm = Ember.get(this, 'searchTerm');
      let searchedTerm = Ember.get(this, 'model.searchedTerm');
      if (Ember.isEmpty(searchTerm)) {
        return true;
      } else {
        return searchedTerm != searchTerm;
      }
    })
  });
  _exports.default = _default;
});