define("dash/routes/devices/device/settings/show", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    setupController(controller) {
      this._super(...arguments);
      const device = this.modelFor("devices.device");
      return Ember.set(controller, "existingDeviceToolLocation", Ember.get(device, "deviceToolLocation"));
    }
  });
  _exports.default = _default;
});