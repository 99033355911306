define("dash/templates/devices/device/settings/about-default-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "HB5APk8V",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\"],[\"About Default Location\",\"devices.device.settings.show\",\"home\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"flex flex-col\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"sub-heading text-center\"],[9],[0,\"\\n      This global setting is shared between the Directions and Directory tools, which you do not currently have installed.\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/device/settings/about-default-location.hbs"
    }
  });
  _exports.default = _default;
});