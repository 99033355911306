define("dash/components/calendar-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    dash: Ember.inject.service(),
    calendar: Ember.inject.service(),
    classNames: ['CalendarForm', 'container--wide', 'text-center', 'mt2_5', 'md:px0'],
    hasValidationError: false,
    isSaving: false,
    init() {
      this._super(...arguments);
    },
    actions: {
      onUpdateValue(calendarAccount, field, val) {
        Ember.set(calendarAccount, field, val);
      },
      submit(evt) {
        if (evt) evt.preventDefault();
        Ember.get(this, 'calendarAccount').validate().then(({
          validations
        }) => {
          const isValid = Ember.get(validations, 'isValid');
          Ember.set(this, 'hasValidationError', !isValid);
          if (isValid) {
            const calendarAccount = Ember.get(this, 'calendarAccount');
            const username = calendarAccount.account;
            const password = calendarAccount.appPassword;
            const deviceId = Ember.get(this, 'deviceId');
            Ember.set(this, 'isSaving', true);
            const dashService = Ember.get(this, 'dash');
            const calendarService = Ember.get(this, 'calendar');
            return calendarService.createCalendar(deviceId, username, password, Ember.get(this, 'type')).then(() => {
              Ember.set(this, 'isSaving', false);
              Ember.get(this, 'onCompleteSubmit')();
            }).catch(errors => {
              Ember.set(this, 'isSaving', false);
              if (errors.status === 404) {
                return dashService.showWarningMessage("Your device must be online to add a new calendar. Please restart your Light Phone and try again.");
              }
              if (errors.status === 408) {
                return dashService.showWarningMessage("An timeout occurred while sending this information to your device. Please try again.");
              }
              if (errors.status === 500) {
                return dashService.showWarningMessage("An unexpected error occurred while sending this information to your device. Please try again.");
              }
              return dashService.handleServerErrors(errors);
            });
          }
        });
      }
    }
  });
  _exports.default = _default;
});