define("dash/controllers/devices/device/tools/notes/view/note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_LENGTH = 35;
  var _default = Ember.Controller.extend({
    dash: Ember.inject.service(),
    needsNote: Ember.computed('model.noteText', function () {
      return Ember.isEmpty(Ember.get(this, 'model.noteText'));
    }),
    editingTitle: false,
    headingText: Ember.computed('editingTitle', 'model.note.title', function () {
      let title = Ember.get(this, 'model.note.title');
      let editingTitle = Ember.get(this, 'editingTitle');
      if (editingTitle) {
        return 'Edit Note Title';
      }
      if (Ember.isEmpty(title)) {
        return 'Not Found';
      }
      return title.length > MAX_LENGTH ? `${title.slice(0, MAX_LENGTH)}...` : title;
    })
  });
  _exports.default = _default;
});