define("dash/routes/devices/device/tools/directions/set-default-location", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    beforeModel() {
      const {
        installed
      } = this.modelFor('devices.device.tools.directions');
      // redirects to directions page if the tool is already installed
      if (installed) {
        this.transitionTo('devices.device.tools.directions');
      }
    },
    setupController(controller) {
      this._super(...arguments);
      // hack since we can't pass closured action from route to component
      // and we need access to 'this' in installDirectoryTool
      controller.set('thisClosure', this);
    },
    actions: {
      installDirectionsTool(selectedAddress) {
        const {
          attributes: {
            address,
            position: {
              lat: latitude,
              lng: longitude
            }
          }
        } = selectedAddress;
        const dashService = Ember.get(this, 'dash');
        const tool = Ember.get(this.modelFor('devices.device.tools.directions'), 'tool');
        return dashService.createDirectionsOrDirectoryTool({
          toolId: Ember.get(tool, 'id'),
          deviceId: Ember.get(this.modelFor('devices.device'), 'id'),
          address,
          latitude,
          longitude
        }).then(() => {
          Ember.getOwner(this).lookup('route:devices').refresh();
        }).then(() => {
          dashService.showSuccessMessage(`${tool.title} was added to your device.`);
          this.transitionTo('devices.device.tools');
        }).catch(errors => dashService.handleServerErrors(errors));
      }
    }
  });
  _exports.default = _default;
});