define("dash/routes/devices/device/tools/magic8ball/index", ["exports", "dash/lib/authenticatedRoute", "dash/constants/ExternalLinks"], function (_exports, _authenticatedRoute, _ExternalLinks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    model() {
      const magic8BallDeviceTool = Ember.get(this.modelFor("devices.device"), "deviceTools").find(deviceTool => {
        return Ember.get(deviceTool, "tool.namespace") === "magic8ball";
      });
      if (!magic8BallDeviceTool) {
        return {
          installed: false,
          device: this.modelFor("devices.device"),
          supportLink: _ExternalLinks.default.SUPPORT.magic8ball,
          tool: Ember.get(this, "store").peekAll("tool").find(tool => tool.namespace === "magic8ball")
        };
      }
      return {
        magic8BallDeviceTool,
        supportLink: _ExternalLinks.default.SUPPORT.magic8ball,
        installed: true
      };
    }
  });
  _exports.default = _default;
});