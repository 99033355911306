define("dash/routes/devices/device/index", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    model() {
      return this.modelFor('devices.device');
    },
    setupController(controller, device) {
      this._super(...arguments);
      Ember.set(controller, 'backPath', this.getBackPath());
      Ember.set(controller, 'links', this.getMenuLinks(device));
    },
    getMenuLinks(device) {
      const deviceId = Ember.get(device || {}, 'id');
      if (!deviceId) return [];
      return [{
        text: 'Contacts',
        path: 'devices.device.contacts.actions',
        data: deviceId
      }, {
        text: 'Toolbox',
        path: 'devices.device.tools',
        data: deviceId
      }, {
        text: 'Settings',
        path: 'devices.device.settings',
        data: deviceId
      }, {
        text: 'Device Info',
        path: 'devices.device.info',
        data: deviceId
      }
      //{ text: 'History', path: 'devices.device.history', data: deviceId },
      ];
    },

    getBackPath() {
      const numberOfDevices = Ember.get(this.modelFor('devices'), 'length');
      return numberOfDevices === 1 ? 'home' : 'devices.index';
    }
  });
  _exports.default = _default;
});