define("dash/routes/devices/device/tools/directory/terms-and-conditions", ["exports", "dash/lib/authenticatedRoute", "dash/constants/ExternalLinks"], function (_exports, _authenticatedRoute, _ExternalLinks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    setupController(controller) {
      this._super(...arguments);
      const deviceId = Ember.get(this.modelFor('devices.device'), 'id');
      Ember.set(controller, 'deviceId', deviceId);
      const {
        existingDeviceToolLocationId
      } = this.modelFor('devices.device.tools.directory');
      Ember.set(controller, 'existingDeviceToolLocationId', existingDeviceToolLocationId);
    },
    model() {
      return {
        termsLink: _ExternalLinks.default.SUPPORT.directory_terms
      };
    },
    actions: {
      continue() {
        const {
          installed,
          existingDeviceToolLocationId
        } = this.modelFor('devices.device.tools.directory');
        // redirects to directory page if the tool is already installed
        if (installed) {
          this.transitionTo('devices.device.tools.directory');
        } else {
          if (existingDeviceToolLocationId) {
            const dashService = Ember.get(this, 'dash');
            const tool = Ember.get(this.modelFor('devices.device.tools.directory'), 'tool');
            return dashService.createDirectionsOrDirectoryTool({
              toolId: Ember.get(tool, 'id'),
              deviceId: Ember.get(this.modelFor('devices.device'), 'id'),
              deviceToolLocationId: existingDeviceToolLocationId
            }).then(() => {
              Ember.getOwner(this).lookup('route:devices').refresh();
            }).then(() => {
              dashService.showSuccessMessage(`${tool.title} was added to your device.`);
              this.transitionTo('devices.device.tools');
            }).catch(errors => dashService.handleServerErrors(errors));
          } else {
            this.transitionTo('devices.device.tools.directory.set-default-location');
          }
        }
      }
    }
  });
  _exports.default = _default;
});