define("dash/routes/tools", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    model() {
      return Ember.get(this, 'store').findAll('device').catch(errors => Ember.get(this, 'dash').handleServerErrors(errors));
    }
  });
  _exports.default = _default;
});