define("dash/models/tool", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var _default = Model.extend({
    title: attr('string'),
    namespace: attr('string'),
    status: attr('string'),
    minApkVersion: attr('number'),
    defaultConfig: attr('raw'),
    isAvailable: Ember.computed('status', function () {
      const status = Ember.get(this, 'status');
      return status === 'available' || status === 'exclusive';
    })
  });
  _exports.default = _default;
});