define("dash/helpers/truncate-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.truncateString = truncateString;
  function truncateString(params /*, hash*/) {
    const string = params && params[0] || "";
    const maxLength = params && params[1] || Number.MAX_SAFE_INTEGER;
    return string.length > maxLength ? `${string.slice(0, maxLength)}...` : string;
  }
  var _default = Ember.Helper.helper(truncateString);
  _exports.default = _default;
});