define("dash/lib/activation", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    imei: (0, _emberCpValidations.validator)('presence', true)
  });
  var _default = Ember.Object.extend(Validations, {
    imei: '',
    serialize() {
      if (!Ember.get(this, 'validations.isValid')) return {};
      return {
        imei: Ember.get(this, 'imei')
      };
    }
  });
  _exports.default = _default;
});