define("dash/app", ["exports", "dash/resolver", "ember-load-initializers", "dash/config/environment", "@sentry/browser", "@sentry/integrations"], function (_exports, _resolver, _emberLoadInitializers, _environment, Sentry, Integrations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  if (_environment.default.APP.isProd) {
    Sentry.init({
      dsn: 'https://ea7c0443041d445ea3a418923a9c78d0@sentry.io/1773742',
      integrations: [new Integrations.Ember()],
      beforeSend(event, hint) {
        if (hint && hint.originalException && hint.originalException.message && hint.originalException.message.includes('TransitionAborted')) {
          // don't send these events to Sentry as they are not unexpected errors,
          // ember-cli-sentry and ember-new-relic both manually filter them out
          // since ember 2.x + started propogating these errors up
          return null;
        } else {
          return event;
        }
      }
    });
  }
  const App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});