define("dash/routes/devices/device/contacts/index", ["exports", "dash/lib/authenticatedRoute", "dash/lib/contactsCache"], function (_exports, _authenticatedRoute, _contactsCache) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    model() {
      const device = this.modelFor('devices.device');
      const cached = _contactsCache.default.getAll(device);
      if (cached) return cached;
      return Ember.get(this, 'store').query('contact', {
        device_id: Ember.get(device, 'id')
      }).then(contacts => {
        const resp = _contactsCache.default.setAll(device, contacts);
        return resp;
      }).catch(errors => {
        return Ember.get(this, 'dash').handleServerErrors(errors);
      });
    },
    actions: {
      willTransition(transition) {
        if (transition.targetName == "devices.device.contacts.contact.index" || transition.targetName == "devices.device.contacts.contact.delete") {
          sessionStorage.setItem('contactScrollX', window.scrollX);
          sessionStorage.setItem('contactScrollY', window.scrollY);
        }
        return true;
      }
    }
  });
  _exports.default = _default;
});