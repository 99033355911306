define("dash/routes/devices/device/tools/music/edit/index", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    setupController(controller) {
      this._super(...arguments);
      const deviceToolId = Ember.get(this.modelFor('devices.device.tools.music'), 'musicDeviceTool.id');
      return Ember.set(controller, 'deviceToolId', deviceToolId);
    },
    model() {
      return this.modelFor('devices.device.tools.music.edit');
    },
    actions: {
      willTransition(transition) {
        if (transition.targetName == "devices.device.tools.music.edit.audio") {
          sessionStorage.setItem('musicScrollX', window.scrollX);
          sessionStorage.setItem('musicScrollY', window.scrollY);
        }
        return true;
      }
    }
  });
  _exports.default = _default;
});