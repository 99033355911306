define("dash/templates/devices/device/tools/notes/view/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xnqlEFrt",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\",\"withoutContainer\"],[\"View Notes\",\"devices.device.tools.notes\",\"home\",true]],{\"statements\":[[4,\"if\",[[23,[\"model\",\"notes\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"flex flex-1 flex-col self-center sub-heading\"],[9],[0,\"\\n      \"],[7,\"ul\"],[11,\"class\",\"flex-none px6 mt0 mx6 mb6 md:pr5\"],[9],[0,\"\\n        \"],[1,[27,\"note-list\",null,[[\"notes\",\"deleteAction\"],[[23,[\"sortedNotes\"]],[27,\"route-action\",[\"delete\"],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"container__desktop flex flex-1 flex-col self-center sub-heading text-center justify-center mb4 md:mb8\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"mb1\"],[9],[0,\"\\n        No Notes\\n      \"],[10],[0,\"\\n\"],[4,\"link-to\",[\"devices.device.tools.notes.add\"],[[\"class\"],[\"sub-title pointer hover-gray-light md:my1\"]],{\"statements\":[[0,\"        \"],[7,\"button\"],[11,\"class\",\"Button--hollow__stretch mb2 md:mb2_5\"],[9],[0,\"\\n          Add note\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/device/tools/notes/view/index.hbs"
    }
  });
  _exports.default = _default;
});