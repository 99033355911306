define("dash/routes/devices/device/tools/tool", ["exports", "dash/lib/authenticatedRoute", "dash/constants/ExternalLinks"], function (_exports, _authenticatedRoute, _ExternalLinks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    beforeModel({
      params
    }) {
      const {
        namespace
      } = params['devices.device.tools.tool'];
      switch (namespace) {
        case 'podcasts':
          this.transitionTo('devices.device.tools.podcasts');
          break;
        case 'music':
          this.transitionTo('devices.device.tools.music');
          break;
        case 'directions':
          this.transitionTo('devices.device.tools.directions');
          break;
        case 'directory':
          this.transitionTo('devices.device.tools.directory');
          break;
        case 'notes':
          this.transitionTo('devices.device.tools.notes');
          break;
        case 'calendar':
          this.transitionTo('devices.device.tools.calendar');
          break;
        case 'magic8ball':
          this.transitionTo('devices.device.tools.magic8ball');
          break;
        default:
          break;
      }
    },
    model({
      namespace
    }) {
      const deviceTool = Ember.get(this.modelFor('devices.device'), 'deviceTools').find(deviceTool => {
        return Ember.get(deviceTool, 'tool.namespace') === namespace;
      });
      const supportLink = _ExternalLinks.default.SUPPORT[namespace] || _ExternalLinks.default.SUPPORT.DEFAULT;
      if (deviceTool) {
        Ember.set(deviceTool, 'supportLink', supportLink);
        return deviceTool;
      }
      const tool = Ember.get(this, 'store').peekAll('tool').find(tool => tool.namespace === namespace);
      return {
        tool,
        supportLink,
        device: this.modelFor('devices.device')
      };
    },
    afterModel(model) {
      if (!model) this.transitionTo('devices.device.tools');
    }
  });
  _exports.default = _default;
});