define("dash/templates/account/edit/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6uN2Hqml",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\"],[\"Edit Account\",\"account\",\"home\"]],{\"statements\":[[4,\"form-for\",[[23,[\"model\"]]],[[\"submit\"],[[27,\"route-action\",[\"updateAccount\"],null]]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"Input--primary text-left mb3\"],[9],[0,\"\\n      \"],[1,[27,\"component\",[[22,1,[\"text-field\"]],\"givenName\"],[[\"label\",\"errorClasses\"],[\"First Name\",\"validation-error\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"Input--primary text-left mb3\"],[9],[0,\"\\n      \"],[1,[27,\"component\",[[22,1,[\"text-field\"]],\"familyName\"],[[\"label\",\"errorClasses\"],[\"Last Name\",\"validation-error\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"Input--primary text-left\"],[9],[0,\"\\n      \"],[1,[27,\"component\",[[22,1,[\"text-field\"]],\"email\"],[[\"label\",\"errorClasses\"],[\"Email\",\"validation-error\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"text-center my3\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"account.edit.password\"],[[\"class\"],[\"sub-title pointer hover-gray-light my4 md:my1\"]],{\"statements\":[[0,\"        Update Password\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"Button--hollow block mxauto mt4 mb_5\"],[12,\"onClick\",[27,\"route-action\",[\"updateAccount\"],null]],[12,\"disabled\",[27,\"or\",[[27,\"not\",[[23,[\"model\",\"validations\",\"isValid\"]]],null],[23,[\"model\",\"isSaving\"]]],null]],[9],[0,\"\\n      \"],[4,\"if\",[[23,[\"model\",\"isSaving\"]]],null,{\"statements\":[[0,\"Updating...\"]],\"parameters\":[]},{\"statements\":[[0,\"save\"]],\"parameters\":[]}],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/account/edit/index.hbs"
    }
  });
  _exports.default = _default;
});