define("dash/routes/devices/device/contacts/contact", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    model({
      contact_id
    }) {
      const local = Ember.get(this, 'store').peekRecord('contact', contact_id);
      if (local) return local;
      const device = this.modelFor('devices.device');
      return Ember.get(this, 'store').findRecord('contact', contact_id, {
        adapterOptions: {
          device_id: Ember.get(device, 'id')
        }
      }).catch(errors => {
        return Ember.get(this, 'dash').handleServerErrors(errors);
      });
    }
  });
  _exports.default = _default;
});