define("dash/templates/devices/device/tools/directory/terms-and-conditions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jdWVScaf",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\",\"withoutContainer\"],[\"Directory Details & Conditions\",\"devices.device.tools.directory\",\"home\",true]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"container__desktop flex flex-3 flex-col self-center sub-heading items-center mb4 md:mb8\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"mb1\"],[9],[0,\"\\n      \"],[7,\"p\"],[9],[0,\"\\n        Like all of our optional tools, the Directory tool is done in a way that respects user privacy. While we do use a third party (Google Places API) for the logic that supports the tool, this is a service that Light pays for and can therefore offer our users without sharing any user-identifiable information about them. We believe this collaboration provides the best user experience and most accurate and up-to-date search results. That being said, there is always the possibility the information provided may not be 100% accurate or up-to-date.\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"mb1\"],[9],[0,\"\\n      \"],[7,\"p\"],[9],[0,\"\\n        Please take a moment to review the full terms & conditions for using the Directory tool. By installing the tool you are agreeing to these terms.\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"mb1 underline\"],[9],[0,\"\\n      \"],[7,\"a\"],[12,\"href\",[23,[\"model\",\"termsLink\"]]],[9],[0,\" Read the full terms & conditions. \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n      \"],[7,\"button\"],[12,\"onclick\",[27,\"route-action\",[\"continue\"],null]],[11,\"class\",\"Button--hollow mt4\"],[9],[0,\"\\n        \"],[1,[21,\"buttonText\"],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/device/tools/directory/terms-and-conditions.hbs"
    }
  });
  _exports.default = _default;
});