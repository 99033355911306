define("dash/templates/devices/device/contacts/actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "BfLBSbNB",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\"],[\"Contacts\",\"devices.device\",\"home\"]],{\"statements\":[[0,\"  \"],[1,[27,\"menu-list\",null,[[\"links\"],[[23,[\"links\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/device/contacts/actions.hbs"
    }
  });
  _exports.default = _default;
});