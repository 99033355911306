define("dash/routes/devices/device/tools/podcasts/edit", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    model() {
      const {
        followedPodcasts
      } = this.modelFor('devices.device.tools.podcasts');
      return followedPodcasts;
    },
    actions: {
      unfollow(followedPodcast) {
        const dashService = Ember.get(this, 'dash');
        const followedPodcastTitle = Ember.get(followedPodcast, 'podcast.title');
        followedPodcast.destroyRecord().then(() => {
          dashService.showSuccessMessage(`${followedPodcastTitle} was unfollowed.`);
          this.send('refreshFollowedPodcastModel');
          this.refresh();
        }).catch(errors => dashService.handleServerErrors(errors));
      }
    }
  });
  _exports.default = _default;
});