define("dash/routes/devices/device/tools/music/edit", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    model() {
      const musicDeviceTool = Ember.get(this.modelFor('devices.device.tools.music'), 'musicDeviceTool');
      const processingCount = Ember.get(this.modelFor('devices.device.tools.music'), 'processingCount');
      const failedCount = Ember.get(this.modelFor('devices.device.tools.music'), 'failedCount');
      if (musicDeviceTool) {
        return this.store.query('playlist', {
          device_tool_id: Ember.get(musicDeviceTool, 'id')
        }).then(playlists => playlists.find(playlist => playlist.get('isSystemPlaylist') === true)).then(playlist => {
          return playlist.hasMany('playlistItems').reload().then(() => {
            Ember.set(playlist, 'processingCount', processingCount);
            Ember.set(playlist, 'failedCount', failedCount);
            return playlist;
          });
        }).catch(errors => {
          return Ember.get(this, 'dash').handleServerErrors(errors);
        });
      } else {
        return {
          processingCount,
          failedCount,
          playlistItems: []
        };
      }
    },
    actions: {
      refreshPlaylistModel() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});