define("dash/routes/devices/device/contacts/delete-all", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Stages = {
    INTRO: 'INTRO',
    SUCCESS: 'SUCCESS'
  };
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    setupController(controller) {
      this._super(...arguments);
      Ember.set(controller, 'stage', Stages.INTRO);
    },
    actions: {
      confirm() {
        this.transitionTo('home');
      },
      deleteAll() {
        if (Ember.get(this, 'controller.isDeleting')) return;
        Ember.set(this, 'controller.isDeleting', true);
        const currentDevice = this.modelFor('devices.device');
        Ember.get(this, 'dash').triggerDeleteAllContacts(currentDevice).then(() => {
          Ember.set(this, 'controller.stage', Stages.SUCCESS);
        }).catch(errors => {
          Ember.get(this, 'dash').handleServerErrors(errors);
        }).finally(() => {
          Ember.set(this, 'controller.isDeleting', false);
        });
      }
    }
  });
  _exports.default = _default;
});