define("dash/routes/devices/device/tools/notes/delete-all", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    ajax: Ember.inject.service(),
    actions: {
      deleteAll() {
        const deviceTool = Ember.get(this.modelFor('devices.device'), 'deviceTools').find(deviceTool => {
          return Ember.get(deviceTool, 'tool.namespace') === 'notes';
        });
        const ajaxService = Ember.get(this, 'ajax');
        const dashService = Ember.get(this, 'dash');
        return ajaxService.request('/notes/delete_all', {
          method: 'POST',
          data: {
            device_tool_id: deviceTool.id
          }
        }).then(() => {
          dashService.showSuccessMessage('All notes deleted from tool.');
          return this.transitionTo('devices.device.tools.notes');
        }).catch(err => {
          return dashService.handleServerErrors(err);
        });
      }
    }
  });
  _exports.default = _default;
});