define("dash/utils/phoneNumber", ["exports", "libphonenumber-js"], function (_exports, _libphonenumberJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatPhoneNumber = _exports.convertToPhoneNumbers = _exports.PRIMARY_NUMBER_LABEL = void 0;
  const PRIMARY_NUMBER_LABEL = 'primary';
  _exports.PRIMARY_NUMBER_LABEL = PRIMARY_NUMBER_LABEL;
  const formatPhoneNumber = (phoneNumber = '') => {
    if (!phoneNumber) return '';
    try {
      const parsedNumber = (0, _libphonenumberJs.parsePhoneNumber)(phoneNumber, 'US');
      if (parsedNumber) return parsedNumber.formatInternational();
      return phoneNumber;
    } catch (error) {
      return phoneNumber;
    }
  };
  _exports.formatPhoneNumber = formatPhoneNumber;
  const convertToPhoneNumbers = (number = '') => [{
    label: PRIMARY_NUMBER_LABEL,
    number
  }];
  _exports.convertToPhoneNumbers = convertToPhoneNumbers;
});