define("dash/routes/devices/device/contacts/import/icloud", ["exports", "dash/lib/authenticatedRoute", "dash/lib/icloudCredentials"], function (_exports, _authenticatedRoute, _icloudCredentials) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Stages = {
    INTRO: 'INTRO',
    IDLE: 'IDLE',
    UPLOADING: 'UPLOADING',
    SUCCESS: 'SUCCESS'
  };
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    model() {
      return _icloudCredentials.default.create(Ember.getOwner(this).ownerInjection());
    },
    setupController(controller) {
      this._super(...arguments);
      Ember.set(controller, 'submissionError', null);
      Ember.set(controller, 'stage', Stages.INTRO);
      Ember.set(this, 'controller.isSaving', false);
    },
    actions: {
      onUpdateValue(model, field, val) {
        Ember.set(model, field, val);
      },
      dismissIntroMessage() {
        Ember.set(this, 'controller.stage', Stages.IDLE);
      },
      submit(e) {
        if (e) e.preventDefault();
        const iCloudCredentials = Ember.get(this, 'controller.model');
        iCloudCredentials.validate().then(({
          validations
        }) => {
          const isValid = Ember.get(validations, 'isValid');
          Ember.set(this, 'hasValidationError', !isValid);
          if (!isValid) return;
          const currentDevice = this.modelFor('devices.device');
          Ember.set(this, 'controller.isSaving', true);
          Ember.get(this, 'dash').triggerICloudImport(iCloudCredentials, currentDevice).then(() => {
            Ember.set(this, 'controller.stage', Stages.SUCCESS);
            Ember.get(this, 'dash').showSuccessMessage('Success! Your iCloud import has started.');
          }).catch(errors => {
            Ember.set(this, 'controller.submissionError', "Something went wrong. Please try again");
            return Ember.get(this, 'dash').handleServerErrors(errors);
          }).finally(() => {
            Ember.set(this, 'controller.isSaving', false);
          });
        });
      }
    }
  });
  _exports.default = _default;
});