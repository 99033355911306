define("dash/templates/components/iccid-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8SSw3gpZ",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"form-for\",[[23,[\"iccid\"]]],[[\"update\",\"submit\"],[[27,\"action\",[[22,0,[]],\"onUpdateValue\"],null],[27,\"action\",[[22,0,[]],\"submit\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"Input--primary text-left mb3\"],[9],[0,\"\\n    \"],[1,[27,\"component\",[[22,1,[\"text-field\"]],\"iccid\"],[[\"label\",\"errorClasses\"],[\"SIM ICCID Number\",\"validation-error\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"Input--primary text-left mb3\"],[9],[0,\"\\n    \"],[1,[27,\"component\",[[22,1,[\"text-field\"]],\"confirmIccid\"],[[\"label\",\"errorClasses\"],[\"Confirm SIM ICCID Number\",\"validation-error\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"button\"],[11,\"class\",\"Button--hollow mt3 mb2\"],[12,\"disabled\",[27,\"not\",[[23,[\"iccid\",\"validations\",\"isValid\"]]],null]],[12,\"onClick\",[27,\"action\",[[22,0,[]],\"submit\"],null]],[9],[0,\"\\n    next\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"hasValidationError\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[11,\"class\",\"error\"],[11,\"data-test\",\"form-error\"],[9],[0,\"Oops! Check for errors to continue.\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/components/iccid-form.hbs"
    }
  });
  _exports.default = _default;
});