define("dash/templates/devices/device/contacts/contact/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8XCWcW3d",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\"],[\"Delete Contact\",\"devices.device.contacts\",\"home\"]],{\"statements\":[[4,\"if\",[[23,[\"model\",\"error\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[0,\"Contact not found.\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"container--wide text-center pt_5\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"heading\"],[9],[0,\"\\n        Remove \"],[7,\"strong\"],[9],[1,[23,[\"model\",\"fullName\"]],false],[10],[0,\" from your contacts?\\n      \"],[10],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"heading mt3\"],[9],[0,\"\\n        This will delete all of your conversations and can't be undone. Are you sure?\\n      \"],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"Button--hollow mt6 mb_5\"],[12,\"disabled\",[23,[\"model\",\"isSaving\"]]],[12,\"onClick\",[27,\"route-action\",[\"delete\"],null]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"isSaving\"]]],null,{\"statements\":[[0,\"          deleting...\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          yes, delete\\n\"]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n      \\n      \"],[7,\"p\"],[11,\"class\",\"error\"],[9],[4,\"if\",[[23,[\"serverError\"]]],null,{\"statements\":[[1,[21,\"serverError\"],false]],\"parameters\":[]},{\"statements\":[[0,\" \"]],\"parameters\":[]}],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/device/contacts/contact/delete.hbs"
    }
  });
  _exports.default = _default;
});