define("dash/routes/account/edit/index", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    model() {
      return Ember.get(this, 'dash.currentUser');
    },
    actions: {
      updateAccount(evt) {
        if (evt) evt.preventDefault();
        const dashService = Ember.get(this, 'dash');
        const currentUser = Ember.get(this, 'controller.model');
        return currentUser.save().then(() => dashService.showSuccessMessage('Your account was successfully updated')).catch(errors => {
          return dashService.handleServerErrors(errors);
        });
      }
    }
  });
  _exports.default = _default;
});