define("dash/templates/components/menu-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xMDYX2Bd",
    "block": "{\"symbols\":[\"link\"],\"statements\":[[4,\"each\",[[23,[\"links\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"menu-list-item\",null,[[\"link\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/components/menu-list.hbs"
    }
  });
  _exports.default = _default;
});