define("dash/routes/devices/device/tools/podcasts/add", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function createFinalModel(podcasts, followedPodcasts) {
    const sortedPodcasts = podcasts.sortBy('podcast.title');
    const isEmptySearchResults = sortedPodcasts.length === 0;
    sortedPodcasts.forEach(podcast => {
      const id = Ember.get(podcast, 'id');
      let followedPodcastId;
      const matchedFollowedPodcast = followedPodcasts.find(followedPodcast => {
        const followedId = Ember.get(followedPodcast, 'podcast.id');
        const isMatch = followedId === id;
        if (isMatch) {
          followedPodcastId = Ember.get(followedPodcast, 'id');
        }
        return isMatch;
      });
      if (matchedFollowedPodcast) {
        Ember.set(podcast, 'alreadyFollowed', true);
        Ember.set(podcast, 'followedPodcastId', followedPodcastId);
      } else {
        Ember.set(podcast, 'alreadyFollowed', false);
      }
    });
    return {
      podcasts: sortedPodcasts,
      isEmptySearchResults
    };
  }
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    model(params) {
      const searchTerm = Ember.get(params, 'searchTerm');
      if (!searchTerm) {
        return {
          podcasts: []
        };
      }
      return Ember.get(this, 'store').query('podcast', {
        title: searchTerm
      }).then(podcasts => {
        const parentModel = this.modelFor('devices.device.tools.podcasts');
        return createFinalModel(podcasts, parentModel.followedPodcasts);
      }).catch(errors => Ember.get(this, 'dash').handleServerErrors(errors));
    },
    setupController(controller) {
      this._super(...arguments);
      Ember.set(controller, 'deviceToolId', Ember.get(this.modelFor('devices.device.tools.podcasts'), 'podcastDeviceTool.id'));
    },
    actions: {
      performSearch() {
        const searchTerm = Ember.get(this, 'controller.searchTerm');
        if (!searchTerm || !searchTerm.length) return;
        return Ember.get(this, 'store').query('podcast', {
          title: searchTerm
        }).then(podcasts => {
          const sorted = podcasts.sortBy('podcast.title');
          const isEmptySearchResults = sorted.length === 0;
          Ember.set(this, 'controller.model.podcasts', sorted);
          Ember.set(this, 'controller.model.isEmptySearchResults', isEmptySearchResults);
        }).catch(errors => Ember.get(this, 'dash').handleServerErrors(errors));
      },
      follow(podcast) {
        const dashService = Ember.get(this, 'dash');
        const deviceTool = Ember.get(this.modelFor('devices.device'), 'deviceTools').find(deviceTool => {
          return Ember.get(deviceTool, 'tool.namespace') === 'podcasts';
        });
        this.store.createRecord('followed-podcast', {
          podcast,
          deviceTool
        }).save().then(() => {
          dashService.showSuccessMessage(`${podcast.title} was followed.`);
          this.send('refreshFollowedPodcastModel');
          this.refresh();
        }).catch(errors => dashService.handleServerErrors(errors));
      },
      unfollow(followedPodcastId) {
        const dashService = Ember.get(this, 'dash');
        const followedPodcast = this.store.peekRecord('followed-podcast', followedPodcastId);
        const title = Ember.get(followedPodcast, 'podcast.title');
        return followedPodcast.destroyRecord().then(() => {
          dashService.showSuccessMessage(`${title} was unfollowed.`);
          this.send('refreshFollowedPodcastModel');
          this.refresh();
          this.actions.performSearch();
        }).catch(errors => dashService.handleServerErrors(errors));
      }
    }
  });
  _exports.default = _default;
});