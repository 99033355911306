define("dash/controllers/devices/device/tools/notes/view/index", ["exports", "dash/mixins/controller-manages-tool-installations"], function (_exports, _controllerManagesToolInstallations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend(_controllerManagesToolInstallations.default, {
    sortedNotes: Ember.computed.sort('model.notes', (a, b) => {
      const aUpdatedAt = new Date(Ember.get(a, 'updated_at'));
      const bUpdatedAt = new Date(Ember.get(b, 'updated_at'));
      return aUpdatedAt.getTime() < bUpdatedAt.getTime() ? 1 : -1;
    })
  });
  _exports.default = _default;
});