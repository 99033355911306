define("dash/routes/devices/device/tools/add", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    model() {
      const deviceTools = Ember.get(this.modelFor('devices.device'), 'deviceTools') || [];
      const installedToolIds = deviceTools.map(item => Ember.get(item, 'tool.id'));
      return Ember.get(this, 'store').peekAll('tool').filter(tool => tool.id && !installedToolIds.includes(tool.id)).sort((toolA, toolB) => toolA.title > toolB.title ? 1 : -1);
    },
    setupController(controller, tools) {
      this._super(...arguments);
      Ember.set(controller, 'links', this.getMenuLinks(tools));
    },
    getMenuLinks(tools) {
      const userIsAdmin = Ember.get(this, 'dash.currentUser.isAdmin');
      return tools.reduce((acc, tool) => {
        const title = Ember.get(tool, 'title');
        if (!title) return acc;
        if (Ember.get(tool, 'isAvailable') || userIsAdmin) {
          acc.push({
            text: title,
            path: 'devices.device.tools.tool',
            data: Ember.get(tool, 'namespace')
          });
        }
        return acc;
      }, []);
    }
  });
  _exports.default = _default;
});