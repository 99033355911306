define("dash/components/address-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['AddressForm', 'container--wide', 'text-center', 'mt2_5', 'md:px0'],
    hasValidationError: false,
    hideSaveButton: false,
    init() {
      this._super(...arguments);
      if (Ember.get(this, 'address.country')) {
        const countryModel = (Ember.get(this, 'countries') || []).find(c => Ember.get(c, 'code') === Ember.get(this, 'address.country.code'));
        if (countryModel) {
          Ember.set(this, 'subdivisions', Ember.get(countryModel, 'subdivisions') || []);
          Ember.set(this, 'address._country', countryModel);
          if (Ember.get(this, 'address.state')) {
            const subdivision = Ember.get(countryModel, 'subdivisions').find(s => Ember.get(s, 'short_code') === Ember.get(this, 'address.state'));
            Ember.set(this, 'address._state', subdivision);
          }
        }
      } else {
        this.applyCountryToAddress(Ember.get(this, 'countries.firstObject'));
      }
    },
    applyCountryToAddress(country) {
      const address = Ember.get(this, 'address');
      if (!address) return;
      if (!country) return;
      Ember.set(address, '_country', country);
      Ember.set(address, 'country', {
        code: Ember.get(country, 'code')
      });
      Ember.set(this, 'subdivisions', Ember.get(country, 'subdivisions') || []);
      if (!Ember.get(address, 'state')) {
        this.applyStateToAddress((Ember.get(this, 'subdivisions') || [])[0]);
      }
    },
    applyStateToAddress(state) {
      const address = Ember.get(this, 'address');
      if (!address) return;
      if (!state) return;
      Ember.set(address, '_state', state);
      Ember.set(address, 'state', Ember.get(state, 'short_code'));
    },
    actions: {
      onUpdateValue(address, field, val) {
        if (field === '_state') {
          this.applyStateToAddress(val);
        } else if (field === '_country') {
          this.applyCountryToAddress(val);
        } else {
          Ember.set(address, field, val);
        }
        Ember.set(this, 'address', address);
      },
      submit(evt) {
        if (evt) evt.preventDefault();
        Ember.get(this, 'address').validate().then(({
          validations
        }) => {
          const isValid = Ember.get(validations, 'isValid');
          Ember.set(this, 'hasValidationError', !isValid);
          if (isValid) {
            Ember.get(this, 'saveAddress')();
          }
        });
      }
    }
  });
  _exports.default = _default;
});