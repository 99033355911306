define("dash/routes/account/edit/password", ["exports", "dash/lib/authenticatedRoute", "dash/lib/editPassword"], function (_exports, _authenticatedRoute, _editPassword) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    ajax: Ember.inject.service(),
    model() {
      return _editPassword.default.create(Ember.getOwner(this).ownerInjection());
    },
    actions: {
      updatePassword(evt) {
        if (evt) evt.preventDefault();
        const model = Ember.get(this, 'controller.model');
        const {
          currentPassword,
          newPassword
        } = model;
        return this._updatePassword(currentPassword, newPassword);
      }
    },
    _updatePassword(currentPassword, newPassword) {
      const ajaxService = Ember.get(this, 'ajax');
      const dashService = Ember.get(this, 'dash');
      return ajaxService.request('/users/password', {
        method: 'PATCH',
        data: {
          current_password: currentPassword,
          new_password: newPassword
        }
      }).then(() => {
        Ember.set(this, 'controller.model', {
          currentPassword: '',
          newPassword: '',
          confirmNewPassword: ''
        });
        return dashService.showSuccessMessage('Your password was successfully updated.');
      }).catch(errors => dashService.handleServerErrors(errors));
    }
  });
  _exports.default = _default;
});