define("dash/controllers/account/referrals/metrics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    commisionPending: Ember.computed('model.affiliate.{total_commission_amount,paid_commission_amount}', function () {
      let total = Ember.get(this, 'model.affiliate.total_commission_amount');
      let paid = Ember.get(this, 'model.affiliate.paid_commission_amount');
      return (total - paid).toFixed(2);
    }),
    commissionReceived: Ember.computed('model.affiliate.paid_commission_amount', function () {
      let paid = Ember.get(this, 'model.affiliate.paid_commission_amount');
      return paid.toFixed(2);
    }),
    totalSales: Ember.computed('model.affiliate.total_conversion_amount', function () {
      let sales = Ember.get(this, 'model.affiliate.total_conversion_amount');
      return sales.toFixed(2);
    })
  });
  _exports.default = _default;
});