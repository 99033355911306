define("dash/routes/devices/device/contacts/contact/delete", ["exports", "dash/lib/authenticatedRoute", "dash/lib/contactsCache"], function (_exports, _authenticatedRoute, _contactsCache) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    model() {
      return this.modelFor('devices.device.contacts.contact');
    },
    setupController(controller) {
      this._super(...arguments);
      return Ember.set(controller, 'serverError', null);
    },
    actions: {
      delete() {
        const contact = Ember.get(this, 'controller.model');
        if (Ember.get(contact, 'isDeleted')) {
          return this.transitionTo('devices.device.contacts.index');
        }
        contact.destroyRecord({
          adapterOptions: {
            device_id: contact.get('device.id')
          }
        }).then(() => {
          sessionStorage.removeItem('contactId');
          const device = this.modelFor('devices.device');
          _contactsCache.default.remove(device, contact);
          Ember.getOwner(this).lookup('route:devices.device.contacts').refresh().then(() => {
            this.transitionTo('devices.device.contacts.index');
          });
        }).catch(errors => {
          Ember.set(this, 'controller.serverError', "Something went wrong. Please try again");
          Ember.get(this, 'dash').handleServerErrors(errors);
        });
      }
    }
  });
  _exports.default = _default;
});