define("dash/constants/SKUs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    SIM: 'LPIISM-002-US',
    PLAN_1GB: 'LPII-UL1GB',
    PLAN_UNLIMITED: 'LPII-ULALL'
  };
  _exports.default = _default;
});