define("dash/templates/devices/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6HhmvTIc",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\"],[\"Select a Phone\",\"home\",\"home\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"flex-col text-center\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"error\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\"],[9],[1,[23,[\"model\",\"error\",\"detail\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"links\",\"length\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"menu-list\",null,[[\"links\"],[[23,[\"links\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"p\"],[11,\"class\",\"heading mb3\"],[9],[0,\"\\n        no devices yet\\n      \"],[10],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"a\"],[12,\"href\",[23,[\"ExternalLinks\",\"DEVICE_MISSING\"]]],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"detail color-gray-light\"],[9],[0,\"Help! My Light Phone is not listed.\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/index.hbs"
    }
  });
  _exports.default = _default;
});