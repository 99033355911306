define("dash/templates/components/contact-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ANIdEv0V",
    "block": "{\"symbols\":[\"contact\"],\"statements\":[[4,\"each\",[[23,[\"contacts\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\"],[11,\"class\",\"w100 flex items-center my2 md:my2_5\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"devices.device.contacts.contact.delete\",[22,1,[\"id\"]]],[[\"class\"],[\"Button--warning pointer mr_5 md:mr2 flex-none\"]],{\"statements\":[[0,\"      \"],[7,\"img\"],[11,\"src\",\"/assets/images/icons/warning.svg\"],[11,\"alt\",\"remove contact button\"],[11,\"data-test\",\"remove-contact-button\"],[11,\"class\",\"wh100\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"link-to\",[\"devices.device.contacts.contact\",[22,1,[]]],[[\"class\"],[\"sub-title pointer hover-gray-light no-wrap mt_25\"]],{\"statements\":[[0,\"      \"],[1,[22,1,[\"fullName\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/components/contact-list.hbs"
    }
  });
  _exports.default = _default;
});