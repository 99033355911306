define("dash/mixins/controller-manages-tool-installations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    dash: Ember.inject.service(),
    installed: Ember.computed('model.id', function () {
      return !!Ember.get(this.model, 'id');
    }),
    needsApkUpgrade: Ember.computed('model.{tool.minApkVersion,device.softwareVersion}', function () {
      const minApkVersion = Ember.get(this.model, 'tool.minApkVersion') || 0;
      const currentApkVersion = Ember.get(this.model, 'device.softwareVersion');
      return !currentApkVersion || currentApkVersion < minApkVersion;
    }),
    backPath: Ember.computed('installed', function () {
      return Ember.get(this, 'installed') ? 'devices.device.tools' : 'devices.device.tools.add';
    }),
    actions: {
      install() {
        const dashService = Ember.get(this, 'dash');
        const deviceId = Ember.get(this, 'model.device.id');
        const tool = Ember.get(this, 'model.tool');
        Ember.get(this, 'store').createRecord('deviceTool', {
          deviceId,
          toolId: tool.id,
          config: tool.defaultConfig || {}
        }).save().then(() => {
          Ember.getOwner(this).lookup('route:devices').refresh();
        }).then(() => {
          dashService.showSuccessMessage(`${tool.title} was added to your device.`);
          this.transitionToRoute('devices.device.tools');
        }).catch(errors => dashService.handleServerErrors(errors));
      },
      uninstall() {
        const dashService = Ember.get(this, 'dash');
        const toolTitle = Ember.get(this, 'model.tool.title');
        const toolId = Ember.get(this, 'model.tool.id');
        const deviceTool = Ember.get(this, 'store').peekAll('deviceTool').find(deviceTool => Ember.get(deviceTool, 'tool.id') === toolId);
        deviceTool.destroyRecord().then(() => {
          dashService.showSuccessMessage(`${toolTitle} was removed from your device.`);
          this.transitionToRoute('devices.device.tools');
        }).catch(errors => dashService.handleServerErrors(errors));
      }
    }
  });
  _exports.default = _default;
});