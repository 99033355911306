define("dash/lib/iccid", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    iccid: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 20,
      max: 20
    })],
    confirmIccid: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 20,
      max: 20
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'iccid',
      message: "SIM ICCID doesn't match"
    })]
  });
  var _default = Ember.Object.extend(Validations, {
    iccid: '',
    confirmIccid: '',
    from(existingIccid) {
      this.setProperties({
        iccid: existingIccid,
        confirmIccid: existingIccid
      });
      return this;
    },
    serialize() {
      if (!Ember.get(this, 'validations.isValid')) return {};
      return {
        iccid: Ember.get(this, 'iccid')
      };
    }
  });
  _exports.default = _default;
});