define("dash/components/one-way-password", ["exports", "ember-one-way-controls/components/one-way-password"], function (_exports, _oneWayPassword) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _oneWayPassword.default;
    }
  });
});